import React, { useEffect, useState } from "react";
import {
  TextField,
} from "@mui/material";
import CommandsTable from "./CommandsTable";
import { useSearchParams } from "react-router-dom";

interface ContainerProps {
  setAddOpen: any;
  setEditOpen: any;
  setDeleteOpen: any;
  setCommandToModify: any;
}

const CommandsContainer: React.FC<ContainerProps> = ({
  setAddOpen,
  setEditOpen,
  setDeleteOpen,
  setCommandToModify,
}) => {
  //Filters
  const [title, setTitle] = useState(``);
  const [searchParams, setSearchParams] = useSearchParams();
  
  useEffect(() => {
    setTitle(searchParams.get('title')??'')
  }, [searchParams]);

  const handleFilter = () => {
    searchParams.delete('page'); //reset page
    title==="" ? searchParams.delete('title') : searchParams.set('title', title);
    setSearchParams(searchParams);
  }

  const handleClear = () => {
    searchParams.delete('page'); //reset page
    setTitle("");
    searchParams.delete('title');
    setSearchParams(searchParams);
  }

  return (
    <div className="container">
        <div className="list">
          <div className="filters">
            <div className="search">
              <TextField
                id="title-search"
                label="Search Title..."
                type="search"
                fullWidth={true}
                value={title??""}
                onChange={(e)=>setTitle(e.target.value)}
                size="small"
              />

              <div className="filter-btn-group">
                <button onClick={handleFilter}>Apply Filters</button>
                <button onClick={handleClear}>Clear</button>
              </div>
            </div></div>

          <div className="table-section">  
            <CommandsTable 
              setCommandToModify={setCommandToModify}
              setEditOpen={setEditOpen}
              setDeleteOpen={setDeleteOpen}
            />
            <div className="filter-btn-group spa-actions">
              <button onClick={()=>setAddOpen(true)}>Add Command</button>
            </div>
          </div>   
        </div>
      </div>      
  );
};

export default CommandsContainer;
