import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/logos/logo.png";
import loginStyles from "../../assets/scss/Login.module.scss"
import RootState from "../../redux/states/root.state";
import LoginForm from "./components/LoginForm";
import Spinner from "../../components/common/Spinner";
import { AppDispatch } from "../../redux/store";
import { fetchUserData } from "../../redux/actions/user.actions";

const Home: React.FC = () => {
    const isLoading = useSelector((state: RootState) => state.user.loading);
    const dispatch = useDispatch<AppDispatch>();
  
    useEffect(() => {
        dispatch(fetchUserData());
    }, [dispatch]);
    return (
        <>
            <div className={loginStyles.homeScreen}>
                <img
                    src={logo}
                    alt="Logo"
                    style={{ height: "7rem", margin: "0 0 4rem 0" }}
                />
                {isLoading ?
                    <Spinner/>
                        :
                    <LoginForm />    
                }
                
            </div>
        </>
    );
};

export default Home;
