// src/redux/reducers/errorLog.reducer.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchSpaUpgradeRequests, fetchUpgradeRequests } from '../actions/upgradeRequests.actions';
import UpgradeRequestsState from '../states/upgradeRequests.state';
const initialState: UpgradeRequestsState = {
  loading: false,
  data: [],
  error: null,
  meta: null,
};

const UpgradeRequestsSlice = createSlice({
  name: 'updates',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUpgradeRequests.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUpgradeRequests.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchUpgradeRequests.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'An error occurred';
      })

      .addCase(fetchSpaUpgradeRequests.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSpaUpgradeRequests.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchSpaUpgradeRequests.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'An error occurred';
      });
  },
});

export default UpgradeRequestsSlice.reducer;