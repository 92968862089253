import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import "./UserSelect.css"
import React, {useEffect, useState} from "react";
import RootState from "../../../redux/states/root.state";
import { fetchUsers } from "../../../redux/actions/users.actions";
import { CircularProgress, Pagination } from "@mui/material";

interface UserSelectProps {
    setSelectedUser: any;
    currentUser: any;
    role?: string|null;
}

const UserSelect: React.FC<UserSelectProps> = ({ setSelectedUser, currentUser, role=null}) => {
const dispatch: AppDispatch = useDispatch();
const {data:users,meta,loading} = useSelector((state: RootState) => state.users);
const [pageNumber, setPageNumber] = useState(1);
const [email, setEmail] = useState(``);
const [emailQuery, setEmailQuery] = useState<any>(null);

useEffect(() => {
    setEmail(currentUser?.email??'')
  }, [currentUser]);

useEffect(() => {
    let query = `?page=${pageNumber}`;

    if (role) query += `&role=${role}`;

    if (emailQuery){
        query += `&email=${emailQuery}`
    }
    dispatch(fetchUsers(query))
  }, [dispatch,pageNumber, emailQuery, role]);

  return (
    <>
    
    <div className="user-search-container">
        <input type="search" placeholder="Search email..." onChange={(e)=>setEmail(e.target.value)} value={email}/>
        <button type="button" className="button" onClick={()=>setEmailQuery(email)}>Search</button>
    </div>

    {loading ?
        <div className="no-users"><CircularProgress size={"24px"}/></div>
        :
    users.length>0 ? 
        <div className="select-container">
            <div className="user-select">
                <div>
                    <label>
                        <input type="radio" name="user-select" defaultChecked={currentUser===null} onChange={()=>setSelectedUser(`none`)}/> {`( None )`} 
                    </label>
                </div>
                {users.map((user:any)=>(
                    <div key={user?.id}>
                        <label>
                            <input type="radio" name="user-select" defaultChecked={user?.id===currentUser?.id} onChange={()=>setSelectedUser(user)}/> {user?.name!==``? `${user?.name} (${user?.id})`:`${user?.id}`} 
                        </label>
                    </div>
                ))}
            </div>
            {
            meta?.last_page>1 &&
            <Pagination 
                count={meta?.last_page} 
                size="small"
                onChange={(e,page)=>setPageNumber(page)}
                page={pageNumber}
                showLastButton 
                showFirstButton 
                disabled={meta?.last_page===1}
            />
            }
        </div>
        :
        <div>No Users Found.</div>
    }
    </>
  );
};

export default UserSelect;
