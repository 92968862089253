import React, {useEffect, useState} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Box,
  Pagination
} from "@mui/material";
import { visuallyHidden } from '@mui/utils';
import CircularProgress from "@mui/material/CircularProgress";
import {useSelector} from "react-redux";
import RootState from "../../../redux/states/root.state";
import { useNavigate, useSearchParams } from "react-router-dom";

type Order = 'asc' | 'desc';
const headers = [
  {id:'id', label:'Id'}, 
  {id:'status', label:'Status'},
  {id:'type', label:'Type'}, 
  {id:'version', label:'Version'}, 
  {id:'source', label:'Source'},
  {id:'spa_id', label:'Spa'},  
  {id:'created_at', label:'Sent'}, 
  {id:'updated_at', label:'Updated'},
];

const HistoryTable: React.FC<{ page?:'reports'|'requests'}> = ({page='requests'}) => {
  const navigate = useNavigate();
  // Table Sort
  const [sortedRows, setRows] = useState<any[]>([]);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('');
  const {data: history, loading:isLoading, meta} = useSelector((state: RootState) => state.upgradeRequests);
  const [searchParams, setSearchParams] = useSearchParams();
  
  useEffect(() => {
    setRows(history);
  }, [history]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    header_id: string,
  ) => {
    const isAsc = orderBy === header_id && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(header_id);

    handleSort();
  };

  const handleChange = (event:any, value:any) => {
    setSearchParams({page: value})  
  }
  
  const handleSort = () => {
    let newArray = [...history].sort((a:any, b:any)=>{
      return a[orderBy] === b[orderBy] ? 0 : order==='asc'? a[orderBy] ? -1:1 : b[orderBy] ? 1:-1;
    })
    setRows(newArray);
  }

  return (
    <>
        <div className="table">
          {isLoading ? ( 
              <CircularProgress size={"24px"} sx={{position:'absolute', top:'50%', right:'50%'}}/>
            ): 
            history.length>0 ? (
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {headers.map((header)=>(
                    ((page==='reports' && header.id!=='spa_id')||(page==='requests')) &&
                    <TableCell key={header.id}  >
                      <TableSortLabel
                        active={orderBy === header.id}
                        direction={orderBy === header.id ? order : 'asc'}
                        onClick={(e)=>handleRequestSort(e,header.id)}
                      >
                        {header.label}
                        {orderBy === header.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {sortedRows.map((request)=>(
                  <TableRow key={request.id}>
                    
                    <TableCell >
                      {request?.id}
                    </TableCell>

                    <TableCell  >
                      {request?.status}
                    </TableCell>

                    <TableCell  >
                      {request?.type}
                    </TableCell>

                    <TableCell  >
                      {request?.version}
                    </TableCell>
                    
                    <TableCell  >
                      {request?.source}
                    </TableCell>

                    {page!=='reports' &&
                    <TableCell  >
                      <div className="clickable-icon" onClick={()=>navigate(`/spa/${request?.spa_id}`)}>
                        {request?.spa_id}
                      </div>
                    </TableCell>
                    }


                    <TableCell  >
                      {new Date(request?.created_at).toUTCString()}
                    </TableCell>

                    <TableCell  >
                      {new Date(request?.updated_at).toUTCString()}
                    </TableCell>

                    <TableCell  >

                    </TableCell>

                  </TableRow>
                ))}
                
              </TableBody>

            </Table>
            ):(
              <div className="empty-table">No Upgrade Requests Found.</div>
            )
          }
          </div>

          <div className="table-footer">
            <div className="pagination">
                <Pagination 
                  count={meta?.last_page} 
                  variant="outlined" 
                  onChange={handleChange}
                  page={searchParams.get("page")?Number(searchParams.get("page")):1}
                  showLastButton 
                  showFirstButton 
                  disabled={meta?.total_pages===1}
                  />
                <div className="total-count">Showing {history?.length??0}/{meta?.total??0} Requests</div>
              </div>
          </div>  
    </>
  );
};

export default HistoryTable;
