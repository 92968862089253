import {createAsyncThunk} from '@reduxjs/toolkit';
import { getSpaUpgradeRequests, getUpgradeRequests } from '../../services/api/upgradeRequests.api';
import RootState from "../states/root.state";

export const fetchUpgradeRequests = createAsyncThunk<any, string, { rejectValue: string; state: RootState}>(
  'upgrades/list',
  async (params, {rejectWithValue, getState}) => {
    try {
      const {user} = getState();
      const token = user.accessToken;
      const response = await getUpgradeRequests(token, params);
      return response.data;

    } catch (error: any) {
        if (error.response) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue('An error occurred while requesting logs.');
        }
    }
  }
);


export const fetchSpaUpgradeRequests = createAsyncThunk<any, {spa_id:string, params:string}, { rejectValue: string; state: RootState}>(
  'upgrades/spa/list',
  async (request, {rejectWithValue, getState}) => {
      try {
        const {user} = getState();
        const token = user.accessToken;
        const response = await getSpaUpgradeRequests(token, request);
        return response.data;
  
      } catch (error: any) {
          if (error.response) {
              return rejectWithValue(error.response.data.message);
          } else {
              return rejectWithValue('An error occurred while requesting logs.');
          }
      }
  }
);