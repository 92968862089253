import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import PermissionsState from '../states/permissions.state';
import { fetchPermissions } from '../actions/permissions.actions';

const initialState: PermissionsState = {
  loading: false,
  data: [],
  error: null,
  meta: null,
};

const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPermissions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPermissions.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchPermissions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      });
  },
});

export default permissionsSlice.reducer;