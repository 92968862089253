import styles from "../../../assets/scss/Modal.module.scss";
import React, { useState } from "react";
import {FormControl, InputLabel, MenuItem, Modal, Select} from '@mui/material';
import { useSelector } from "react-redux";
import RootState from "../../../redux/states/root.state";

interface ModalProps {
  isOpen: boolean, 
  setOpen: any,
  setOpenSingle: any,
  setOpenMultiple?: any,
  setFirmware: any,
  setVersion: any,
  spas: any[],
}

const ConfirmFirmwareModal: React.FC<ModalProps> = ({
  isOpen, 
  setOpen, 
  setOpenSingle, 
  setOpenMultiple=null, 
  setFirmware,
  setVersion,
  spas
}) => {

  const availableFirmware = useSelector((state: RootState) => state.firmware.data);
  const [selectedFirmware, setSelectedFirmware] = useState<any>("");
  const [selectedVersion, setSelectedVersion] = useState<any>("")

  const handleClose = () => {
      setOpen(false);
      setSelectedFirmware("");
      setSelectedVersion("");
  };

  const handleConfirm = () => {
    setFirmware(selectedFirmware);
    setVersion(selectedVersion);
    spas.length>1 ? setOpenMultiple(true) : setOpenSingle(true);
    handleClose();
  }

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
      >
        <div className={styles.modal}>
          <div className={styles.header}>
            {spas.length>1 ? 
             <p>{`Update ${spas.length} Spas firmware?`}</p>
              :
              <p>Update Spa's Firmware?</p>
            }
          </div>

          <div className={styles.body}>
          <div className="spas-title">{spas.length===1? `Spa to update `:`Spas to update `}</div>
          {spas.length>0 ? 
            <div className="select-container">
                <div className="user-select">
                  {spas.map((spa:any)=>(
                    spa?.nick_name ? 
                      <span className="name-guid" key={spa.id}>
                        {`${spa?.nick_name} (`}<span className="partial-guid">{spa?.id}</span>{`)`}
                      </span>
                      :
                      <span className="partial-guid" key={spa.id}>{spa?.id}</span>
                  ))}
                </div>
              </div>
              :
              <div>No spas selected.</div>
            }

          <div className="firmware-updates">
              <FormControl fullWidth>
                <InputLabel>Firmware</InputLabel>
                <Select
                  label="firmware-selection"
                  onChange={e => setSelectedFirmware(e.target.value)}
                  defaultValue=""
                  value={selectedFirmware??""}
                >
                  <MenuItem value={`yocto`}>Yocto</MenuItem>
                  <MenuItem value={`lpc`}>LPC</MenuItem>
                  <MenuItem value={`spaboy`}>SpaBoy</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel disabled={availableFirmware.length===0}>
                  {selectedFirmware!=="" && [...availableFirmware.filter((f:any)=>f.type===selectedFirmware)].length===0 ? `No firmware found.`:`Version`}
                </InputLabel>
                <Select
                  label="version-selection"
                  onChange={e => setSelectedVersion(e.target.value)}
                  disabled={selectedFirmware==="" || availableFirmware.length===0}
                  defaultValue=""
                  value={selectedVersion??""}
                >
                  {[...availableFirmware.filter((f:any)=>f.type===selectedFirmware)].map((f:any,i:number)=>
                    <MenuItem key={i} value={f?.version}>{f?.version}</MenuItem>
                  )}
                </Select>
              </FormControl>
            
              <div className="filter-btn-group">
                <button disabled={spas.length===0 || selectedFirmware==="" || selectedVersion===""} onClick={handleConfirm}>Update Firmware</button>
              </div>
            </div>
          </div>

          <br></br>
          
          <div className="modal-btn-group">
            <button className="as-text" onClick={handleClose}> Close </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmFirmwareModal;
