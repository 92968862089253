import { useSelector } from "react-redux";
import React, {useEffect, useState} from "react";
import RootState from "../../../redux/states/root.state";

interface SelectProps {
    checked:any[];
    setChecked: any;
}

const MultiRemovePermissions: React.FC<SelectProps> = ({
    checked,
    setChecked
}) => {

const {data:userGroup} = useSelector((state: RootState) => state.userGroup);
const [permissions, setPermissions] = useState<any[]>([]);

useEffect(() => {
    if (userGroup && userGroup.permissions) {
        setPermissions(userGroup.permissions);
    }
  }, [userGroup]);

const handleCheck = (permission:any) => {
    let newArr = [...checked];
    let found = checked?.find((perm:any)=>permission?.id===perm?.id)!==undefined;
    if(found){
      newArr = newArr.filter((perm:any)=>perm?.id!==permission?.id);
    } else {
      newArr.push(permission);
    }
    setChecked(newArr);
}

  return (
    <>

    {permissions.length>0 ? 
        <div className="select-container">
            <div className="user-select">
                {permissions.map((perm:any)=>(
                    <div key={perm.id}>
                        <label>
                            <input type="checkbox" name="user-select" onChange={()=>handleCheck(perm)}/> {perm?.name!==``? `${perm?.name} (${perm?.id})`:`${perm?.id}`} 
                        </label>
                    </div>
                ))}
            </div>
        </div>
        :
        <div>No Permissions Found.</div>
    }
    </>
  );
};

export default MultiRemovePermissions;
