import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import UserGroupState from '../states/userGroup.state';
import { assignUserGroupPermissions, assignUserGroupUsers, deleteUserGroup, fetchUserGroup, removeUserGroupPermissions, removeUserGroupUsers, updateUserGroup } from '../actions/userGroups.actions';

const initialState: UserGroupState = {
    loading: false,
    data: [],
    error: null,
};

const userGroupSlice = createSlice({
    name: 'userGroup',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserGroup.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUserGroup.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchUserGroup.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload ?? null;
            })

            .addCase(updateUserGroup.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateUserGroup.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(updateUserGroup.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload ?? null;
            })

            .addCase(assignUserGroupUsers.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(assignUserGroupUsers.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(assignUserGroupUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload ?? null;
            })

            .addCase(assignUserGroupPermissions.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(assignUserGroupPermissions.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(assignUserGroupPermissions.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload ?? null;
            })

            .addCase(removeUserGroupPermissions.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(removeUserGroupPermissions.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(removeUserGroupPermissions.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload ?? null;
            })

            .addCase(removeUserGroupUsers.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(removeUserGroupUsers.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(removeUserGroupUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload ?? null;
            })

            .addCase(deleteUserGroup.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteUserGroup.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(deleteUserGroup.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload ?? null;
            });
    },
});

export default userGroupSlice.reducer;