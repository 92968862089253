import './UserGroupsPage.css'
import React, {  useEffect, useState } from "react";
import Header from '../../components/common/Header/Header';
import { AppDispatch } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import UserGroupsContainer from './components/UserGroupsContainer';
import { fetchUserGroups } from '../../redux/actions/userGroups.actions';
import UpdateUserGroupModal from './components/modals/UpdateUserGroupModal';
import DeleteUserGroupModal from './components/modals/DeleteUserGroupModal';

const UserGroupsPage = () => {
  const dispatch: AppDispatch = useDispatch();
  const searchParams = useSearchParams()[0];
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [userGroupToModify, setUserGroupToModify] = useState<any>(null);
  // Dispatch UseEffects
  useEffect(() => {
    dispatch(fetchUserGroups(searchParams.toString()));
  }, [dispatch, searchParams]);
  
  return (
    <>
      <UpdateUserGroupModal isOpen={editOpen} setOpen={setEditOpen} userGroup={userGroupToModify} params={searchParams.toString()}/>
      <DeleteUserGroupModal open={deleteOpen} setOpen={setDeleteOpen} userGroup={userGroupToModify} params={searchParams.toString()}/>
      <Header/>
      <UserGroupsContainer 
        setEditOpen={setEditOpen}
        setDeleteOpen={setDeleteOpen}
        setUserGroupToModify={setUserGroupToModify}
      />
    </>
  );
};

export default UserGroupsPage;
