import styles from "../../../assets/scss/Modal.module.scss";
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, TextField, Button, FormControl, CircularProgress, InputLabel, Select, MenuItem } from '@mui/material';
import { AppDispatch, RootState } from '../../../redux/store'; 
import { addComment } from "../../../redux/actions/comments.actions";
import { useParams, useSearchParams } from "react-router-dom";
import { CategoryLabels } from "../categoryLabels.enum";
import { ModalProps } from "../../../components/common/Interfaces/Modals";

const AddCommentModal: React.FC<ModalProps> = ({open, setOpen}) => {
  const {id} = useParams();  
  const searchParams = useSearchParams()[0];
  
  const dispatch = useDispatch<AppDispatch>();
  const { data:user, accessToken } = useSelector((state: RootState) => state.user);
  const { error, loading } = useSelector((state: RootState) => state.comments);

  const [submitted, setSubmitted] = useState(false);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [category, setCategory] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitted(true);
    if(id) {
      await dispatch(addComment({title:title, category:category, body:body, spa_id:id, user_id:user?.id,params:searchParams.toString()}));
    }
  };
      
  const handleClose = () => {
    handleReset();
    setOpen(false);
  };

  const handleReset = () => {
    setSubmitted(false);
    setTitle('');
    setBody('');
    setCategory('');
  };

if (!submitted || loading){
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <div className={styles.modal}>
          <div className={styles.header}>
              Create New Comment
          </div>

            <div className={styles.body}>
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    id="title"
                    label="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                    disabled={loading}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal" required>
                <InputLabel>Category</InputLabel>
                  <Select
                    label="Category"
                    value={category}
                    onChange={e => setCategory(e.target.value as string)}
                    defaultValue=""
                  >
                    {Object.keys(CategoryLabels).map((cat:any, i:number)=>
                      <MenuItem key={i} value={cat}>{CategoryLabels[cat as keyof typeof CategoryLabels]}</MenuItem>
                    )}
                </Select>
              </FormControl>

                <FormControl fullWidth margin="normal">
                  <TextField
                    id="body"
                    label="Body"
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                    multiline
                    required
                    disabled={loading}
                    minRows={4}
                  />
                </FormControl>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading || !accessToken || title===''||body===''||category===''}
                  sx={{ mt: 2 }}
                >
                  {loading ? (
                    <CircularProgress size={"24px"} style={{ color: "var(--pure-white)" }} />
                    ) : (
                    "Post Comment"
                    )} 
                </Button>
              </form>
            </div>

            <div className="modal-btn-group">
                <button className="as-text" onClick={handleClose}> Close </button>
            </div>
        </div>
      </Modal>
    </div>
  )} else if (error){
    return (
      <div>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <div className={styles.modal}>
          <div className={styles.header}>An Error Occurred</div>
          <div className={styles.body}><p>{error}</p></div>
          <div className="modal-btn-group">
          <button className="as-text" onClick={handleClose}> Close </button>
            <button className="as-text" onClick={handleReset}> Try Again </button>
          </div>
        </div>
      </Modal>
    </div>
    )
  } else {
    return (
      <div>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <div className={styles.modal}>
          <div className={styles.header}>Success!</div>
          <div className={styles.body}>{`Comment successfully added.`}</div>
          <div className="modal-btn-group">
              <button className="as-text" onClick={handleClose}> Close </button>
              <button className="as-text" onClick={handleReset}> Add Another </button>
          </div>
        </div>
      </Modal>
    </div>
    )
  }
};

export default AddCommentModal;
