import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchPermission } from '../actions/permissions.actions';
import PermissionState from '../states/permission.state';

const initialState: PermissionState = {
    loading: false,
    data: [],
    error: null,
};

const permissionSlice = createSlice({
    name: 'permission',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPermission.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchPermission.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchPermission.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload ?? null;
            });
    },
});

export default permissionSlice.reducer;