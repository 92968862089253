import "./UsersPage.css"
import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../redux/states/root.state";
import Header from "../../components/common/Header/Header";
import EditUserModal from "../../components/common/Modals/EditUserModal";
import DeleteUserModal from "../../components/common/Modals/DeleteUserModal";
import { AppDispatch } from "../../redux/store";
import { fetchUsers } from "../../redux/actions/users.actions";
import { useSearchParams } from "react-router-dom";
import UsersContainer from "./components/UsersContainer";
import ApplyRolesModal from "./components/ApplyRolesModal";
import { fetchRoles } from "../../redux/actions/roles.actions";
import { fetchUserData } from "../../redux/actions/user.actions";

const UsersPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  // Data
  const users = useSelector((state: RootState) => state.users.data);
  const searchParams = useSearchParams()[0];

  // Checked items
  const [checked, setChecked] = useState<any[]>([]);
  const [role, setRole] = useState("");

  //Modal Handling
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [applyOpen, setApplyOpen] = useState(false);
  const [userToEdit, setUser] = useState<any>(null);

  // Dispatch UseEffects
  useEffect(() => {
    dispatch(fetchUsers(searchParams.toString()));
  }, [dispatch, searchParams]);

  useEffect(() => {
    dispatch(fetchUserData());
    dispatch(fetchRoles(""));
  }, [dispatch]);

  useEffect(() => {
    setChecked(users?.map((u)=>{return {id:u?.id, is_checked:false}}))
  }, [users]);

  return (
    <>
      <EditUserModal open={editOpen} setOpen={setEditOpen} object_id={userToEdit?.id}/>
      <DeleteUserModal isOpen={deleteOpen} setOpen={setDeleteOpen} user={userToEdit}/>
      <ApplyRolesModal open={applyOpen} setOpen={setApplyOpen} selection={checked} selected={role}/>
      <Header/>
      <UsersContainer 
        checked={checked}
        setChecked={setChecked}
        setEditOpen={setEditOpen}
        setDeleteOpen={setDeleteOpen}
        setApplyOpen={setApplyOpen}
        setUser={setUser}
        setRole={setRole}
      />
    </>
  );
};

export default UsersPage;
