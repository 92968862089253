import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import React, {useEffect, useState} from "react";
import RootState from "../../../redux/states/root.state";
import { Pagination } from "@mui/material";
import { fetchPermissions } from "../../../redux/actions/permissions.actions";

interface SelectProps {
    checked:any[];
    setChecked: any;
}

const MultiSelectPermissions: React.FC<SelectProps> = ({
    checked,
    setChecked
}) => {
const dispatch: AppDispatch = useDispatch();
const {data:permissions,meta} = useSelector((state: RootState) => state.permissions);

const [pageNumber, setPageNumber] = useState(1);

const handleCheck = (permission:any) => {
    let newArr = [...checked];
    let found = checked?.find((perm:any)=>permission?.id===perm?.id)!==undefined;
    if(found){
      newArr = newArr.filter((perm:any)=>perm?.id!==permission?.id);
    } else {
      newArr.push(permission);
    }
    setChecked(newArr);
}

useEffect(() => {
    dispatch(fetchPermissions(`?page=${pageNumber}`))
  }, [dispatch,pageNumber]);

  return (
    <>

    {permissions.length>0 ? 
        <div className="select-container">
            <div className="user-select">
                {permissions.map((perm:any)=>(
                    <div key={perm.id}>
                        <label>
                            <input type="checkbox" name="user-select" onChange={()=>handleCheck(perm)}/> {perm?.name!==``? `${perm?.name} (${perm?.id})`:`${perm?.id}`} 
                        </label>
                    </div>
                ))}
            </div>
            <Pagination 
                count={meta?.last_page} 
                size="small"
                onChange={(e,page)=>setPageNumber(page)}
                page={pageNumber}
                showLastButton 
                showFirstButton 
                disabled={meta?.last_page===1}
            />
        </div>
        :
        <div>No Permissions Found.</div>
    }
    </>
  );
};

export default MultiSelectPermissions;
