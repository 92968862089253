import React, { useEffect } from 'react';
import loginStyles from "../../assets/scss/Login.module.scss";
import logo from "../../assets/logos/logo.png";
import ForgotPasswordForm from "./components/ForgotPasswordForm";
import { AppDispatch } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { fetchUserData } from '../../redux/actions/user.actions';

const ForgotPasswordPage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
  
    useEffect(() => {
        dispatch(fetchUserData());
    }, [dispatch]);
    return (
        <>
            <div className={loginStyles.homeScreen}>
                <img
                    src={logo}
                    alt="Logo"
                    style={{ height: "7rem", margin: "0 0 4rem 0" }}
                />

                <ForgotPasswordForm />
            </div>
        </>
    );
};

export default ForgotPasswordPage;