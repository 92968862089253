import axios from 'axios';
import { BASE_URL } from './apiconfig';

export const getUserSpas = async (token: string | null, params: string) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/spas?${params}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.log("Failed to pull user spas list. ", error);
    throw error;
  }
}

export const getAUserSpas = async (token: string | null, params: {page:string, uuid:string}) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/user/${params.uuid}/spas?${params.page}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.log("Failed to pull user's spas list. ", error);
    throw error;
  }
}

export const getSpaStatuses = async (token: string | null) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/spas/connected`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.log("Failed to pull spa statuses. ", error);
    throw error;
  }
}

export const getSpaById = async (token: string | null, id: string) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/spas/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return data;
  } catch (error) {
    console.log("Failed to pull spa by id. ", error);
    throw error;
  }
}

// Edit profile API:
export const editSpaApi = async (token: string | null, updateInfo:{guid:string, nick_name?:any, dealership_id?:any,arctic_serial_number?:string}) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/spas/${updateInfo.guid}`, updateInfo, {
      headers: { Authorization: `Bearer ${token}` }
      // Get updated user 
    })
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteSpaApi = async (token: string | null, id: string) => {
  try {
    const { data } = await axios.delete(`${BASE_URL}/admin/spas/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    return data;
  } catch (error) {
    console.log("Failed to delete spa. ", error);
    throw error;
  }
}

export const deregisterSpaUserApi = async (token: string | null, spaInfo:{guid:string, user_id:string}) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/spas/deregister`, {
      spa_id:spaInfo.guid
    },{
      headers: { Authorization: `Bearer ${token}` },
    })
    return data;
  } catch (error) {
    console.log("Failed to deregister spa. ", error);
    throw error;
  }
}

export const registerSpaUserApi = async (token: string | null, spaInfo:{guid:string, user_id:string}) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/spas/register-to-user`, {
      spa_id:spaInfo.guid,
      user_id: spaInfo.user_id
    },{
      headers: { Authorization: `Bearer ${token}` },
    })
    return data;
  } catch (error) {
    console.log("Failed to deregister spa. ", error);
    throw error;
  }
}

export const flagSpaApi = async (token: string | null, guid:string) => {
  try {
    const { data } = await axios.put(`${BASE_URL}/spas/${guid}/flag`,{},{
      headers: { Authorization: `Bearer ${token}` },
    })
    return data;
  } catch (error) {
    console.log("Failed to flag spa. ", error);
    throw error;
  }
}

export const getFlaggedSpas = async (token: string | null, params: string) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/user/flagged-spas${params}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;

  } catch (error) {
    console.log("Failed to get user's flagged spas. ", error);
    throw error;
  }
};