import { createAsyncThunk } from '@reduxjs/toolkit';
import RootState from "../states/root.state";
import { assignUserGroupPermissionsApi, assignUserGroupUsersApi, deleteUserGroupApi, getUserGroup, getUserGroups, getUserGroupUsers, putUserGroup, removeUserGroupPermissionsApi, removeUserGroupUsersApi } from '../../services/api/userGroups.api';
import User from '../models/user.model';
import { Permission } from '../models/permission.model';

export const fetchUserGroups = createAsyncThunk<any, string, { rejectValue: string; state: RootState}>(
    'user-groups',
    async (params, {rejectWithValue, getState}) => {
        try {
        const {user} = getState();
        const token = user.accessToken;

        const response = await getUserGroups(token, params);
        return response.data;

        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred while requesting logs.');
            }
        }
    }
);

export const fetchUserGroup = createAsyncThunk<any, string, { rejectValue: string; state: RootState}>(
    'user-group',
    async (id, {rejectWithValue, getState}) => {

    try {
        const {user} = getState();
        const token = user.accessToken;

        const response = await getUserGroup(token, id);
        return response.data;

        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred while requesting logs.');
            }
        }
    }
);

export const fetchUserGroupUsers = createAsyncThunk<any, string, { rejectValue: string; state: RootState}>(
    'user-group/users',
    async (id, {rejectWithValue, getState}) => {
        try {
            const {user} = getState();
            const token = user.accessToken;

            const response = await getUserGroupUsers(token, id);
            return response.data;

            } catch (error: any) {
                if (error.response) {
                    return rejectWithValue(error.response.data.message);
                } else {
                    return rejectWithValue('An error occurred while requesting logs.');
                }
            }
    }
);

export const updateUserGroup = createAsyncThunk<any, {id:string, name:string, description:string}, { rejectValue: string; state: RootState}>(
    'user-group/update',
    async (updateInfo, {rejectWithValue, getState}) => {
        try {
            const {user} = getState();
            const token = user.accessToken;

            const response = await putUserGroup(token, updateInfo).then(()=>{return getUserGroup(token, updateInfo.id)});

            return response.data;

        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred during updating.');
            }
        }
    } 

);

export const deleteUserGroup = createAsyncThunk<any, {id:string}, { rejectValue: string; state: RootState}>(
    'user-groups/delete',
    async (deleteInfo, {rejectWithValue, getState}) => {
        try {
            const {user} = getState();
            const token = user.accessToken;

            const response = await deleteUserGroupApi(token, deleteInfo.id).then(()=>{return getUserGroup(token, deleteInfo.id)});;
            return response.data;

        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred during deletion.');
            }
        }
    } 
);

export const assignUserGroupUsers = createAsyncThunk<any, {id:string, users:User[]}, { rejectValue: string; state: RootState}>(
    'user-group/assign-users',
    async (updateInfo, {rejectWithValue, getState}) => {
        try {
            const {user} = getState();
            const token = user.accessToken;

            const response = await assignUserGroupUsersApi(token, updateInfo).then(()=>{return getUserGroup(token, updateInfo.id)});;
            return response.data;

        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred during assigning.');
            }
        }
    } 
);


export const removeUserGroupUsers = createAsyncThunk<any, {id:string, users:User[]}, { rejectValue: string; state: RootState}>(
    'user-group/remove-users',
    async (updateInfo, {rejectWithValue, getState}) => {
        try {
            const {user} = getState();
            const token = user.accessToken;

            const response = await removeUserGroupUsersApi(token, updateInfo).then(()=>{return getUserGroup(token, updateInfo.id)});;
            return response.data;

        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred during removal.');
            }
        }
    } 
);


export const assignUserGroupPermissions = createAsyncThunk<any, {id:string, permissions:Permission[]}, { rejectValue: string; state: RootState}>(
    'user-group/assign-permissions',
    async (updateInfo, {rejectWithValue, getState}) => {
        try {
            const {user} = getState();
            const token = user.accessToken;

            const response = await assignUserGroupPermissionsApi(token, updateInfo).then(()=>{return getUserGroup(token, updateInfo.id)});;
            return response.data;

        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred during assigning.');
            }
        }
    } 
);

export const removeUserGroupPermissions = createAsyncThunk<any, {id:string, permissions:Permission[]}, { rejectValue: string; state: RootState}>(
    'user-group/remove-permissions',
    async (updateInfo, {rejectWithValue, getState}) => {
        try {
            const {user} = getState();
            const token = user.accessToken;

            const response = await removeUserGroupPermissionsApi(token, updateInfo).then(()=>{return getUserGroup(token, updateInfo.id)});;
            return response.data;

        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred during removal.');
            }
        }
    } 
);