import React from "react";
import UserGroupsTable from "./UserGroupsTable";

interface ContainerProps {
    setEditOpen: any;
    setDeleteOpen: any;
    setUserGroupToModify: any;
}

const UserGroupsContainer: React.FC<ContainerProps> = ({
    setEditOpen,
    setDeleteOpen,
    setUserGroupToModify,
}) => {
  return (
    <div className="container">
        <div className="list">
            <UserGroupsTable 
              setEditOpen={setEditOpen}
              setDeleteOpen={setDeleteOpen}
              setUserGroupToModify={setUserGroupToModify}
            />
        </div>
    </div>
)
};

export default UserGroupsContainer;