import {createAsyncThunk, createAction} from '@reduxjs/toolkit';
import {
    getUserData,
    loginUserApi,
    refreshAccessTokenApi,
    logoutUser,
    registerUserApi,
    resetPasswordApi,
    requestResetLinkApi,
    editProfileApi,
    changePasswordApi,
    loginUserFromTokenApi,
    updateUserApi
} from '../../services/api/user.api';
import RootState from '../states/root.state';
import {jwtDecode} from 'jwt-decode';
import {IUser} from "../../redux/models/user.model";

export const fetchUserData = createAsyncThunk<any, void, { rejectValue: string; state: RootState}>(
    'user/fetchData',
    async (_, {rejectWithValue, getState, dispatch}) => {
        const {user} = getState();
        const token = user.accessToken;

        if (!token) {
            return rejectWithValue('No access token found');
        }

        try {
            return await getUserData(token);
        } catch (error: any) {
            if (error.response) {
                if(error.response.status === 401){
                    // Access token is refused/expired
                    dispatch(refreshAccessToken())
                }
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred while fetching user data.');
            }
        }
    }
);

export interface LoginResponse {
    accessToken: string;
    expirationTime: number;
    user: Object;
}

export interface registerResponse {
    accessToken: string;
    expirationTime: number;
    user: Object;
}

export interface ResetPasswordResponse {
    status: string;
}

export interface ForgotPasswordResponse {
    
}

export interface RefreshTokenResponse {
    accessToken: string;
    expirationTime: number;
}

export interface editResponse {
    user: Object;
}

export interface changePasswordResponse {
    status: string;
}

export const loginUser = createAsyncThunk<
    LoginResponse,
    { Email: string; Password: string },
    { rejectValue: string }
>(
    'user/login',
    async (credentials, {rejectWithValue}) => {
        try {
            const response = await loginUserApi(credentials);
            const decodedToken = jwtDecode(response.access_token);

            const expirationTime = (decodedToken.exp ?? 0) * 1000; // Convert expiration time to milliseconds

            return {
                accessToken: response.access_token,
                expirationTime: expirationTime,
                user: response.user
            };
        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.data.message);
            } else {
                return rejectWithValue('An error occurred during login.');
            }
        }
    }
);

export const refreshAccessToken = createAsyncThunk<
    RefreshTokenResponse,
    void,
    { rejectValue: string }
>(
    'user/refreshAccessToken',
    async (_, {rejectWithValue, dispatch}) => {
        try {
            const response = await refreshAccessTokenApi();
            const decodedToken = jwtDecode(response.access_token);
            const expirationTime = (decodedToken.exp ?? 0) * 1000;
            return {accessToken: response.access_token, expirationTime: expirationTime};
        } catch (error: any) {
            if (error.response) {
                // Clear user state if the refresh token is also expired/invalid to redirect the user to the login page to obtain a new one.
                dispatch(clearUserState());
                return rejectWithValue(error.response.data.data.message);
            } else {
                return rejectWithValue('An error occurred while refreshing the access token.');
            }
        }
    }
);

export const logout = createAsyncThunk<void, void, { rejectValue: string, state: RootState }>(
    'user/logout',
    async (_, {rejectWithValue, dispatch, getState}) => {
        try {
            const {user} = getState();
            const token = user.accessToken;
            await logoutUser(token);
            sessionStorage.removeItem("impersonate");
            dispatch(clearUserState());
        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.data.message);
            } else {
                return rejectWithValue('An error occurred during logout.');
            }
        }
    }
);

export const clearUserState = createAction('user/clearState');


/* Register user */
export const registerUser = createAsyncThunk<
    registerResponse,
    { first_name: string; last_name: string, username: string; email: string; password: string; password_confirmation: string },
    { rejectValue: string }
>(
    'user/register',
    async (credentials, {rejectWithValue}) => {
        try {
            const response = await registerUserApi(credentials);
            const decodedToken = jwtDecode(response.access_token);

            const expirationTime = (decodedToken.exp ?? 0) * 1000; // Convert expiration time to milliseconds

            return {
                accessToken: response.access_token,
                expirationTime: expirationTime,
                user: response.user
            };
        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(JSON.stringify(error.response.data.errors));
            } else {
                return rejectWithValue('An error occurred during register.');
            }
        }
    }
);


export const resetPassword = createAsyncThunk<
    ResetPasswordResponse,
    { email: string; password: string, password_confirmation: string; token: string;},
    { rejectValue: string }
>(
    'password/reset',
    async (credentials, {rejectWithValue}) => {
        try {
            const response = await resetPasswordApi(credentials);

            return {
                status: response.status
            };
        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(JSON.stringify(error.response.data.errors));
            } else {
                return rejectWithValue('An error occurred during resetting password.');
            }
        }
    }
);



export const requestResetLink = createAsyncThunk<
    ForgotPasswordResponse,
    { email: string;},
    { rejectValue: string }
>(
    'password/request-reset-link',
    async (credentials, {rejectWithValue}) => {
        try {
            const response = await requestResetLinkApi(credentials);
            return {
                status: response.status
            };
        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred during requesting reset link.');
            }
        }
    }
);

/* Edit user profile */
export const editProfile = createAsyncThunk<
    editResponse,
    IUser,
    { rejectValue: string; state: RootState}
>(
    'user/update',
    async (credentials, {rejectWithValue, getState}) => {
        try {
            const {user} = getState();
            const token = user.accessToken;
            const response = await editProfileApi(token,credentials);

            return response.data;

        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred during editing.');
            }
        }
    }
);

/* Edit user profile */
export const changePassword = createAsyncThunk<
    changePasswordResponse,
    {old_password: string, password: string, password_confirmation: string},
    { rejectValue: string; state: RootState}
>(
    'user/update-password',
    async (credentials, {rejectWithValue, getState}) => {
        try {
            const {user} = getState();
            const token = user.accessToken;
            const response = await changePasswordApi(token,credentials);

            return response.data;

        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred during password change.');
            }
        }
    }
);

  export const loginUserFromToken = createAsyncThunk<
    LoginResponse,
    string|null
>(
    'user/token',
    async (jwt, {rejectWithValue}) => {
        try {
            const response = await loginUserFromTokenApi(jwt);
            const decodedToken = jwtDecode(response.access_token);

            const expirationTime = (decodedToken.exp ?? 0) * 1000; // Convert expiration time to milliseconds

            return {
                accessToken: response.access_token,
                expirationTime: expirationTime,
                user: response.user
            };
        } catch (error: any) {
            if (error.response) {
                if (error.response.data.message!==undefined) {
                    return rejectWithValue(error.response.data.message);
                } else {
                    return rejectWithValue(error.response.data.data.message);
                }
                
            } else {
                return rejectWithValue('An error occurred during login.');
            }
        }
    }
);

export const updateUser = createAsyncThunk<any, 
    {
    uuid:string, 
    name?:string, 
    username?:string, 
    email?:string
}, { rejectValue: string; state: RootState}>(
    'spa/update',
    async (updateInfo, {rejectWithValue, getState}) => {
        try {
            const {user} = getState();
            const token = user.accessToken;
            const response = await updateUserApi(token,updateInfo);
            return response.data;

        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred during editing.');
            }
        }
    }
);
