import "../SpaDetailsPage.css"
import React from "react";
import { DeregisterIcon, RegisterIcon } from "../../../assets/svg/svg";
import useRoleCheck from "../../../hooks/useRoleCheck";

const UserCard: React.FC<{user:any, setOpen:any}> = ({user, setOpen}) => {
  const canEditUser = useRoleCheck(['admin','developer']);

  if (user) {
    return (
      <>
        <div className="card-container user">
          {canEditUser&&<div className="absolute-icon clickable-icon" onClick={()=>setOpen(true)}><DeregisterIcon/></div>}
          <div><strong>{user?.name??'n/a'}</strong></div>
          <div>{user?.username??'n/a'}</div>
          <div>{user?.email??'n/a'}</div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="card-container user">
        {canEditUser&&<div className="absolute-icon clickable-icon" onClick={()=>setOpen(true)}><RegisterIcon/></div>}
        <div><strong>{`No Registered User`}</strong></div>
      </div>
    </>
  );

};

export default UserCard;
