import styles from "../../assets/scss/List.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import RootState from "../../redux/states/root.state";
import { AppDispatch } from "../../redux/store";
import { useEffect, useState } from "react";
import { fetchUserSpas } from "../../redux/actions/spas.actions";
import Header from "../../components/common/Header/Header";
import SpaCard from "./components/SpaCard"
import DeleteModal from "./components/DeleteModal"
import EditSpaModal from "../../components/common/Modals/EditSpaModal"
import AddModal from "./components/AddModal"
import {CircularProgress, Pagination} from "@mui/material";
import { fetchUserData } from "../../redux/actions/user.actions";

const MySpas = () => {
  const dispatch: AppDispatch = useDispatch();
  const {data:spas, loading:isLoading, meta} = useSelector((state: RootState) => state.spas);
  const {data:user} = useSelector((state: RootState) => state.user);

  const [isEditMode, setIsEditMode] = useState(false);

  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [spaToModify, setSpaToModify] = useState<any>(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = (event:any, value:any) => {
    setSearchParams({page: value})  
  }

  useEffect(() => {
    if (searchParams.get('session_id')) {
      searchParams.delete('session_id');
      setSearchParams(searchParams);
    }
  }, [setSearchParams, searchParams]);

  useEffect(() => {
    dispatch(fetchUserData());
  }, [dispatch]);

  useEffect(() => {
    if (user){
      dispatch(fetchUserSpas({uuid:user?.id,page:searchParams.toString()}));
    }
  }, [dispatch, user, searchParams]);
 
  const AddSpa = () => {
    return (<svg className={styles.addButton} onClick={()=>setOpenAdd(true)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
    </svg>)
  }

  return (
    <>
      <DeleteModal isOpen={openDelete} setOpenDelete={setOpenDelete} spa={spaToModify}/>
      <EditSpaModal open={openEdit} setOpen={setOpenEdit} object_id={spaToModify?.id}/>
      <AddModal isOpen={openAdd} setOpenAdd={setOpenAdd}/>

      <Header withBackButton={false} withBetaLink={true}/>
      
      <div className="primary-window">
        <br></br>
        {isLoading ?
          <CircularProgress size={"24px"} sx={{position:'absolute', top:'50%', right:'50%'}}/>
        :
        <div>
          <div className={styles.yourSpasSection}>
            <div>
              <p>YOUR SPAS</p>
              <Pagination 
                count={meta?.last_page} 
                disabled={meta?.last_page===1}
                variant="outlined" 
                page={searchParams.get("page")?Number(searchParams.get("page")):1}
                onChange={handleChange}
                showLastButton 
                showFirstButton 
                />
                <br></br>
            </div>
            <div className={styles.modifyButtonGroup}>
              <p className={styles.editButton} onClick={()=>setIsEditMode(!isEditMode)} style={spas.length>0 ? {}:{visibility:"hidden"}}>{isEditMode? `Save`:`Edit`}</p>
              {isEditMode ? (<></>):(<AddSpa />)}
            </div>
          </div>
  
          { spas.length>0 ?
            <div className={styles.grid}>
              { isLoading ? 
                <CircularProgress size={"24px"} sx={{position:'absolute', top:'50%', right:'50%'}}/>
              :
                spas.map((spa) => (
                    <div key={spa.id} className={styles.gridItem}>
                      <SpaCard spa={spa} isCardEdit={isEditMode} setOpenDelete={setOpenDelete} setSpaToModify={setSpaToModify} setOpenEdit={setOpenEdit}/>
                    </div>
                  ))
              }
            </div>
            :
            <div className={styles.noSpas}>No registered spas.</div>
            }

        </div>}
      </div> 
    </>
  );
};

export default MySpas;
