import styles from "../../../../assets/scss/Modal.module.scss";
import React, {useState} from "react";
import {Modal, CircularProgress} from '@mui/material';
import { AppDispatch } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { deleteUserGroup, fetchUserGroups } from "../../../../redux/actions/userGroups.actions";
import RootState from "../../../../redux/states/root.state";

interface ModalProps {
    open: boolean;
    setOpen: any;
    userGroup: any;
    params?: string;
}

const DeleteUserGroupModal: React.FC<ModalProps> = ({open, setOpen, userGroup, params=""}) => {
  const dispatch: AppDispatch = useDispatch();
  const {loading, error} = useSelector((state: RootState) => state.userGroups);
  const [success, setSuccess] = useState(false);

  const handleClose = () => {
    setSuccess(false);
    setOpen(false);
  };

  const handleDelete = async () => {
    if(userGroup){
      await dispatch(
          deleteUserGroup({id:userGroup.id})
      );

      if (!error) {
        setSuccess(true);
        await dispatch(fetchUserGroups(params));
      }
    }
  }

  return (
    <div>
    <Modal
      open={open}
      onClose={handleClose}
    >

      <div className={styles.modal}>
          <div className={styles.header}>
              {loading ?
                <p>Deleting...</p>
              :
              error ?
                <p>An Error Occurred</p>
              :
              success ?
              <p>Success!</p>
              :
                <p> Delete User Group <b>{userGroup?.name}</b>? </p>
              }
              
          </div>
          <div className={styles.body}>
              {
              loading ?
                <CircularProgress size={"24px"} />
              :
              error?
                <div>An error occured while deleting, please try again later.</div>
              :
              success ?
                <div>User Group successfully deleted.</div>
              :
                <p> This action cannot be undone. </p>
              }
          </div>
          {
              error || success ?
              <div className="modal-btn-group">
                  <div></div>
                  <button className="as-text" onClick={handleClose}> OK </button>                        
          </div>
          : !loading ?
          <div className="modal-btn-group">
                <button className={styles.delete} onClick={handleDelete}> Delete </button>
                <button className="as-text" onClick={handleClose}> Cancel </button>
          </div>
          :
          <></>
          }
      </div>
    </Modal>
  </div>
);
};
    
export default DeleteUserGroupModal;
