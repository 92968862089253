import styles from "../../../assets/scss/Modal.module.scss";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import RootState from "../../../redux/states/root.state";
import {
  Modal, 
  CircularProgress,
 } from '@mui/material';
import SingleSelectUserRole from "../../../components/common/UserSelect/SingleSelectUserRole";

interface ModalProps {
    isOpen: boolean, 
    setOpen: any,
    dealership: any;
    setCorporate: any;
    setOwner: any;
    setDealer: any;
    role: any;
}

const EditDealershipOwnersModal: React.FC<ModalProps> = ({isOpen, setOpen, dealership, setCorporate, setOwner, setDealer, role}) => {
  const {loading} = useSelector((state: RootState) => state.dealership);
  const {loading:userLoading} = useSelector((state: RootState) => state.users);

  const [selectedUser, setSelectedUser] = useState<any>(null);
  
  const handleClose = () => {
    handleReset();
    setOpen(false);
  }

  const handleSave = () => {
    switch(role?.name){
      case 'corporate':
        setCorporate(selectedUser);
        break;

      case 'owner':
        setOwner(selectedUser);
        break;

      case 'dealer':
        setDealer(selectedUser);
        break;
    }
    handleClose();
  }

  const handleReset = () => {
    setSelectedUser(null);
  }

  return (
    <div>
      <Modal
        open={isOpen}
      >
        {loading||userLoading ?
        <CircularProgress size={"24px"} sx={{position:'absolute', top:'50%', right:'50%'}}/>
        :
        <div className={styles.modal}>
            <div className={styles.header}>Change Dealership's {role?.name==='owner'? 'Owner' : role?.name==='dealer'? 'Dealer' : "Corporation"}</div>

            <div className="edit-modal-input">
              <SingleSelectUserRole setSelectedUser={setSelectedUser} currentUser={role?.name==='owner'? dealership?.owner : role?.name==='dealer'? dealership?.dealer : dealership?.corporate} role={role}/> 
            </div>

            <div className="modal-btn-group">
                <button className="as-text" onClick={handleClose}> Cancel </button>
                <button className="as-button" onClick={handleSave} disabled={loading||userLoading}>                         
                  {loading||userLoading ? (
                    <CircularProgress size={"24px"} style={{ color: "as-white" }} />
                    ) : (
                    "Save"
                    )}
                </button>
            </div>
        </div>
        }
      </Modal>
    </div>
  );
};

export default EditDealershipOwnersModal;
