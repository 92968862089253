import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchComment, markAsRead, resolveComment, updateComment } from '../actions/comments.actions';
import CommentState from '../states/role.state';

const initialState: CommentState = {
    loading: false,
    data: null,
    error: null,
};

const commentSlice = createSlice({
    name: 'comment',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchComment.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchComment.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchComment.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload ?? null;
            })
            
            .addCase(updateComment.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateComment.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(updateComment.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload ?? null;
            })

            .addCase(resolveComment.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(resolveComment.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(resolveComment.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload ?? null;
            })
            
            .addCase(markAsRead.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(markAsRead.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(markAsRead.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload ?? null;
            });
    },
});

export default commentSlice.reducer;