import styles from "../../../assets/scss/Modal.module.scss";
import React, {useState} from "react";
import {Modal,CircularProgress} from '@mui/material';
import { AppDispatch } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../../redux/states/root.state";
import MultiSelect from "../../../components/common/UserSelect/MultiSelect";
import { useParams } from "react-router-dom";
import { assignRole } from "../../../redux/actions/roles.actions";
import { ModalProps } from "../../../components/common/Interfaces/Modals";

const AddUsersModal: React.FC<ModalProps> = ({open, setOpen}) => {
    const dispatch: AppDispatch = useDispatch();
    const {id} = useParams();

    const {loading, error} = useSelector((state: RootState) => state.roleUsers);
    const [selected, setSelected] = useState<any[]>([]);

    const [submitted, setSubmitted] = useState(false);

    const handleClose = () => {
        handleReset();
        setOpen(false);
    };

    const handleReset = () => {
        setSelected([]);
        setSubmitted(false);
    }

    const handleAdd = async () => {
        setSubmitted(true);
        await dispatch(assignRole({role_id:id??'', users:selected}));
    };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        >
        <div className={styles.modal}>
            <div className={styles.header}>
                {loading ?
                    <p>Adding...</p>
                :
                error ?
                    <p>An Error Occurred</p>
                :
                submitted ?
                    <p>Success</p>
                :
                    <p>Add users to role</p>
                }
            </div>
            <div className={styles.body}>
                {loading ?
                    <CircularProgress size={"24px"} />
                :
                error ?
                    <p>{error}</p>
                :
                submitted ?
                <p>Users successfully added to role.</p>
                :
                <div className={styles.body}>
                <div className="spas-title">{`Add ${selected.length} ${selected.length===1?`user`:`users`}.`}</div>
                    <MultiSelect checked={selected} setChecked={setSelected}/>
                  <br></br>
                  <button className="button request" disabled={selected.length===0} onClick={handleAdd}>Add</button>
                </div>
                }
            </div>

            {
            !loading && !submitted?
            <div className="modal-btn-group">
                <button className="as-text" onClick={handleClose}> Close </button>                        
            </div>
            :
            submitted ?
            <div className="modal-btn-group">
                <button className="as-text" onClick={handleClose}> Close </button>    
                <button className="as-text" onClick={handleReset}> Add More </button>                        
            </div>
            :
            <></>
            }
        </div>
      </Modal>
    </div>
  );
};

export default AddUsersModal;
