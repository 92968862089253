import styles from "../../../assets/scss/Modal.module.scss";
import React, { useState, useEffect } from "react";
import { AppDispatch } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../../redux/states/root.state";
import {
  Modal, 
  TextField, 
  CircularProgress,  
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Button,
 } from '@mui/material';
import { fetchCountries, fetchRegions } from "../../../redux/actions/countries.actions";
import { useSearchParams } from "react-router-dom";
import { createDealership } from "../../../redux/actions/dealership.actions";
import { ModalProps } from "../../../components/common/Interfaces/Modals";

const AddDealershipModal: React.FC<ModalProps> = ({open, setOpen}) => {
  const dispatch: AppDispatch = useDispatch();
  const searchParams = useSearchParams()[0];
  const {error, loading} = useSelector((state: RootState) => state.dealership);
  const {data:countries} = useSelector((state: RootState) => state.countries);
  const {data:regions} = useSelector((state: RootState) => state.regions);

  // Text Input
  const [regionInput, setRegionInput] = useState("");
  const [countryInput, setCountryInput] = useState<any>("");

  const [success, setSuccess] = useState(false);

  const [updateInfo, setUpdateInfo] = useState<any>(null);

  const handleClose = () => {
    handleReset();
    setOpen(false)
  };

  useEffect(() => {
    if (open) {
      dispatch(fetchCountries());
      if (countryInput!=='') {
        dispatch(fetchRegions(countryInput));
      }
    }
  }, [dispatch,open,countryInput]);

  const handleSelect = (event: SelectChangeEvent, selectField: string) => {
    switch (selectField){
      case 'country':
        setCountryInput(event.target.value as string);
        setUpdateInfo({...updateInfo, country_id:event.target.value as string})
        setRegionInput('');
        break;

      case 'region':
        setRegionInput(event.target.value as string);
        setUpdateInfo({...updateInfo, region_id:event.target.value as string})
        break;
    }
  };

  const handleEdit = async () => {
    if (updateInfo) await dispatch(createDealership({...updateInfo,params:searchParams.toString()}));

    if (!error){
      setSuccess(true);
    }
  }

  const handleReset = () => {
    setCountryInput("");
    setRegionInput("");
    setSuccess(false);
  }

  const getFieldName = () => {
    switch (countryInput) {
      case 1:
        return 'State'

      case 2:
          return 'Province'
      default:
          return 'Region'
    }
  }

  return (
    <>
      <div>
        <Modal
          open={open}
        >
          {loading ?
          <div className={styles.modal}>
            <div className={styles.header}>
              <p>Adding Dealership...</p>
            </div>

            <div className={styles.body}>
              <p><CircularProgress size={"24px"} sx={{position:'absolute', top:'50%', right:'50%'}}/></p>
            </div>
          </div>
          :
          error ?
          <div className={styles.modal}>
            <div className={styles.header}>
              <p>An Error Occurred</p>
            </div>

            <div className={styles.body}>
              <p>{error}</p>
            </div>

            <div className="modal-btn-group">
              <div></div>
              <button className="as-button" onClick={handleClose}>OK</button>  
            </div>
          </div>
          :
          success ?
          <div className={styles.modal}>
            <div className={styles.header}>
              <p>Success</p>
            </div>

            <div className={styles.body}>
              <p>Dealership Successfully Added</p>
            </div>

            <div className="modal-btn-group">
              <div></div>
              <button className="as-button" onClick={handleClose}>OK</button>  
            </div>
          </div>
          :
          <div className={styles.modal}>
              <div className={styles.header}>
                  <p><strong>Add New Dealership</strong></p>
              </div>
              <div className={styles.body}>
                <form onSubmit={handleEdit}>
                 
                  <FormControl fullWidth margin="normal">
                  <TextField 
                    id="name" 
                    label="Name" 
                    variant="outlined" 
                    fullWidth 
                    required
                    onChange={(e)=>setUpdateInfo({...updateInfo, name:e.target.value})}/>
                  </FormControl>

                  <FormControl fullWidth margin="normal">
                  <TextField 
                    id="city" 
                    label="City" 
                    variant="outlined" 
                    fullWidth 
                    required
                    onChange={(e)=>setUpdateInfo({...updateInfo, city:e.target.value})}/>
                  </FormControl>

                  <FormControl fullWidth margin="normal" required>
                    <InputLabel>Country</InputLabel>
                    <Select
                      label="Country"
                      value={countryInput}
                      onChange={e => handleSelect(e,'country')}
                    >
                      <MenuItem value={''}>All</MenuItem>
                      { countries?.length>0 &&
                        countries.map((country:any)=>
                          <MenuItem key={country?.id} value={country?.id}>{country?.label}</MenuItem>
                        )
                      }
                    </Select>
                  </FormControl>

                  <FormControl fullWidth margin="normal" required={regions.length>0}>
                  <InputLabel>{countryInput==='' || regions.length>0 ? getFieldName() : `No Regions Found.`}</InputLabel>
                  <Select
                    value={regionInput}
                    onChange={e => handleSelect(e,'region')}
                    disabled={regions.length===0}
                  >
                    <MenuItem value={''}>All</MenuItem>
                    { regions?.length>0 && countryInput!=='' &&
                      regions.map((region:any)=>
                        <MenuItem key={region?.id} value={region?.id}>{region?.label}</MenuItem>
                      )
                    }
                  </Select>
                </FormControl>

                <FormControl fullWidth margin="normal" >
                <TextField 
                    id="phone" 
                    label="Phone" 
                    variant="outlined" 
                    fullWidth 
                    required
                    onChange={(e)=>setUpdateInfo({...updateInfo, phone:e.target.value})}/>
                  </FormControl>

                  
                  <FormControl fullWidth margin="normal">
                  <TextField 
                    id="email" 
                    label="Email" 
                    variant="outlined" 
                    fullWidth 
                    required
                    onChange={(e)=>setUpdateInfo({...updateInfo, email:e.target.value})}/>
                  </FormControl>

                  
                  <FormControl fullWidth margin="normal">
                  <TextField 
                    id="website_url" 
                    label="Website" 
                    variant="outlined" 
                    fullWidth 
                    onChange={(e)=>setUpdateInfo({...updateInfo, website_url:e.target.value})}/>
                  </FormControl>

                  
                  <FormControl fullWidth margin="normal">
                  <TextField 
                    id="supplies_url" 
                    label="Supplies" 
                    variant="outlined" 
                    fullWidth 
                    onChange={(e)=>setUpdateInfo({...updateInfo, supplies_url:e.target.value})}/>
                  </FormControl>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    sx={{ mt: 2 }}
                  >
                    {loading ? (
                      <CircularProgress size={"24px"} style={{ color: "var(--pure-white)" }} />
                      ) : (
                      "Add Dealership"
                      )} 
                  </Button>
                </form>
              </div>

              <div className="modal-btn-group">
                  <button className="as-text" onClick={handleClose}> Cancel </button>
              </div>
          </div>}
        </Modal>
      </div>
    </>
  );
};

export default AddDealershipModal;
