import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { assignStaff, createDealership, deleteDealership, fetchDealership, removeStaff, updateDealership, updateDealershipCorporate } from '../actions/dealership.actions';
import DealershipState from '../states/dealership.state';

const initialState: DealershipState = {
  loading: false,
  data: [],
  error: null,
};

const DealershipSlice = createSlice({
  name: 'dealership',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDealership.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDealership.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchDealership.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      })

      .addCase(createDealership.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createDealership.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(createDealership.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      })

      .addCase(updateDealership.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateDealership.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(updateDealership.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      })
      
      .addCase(updateDealershipCorporate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateDealershipCorporate.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(updateDealershipCorporate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      })
      .addCase(assignStaff.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload.dealership;
      })
      .addCase(assignStaff.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      })

      .addCase(removeStaff.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removeStaff.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload.dealership;
      })
      .addCase(removeStaff.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      })

      .addCase(deleteDealership.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteDealership.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = null;
      })
      .addCase(deleteDealership.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      });
  },
});

export default DealershipSlice.reducer;