import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import UsersByIdState from '../states/usersById.state';
import { fetchUserById } from '../actions/users.actions';
import { assignRole } from '../actions/roles.actions';
import { updateUser } from '../actions/user.actions';

const initialState: UsersByIdState = {
  loading: false,
  data: [],
  error: null,
};

const UserByIdSlice = createSlice({
  name: 'userById',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserById.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchUserById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      })

      .addCase(updateUser.pending, (state) => {
          state.loading = true;
          state.error = null;
      })
      .addCase(updateUser.fulfilled, (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.data = action.payload;
      })
      .addCase(updateUser.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload ?? null;
      })

      .addCase(assignRole.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(assignRole.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = {...state.data, roles:[action.payload.role]};
      })
      .addCase(assignRole.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      });
  },
});

export default UserByIdSlice.reducer;