// src/redux/reducers/errorLog.reducer.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uploadFirmware } from '../actions/firmware.actions';
import FirmwareUpgradeState from '../states/firmwareUpgrade.state';

const initialState: FirmwareUpgradeState = {
  data: null,
  loading: false,
  error: null,
};

const FirmwareUploadSlice = createSlice({
  name: 'uploadFirmware',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadFirmware.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadFirmware.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(uploadFirmware.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'An error occurred';
      });
  },
});

export default FirmwareUploadSlice.reducer;