import React, { useEffect, useState } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { styled } from '@mui/system';
import store, { AppDispatch, RootState } from './redux/store';
import {theme, darkTheme} from './theme';
import AppRoutes from './Routes';
import { fetchUserData } from './redux/actions/user.actions';
import { setupTokenRefresh, clearTokenRefresh } from './redux/utils/tokenManager';
import ImpersonateDialog from './components/common/Dialog/ImpersonateDialog';
import roleCheck from './components/Layout/roleCheck';

const AppWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
});

const MainContent = styled('main')({
  flexGrow: 1,
});

const AppContent: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { accessToken, expirationTime, data:user } = useSelector((state: RootState) => state.user);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    var session = sessionStorage.getItem('impersonate');
    let canImpersonate = session!==null  // there is an impersonation in storage
                        && !['admin','developer','corporate'].includes(session)  // the impersonation is NOT of these roles
                        && roleCheck(user,['admin','developer','corporate']);  // the user IS one of these roles
    
    if (session && canImpersonate) {
        setOpen(canImpersonate);

    } else{
      setOpen(false);
    };
  }, [setOpen,user]);

  window.addEventListener("impersonate", (event) => {
    var session = sessionStorage.getItem('impersonate');
    let canImpersonate = session!==null  // there is an impersonation in storage
                        && !['admin','developer','corporate'].includes(session)  // the impersonation is NOT of these roles
                        && roleCheck(user,['admin','developer','corporate']);  // the user IS one of these roles
    
    if (session && canImpersonate) {
        setOpen(canImpersonate);

    } else{
      setOpen(false);
    };
  });

  useEffect(() => {
    if (accessToken && expirationTime) {
      dispatch(fetchUserData());
      setupTokenRefresh(dispatch, expirationTime);
    }

    return () => {
      clearTokenRefresh();
    };
  }, [dispatch, accessToken, expirationTime]);

  return (
    <AppWrapper>
      <MainContent>
        <ImpersonateDialog open={open} setOpen={setOpen}/>
        <AppRoutes />
      </MainContent>
    </AppWrapper>
  );
};

const App: React.FC = () => {
  const [mode,setMode] = useState('light');

  useEffect(() => {
    var local = localStorage.getItem('data-theme');
    if (local) {
      setMode(local);
    } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      setMode('dark');
    };
  }, [setMode]);

  window.matchMedia('(prefers-color-scheme: dark)').addEventListener("change", function (e) {
    e.matches ? setMode('dark') : setMode('light');
  });

  window.addEventListener("storage", (event) => {
    var local = localStorage.getItem('data-theme');
    if (local) {
      setMode(local);
    } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      setMode('dark');
    };
  });

  return (
    <Provider store={store}>
      <ThemeProvider theme={mode==='dark'? darkTheme : theme}>
        <CssBaseline />
        <AppContent />
      </ThemeProvider>
    </Provider>
  );
};

export default App;