import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { CartesianGrid, Legend, LineChart, XAxis, YAxis, Line, ResponsiveContainer, Tooltip } from "recharts";
import { 
  CircularProgress
} from "@mui/material";
import RootState from "../../../redux/states/root.state";
import { AppDispatch } from "../../../redux/store";
import { fetchSpaHistory } from "../../../redux/actions/spaHistory.actions";
import { LiveReportLabels, PumpState, SBConState } from "../reportEnums.enums";

interface ReportsGraphProps {
  spa_guid: string;
  selected: any[];
  startDate: string;
  endDate: string;
}

const colors = {
  'STemp': 'var(--stemp-line)',
  'TSP': 'var(--sptemp-line)',
  'HTemp': 'var(--htemp-line)',
  'P1': 'var(--p1-line)',
  'H1': 'var(--h1-line)',
  'Filter': 'var(--filter-line)',
  'SBConnected': 'var(--sbconn-line)',
  'Current': 'var(--current-line)',
  'sbVout': 'var(--sbvout-line)',
  'sbI1': 'var(--sbi1-line)',
  'sbI2': 'var(--sbi2-line)',
  'sbpH': 'var(--sbph-line)',
  'sbORP': 'var(--sborp-line)'
};

const CustomTooltip: React.FC<{ active:any, payload:any, label:any }> = ({ active, payload, label }) => {
  if (active && payload?.length) {
    return (
      <div className="rechart-tooltip">
        <span>{label}</span>
        <br />
        {payload.map((el:any, i:number) => (
          <div key={i}>
            <small style={{color:el.color}}>
              {el.name} : {el.dataKey==='P1'? PumpState[el.value] : el.dataKey==='SBConnected'? SBConState[el.value] : ['sbVout','sbI1','sbI2'].includes(el.dataKey) ? (el.value/1000).toFixed(2) : el.value}
            </small>
            <br/>
          </div>
        ))}
      </div>
    );
  }
  return null;
};

const ReportsGraph: React.FC<ReportsGraphProps> = ({spa_guid, selected, startDate, endDate}) => {
  const dispatch: AppDispatch = useDispatch();
  const {data: live, loading} = useSelector((state: RootState) => state.spaHistory);
  
  useEffect(() => {
    let keys = [...selected];
    if (keys.includes('Current')){
      let i = keys.indexOf('Current');
      keys[i] = '#cr';
    }
    if (keys.includes('Filter')){
      let i = keys.indexOf('Filter');
      keys[i] = '#fl';
    }

    if (spa_guid){
      dispatch(fetchSpaHistory({guid:spa_guid, date_from:new Date(startDate).getTime(), date_to:new Date(endDate).getTime(), keys:keys, type:'live'}));
    } 
  }, [dispatch, spa_guid, selected, startDate, endDate]);

  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    let newArray = [...live];
    newArray = newArray.map((data:any)=>{
      let keys = Object.keys(data);
      let newObj = {...data};
      if (keys.includes('SBConnected')) {
        newObj={...newObj, SBConnected: + data.SBConnected}
      }

      if (keys.includes('sbpH')) {
        newObj={...newObj, sbpH: data.sbpH/100}
      }

      if (keys.includes('Current')) {
        newObj={...newObj, Current: data.Current/100}
      }

      return {...newObj, timestamp:new Date(data.timestamp).toUTCString()}
    })

    newArray.sort((a:any,b:any)=>{
      return new Date(b.timestamp) < new Date(a.timestamp) ? 1 : -1
    })

    setData(newArray);
  }, [live,setData]);

  return (
    <>
      <div className="table">
        {loading ?
        <CircularProgress size={"24px"} sx={{position:'absolute', top:'50%', right:'50%'}}/>
        :
        live.length>0?
        <ResponsiveContainer width="95%" height="95%">
          <LineChart 
            data={data}
            margin={{ top: 50, right: 30, left: 20, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="timestamp"/>
            {selected.map((select:any, i)=>
              ['HTemp','STemp','TSP'].includes(select) ?
                <YAxis key={i} yAxisId={select} axisLine={false} tick={false} hide domain={[57,107]}/>
                :
                <YAxis key={i} yAxisId={select} axisLine={false} tick={false} hide />
              
            )}
            <Tooltip
              content={<CustomTooltip active={false} payload={[]} label={""} />}
            />
            <Legend />
            {selected.map((select:any, i)=>
              <Line key={i} type="monotone" name={LiveReportLabels[select as keyof typeof LiveReportLabels]} dataKey={select} stroke={colors[select as keyof typeof colors]} strokeWidth={2} dot={false} yAxisId={select}/>
            )}
          </LineChart>
        </ResponsiveContainer>
        :
        <div className="empty-table">No Data Found.</div>
        }
      </div>
    </>
  );
};

export default ReportsGraph;
