import { createAsyncThunk } from '@reduxjs/toolkit';
import RootState from "../states/root.state";
import { getPermission, getPermissions } from '../../services/api/permissions.api';

export const fetchPermissions = createAsyncThunk<any, string, { rejectValue: string; state: RootState}>(
    'permissions',
    async (params, {rejectWithValue, getState}) => {
        try {
            const {user} = getState();
            const token = user.accessToken;
    
            const response = await getPermissions(token, params);
            return response.data;
        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred while requesting logs.');
            }
        }
    }
);

export const fetchPermission = createAsyncThunk<any, string, { rejectValue: string; state: RootState}>(
    'permission',
    async (id, {rejectWithValue, getState}) => {
        try {
            const {user} = getState();
            const token = user.accessToken;
    
            const response = await getPermission(token, id);
            return response.data;

        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred while requesting logs.');
            }
        }
    }
);