import React, {useEffect, useMemo, useState} from "react";
import "../../assets/svg/svg";

import {
    FiltrationIcon,
    SunIcon,
} from "../../assets/svg/svg";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../../redux/store";
import {fetchSpa} from "../../redux/actions/spa.actions";
import RootState from "../../redux/states/root.state";
import useMQTT from "../../hooks/useMQTT";
import Command from "../../classes/command.class";
import {commandKeys} from "../../mqttData/commands/commands.enum";
import {SpaLiveLabels} from "../../mqttData/live/spaLiveLabels.enums";
import {SpaSettingsLabels} from "../../mqttData/settings/spaSettingsLabels";
import { AmpereMeter } from "../../components/SpaControl/AmpereMeter";
import Header from "../../components/common/Header/Header";
import useDebounce from "../../hooks/useDebounce";
import { Popover, Slider } from "@mui/material";
import Wheel from '@uiw/react-color-wheel';
import SpaBoyLogo from '../../assets/logos/SpaBoyLogo.png';
import { SpaConstLabels } from '../../mqttData/consts/spaConstsLabels.enums';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { inputDebounce, settingsDebounce } from "./debounceTimes";
import useRoleCheck from "../../hooks/useRoleCheck";

interface ReceivedData {
    [key: string]: boolean | string | number;
}

function valuetext(value: number) {
    return `${((value/225) * 100).toFixed(0)}%`;
  }

const delay = 1000;
const SpaControlPage = () => {
    // Configs:
    const {id} = useParams();
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();

    var colorsys = require('colorsys')

    // SPA:
    const canAccessAdminPanel = useRoleCheck(["dealer", "admin", "owner", "corporate", "developer","technician"]);
    const isConnected = useSelector((state: RootState) => state.spa.isConnected);

    // COLOR:
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [rgb, setRgb] = useState({ r: 214, g: 43, b: 90 });
    const [brightness, setBrightness] = useState(123);


    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };
    
    const [open, setOpen] = useState(false);

    // Fetch SPA From the DB
    useEffect(() => {
        if (id != null) {
            dispatch(fetchSpa(id));
        }
    }, [dispatch, id]);

    // Spa data and settings:
    const settingsData = useSelector((state: RootState) => state.settings.data);
    const liveData = useSelector((state: RootState) => state.live.data);
    const constData = useSelector((state: RootState) => state.const.data);

    // MQTT Functionality:
    const [commandTopic] = useMQTT(id ?? "");
    const command = new Command(commandTopic);

    const pumps = [
        {
            number: 1,
            label: SpaLiveLabels.Pump1,
            command: commandKeys.P1Next,
            config: SpaSettingsLabels.cfgP1
        },
        {
            number: 2,
            label: SpaLiveLabels.Pump2,
            command: commandKeys.P2Next,
            config: SpaSettingsLabels.cfgP2
        },
        {
            number: 3,
            label: SpaLiveLabels.Pump3,
            command: commandKeys.P3Next,
            config: SpaSettingsLabels.cfgP3
        },
        {
            number: 4,
            label: SpaLiveLabels.Pump4,
            command: commandKeys.P4Next,
            config: SpaSettingsLabels.cfgP4
        },
        {
            number: 5,
            label: SpaLiveLabels.Pump5,
            command: commandKeys.P5Next,
            config: SpaSettingsLabels.cfgP5
        }
    ];

    // Handle temperature change with debouncing:
    const [temp, setTemp] = useState<number>(0);
    const debouncedTemp = useDebounce(temp, inputDebounce);

    // Handle temperature change with debouncing:
    const [spaSP, setSpaSP] = useState<number>(0);
    const debouncedSP = useDebounce(spaSP, settingsDebounce);

    const handleTempChange = (newTemp: number) => {
        setTemp(newTemp); // Update the slider value
        setSpaSP(newTemp);
    }

    useEffect(() => {
        if (!settingsData) return;

        if (settingsData.TSP) setSpaSP(settingsData.TSP as number);
        
    }, [settingsData]);


    useMemo(() => {
        if (debouncedSP!==debouncedTemp) {
            command.updateSettings({
                setTSP: debouncedTemp,
            });
        }
    }, [debouncedTemp]);

    useMemo(() => {
        if (debouncedSP!==debouncedTemp) {
            setTemp(debouncedSP);
        }
    }, [debouncedSP]);


    const pumpLevel = (pumpLabel:string) => {
        let num = liveData[pumpLabel] as number;
        if (num>15){
            return `high`

        } else if (num>0) {
            return `low`

        } else {
            return `default`
        }
    }

    const getConvertedValue = (value:any, convert = 100) => {
        return value ? (value as number) / convert : 0;
    };

    const [startTime, setStartTime] = useState(0);
    const [timeoutID, setTimeoutID] = useState<any>(null);
    const handlePress = (e:any) => {
        setStartTime(+new Date());
        setAnchorEl(e.currentTarget);
        let timer = setTimeout(()=>setOpen(true),delay);
        setTimeoutID(timer);
    }

    const handleRelease = () => {
        const endTime = +new Date();
        const time = (endTime - startTime);
        if (time<delay) {
            command.executeSingle(commandKeys.LightsNext);
            clearTimeout(timeoutID);

        }
    }

    return (
        <div className="page">
            <Header/>
            <div className={`controls-page-container`}>
            <div className='top-section'>
                <div className='temperature'>
                    <button className='arrow-button' id='up' onClick={()=>handleTempChange(temp+1)} disabled={temp>=(constData[SpaConstLabels.setTSPmax]??104)||!isConnected}><KeyboardArrowUpIcon/></button>
                    <span className='values'>
                        <span className='current'>{liveData[SpaLiveLabels.CurrentTemp] as number ?? 0}<span id='degrees'>&deg;F</span></span>
                        <span className='set-point'>{temp}<span id='degrees-set'>&deg;F</span></span>
                    </span>
                    <button className='arrow-button' id='down' onClick={()=>handleTempChange(temp-1)} disabled={temp<=(constData[SpaConstLabels.setTSPmin]??59)||!isConnected}><KeyboardArrowDownIcon/></button>
                </div>
                <div className='spa-section'>
                    <div className='spa-image'>
                        <div className='overlay'>
                            <button id='Q1' disabled={!settingsData[SpaSettingsLabels.cfgP1]||!isConnected} onClick={() => command.executeSingle(commandKeys.P1Next)}>
                                {settingsData[SpaSettingsLabels.cfgP1] && <div id='P1' className={`indicator ${isConnected ? pumpLevel('P1'):`offline`}`}></div>}
                            </button>
                            <button id='Q2'  disabled={!settingsData[SpaSettingsLabels.cfgP2]||!isConnected} onClick={() => command.executeSingle(commandKeys.P2Next)}>
                                {settingsData[SpaSettingsLabels.cfgP2] && <div id='P2' className={`indicator ${isConnected ? pumpLevel('P2'):`offline`}`}></div>}
                            </button>
                            <button id='Q3'  disabled={!settingsData[SpaSettingsLabels.cfgP3]||!isConnected} onClick={() => command.executeSingle(commandKeys.P3Next)}>
                                {settingsData[SpaSettingsLabels.cfgP3] && <div id='P3' className={`indicator ${isConnected ? pumpLevel('P3'):`offline`}`}></div>}
                            </button>
                            <button id='Q4'  
                                disabled={(!settingsData[SpaSettingsLabels.cfgP4]&&!settingsData[SpaSettingsLabels.cfgSDS]&&!settingsData[SpaSettingsLabels.cfgLi])||!isConnected}
                                onClick={() => settingsData[SpaSettingsLabels.cfgP4] ? command.executeSingle(commandKeys.P4Next) : settingsData[SpaSettingsLabels.cfgSDS] ? command.executeSingle(commandKeys.SDSNext) : command.executeSingle(commandKeys.LightsNext)}
                            >
                                {settingsData[SpaSettingsLabels.cfgP4] ?
                                    <div id='P4' className={`indicator ${isConnected ? pumpLevel('P4'):`offline`}`}></div>
                                :
                                settingsData[SpaSettingsLabels.cfgSDS] ?
                                    <div id='SDS' className={`indicator ${isConnected ? liveData[SpaLiveLabels.SDS] as boolean ? `selected`:`default`:`offline`}`}></div>
                                :
                                    <div id='Lights' className={`indicator ${isConnected ? liveData[SpaLiveLabels.Lights] as boolean ? `selected`:`default`:`offline`}`}></div>
                                }

                            </button>
                        </div>
                        <div className={`image ${!isConnected && `offline`}`}></div>
                        {isConnected && <div className={`image ${liveData[SpaLiveLabels.Pump1] as boolean &&`p1`} ${liveData[SpaLiveLabels.Pump2] as boolean &&`p2`} ${liveData[SpaLiveLabels.Pump3] as boolean &&`p3`}`}></div>}
                    </div>
                    <div className={`spa-status ${isConnected ? liveData.H1 || liveData.H2 ? 'heating' : 'idle' : 'offline'}`}>
                        <p>                                    
                            {
                                isConnected ?
                                    liveData.H1 || liveData.H2 ? 'Heating...' : 'Idle'
                                    : 'Offline'
                            }
                        </p>
                    </div>
                </div>
                <div className='nav'>
                    {settingsData[SpaSettingsLabels.cfgSB]&&
                    <button 
                        className={`nav-btn`}
                        id={`spa-boy`}
                        onClick={() => navigate(`/spa-control/${id}/spaboy`)}
                    >
                        <img 
                        src={SpaBoyLogo} 
                        alt='spaboy_logo'
                        id='sb-logo'
                    />
                    </button>}
                    
                    <button 
                        className={`nav-btn`}
                        id={`filters`}
                        onClick={() => navigate(`/spa-control/${id}/filtration`)}
                    >
                        <div className='sanitation-icon' id="filter-icon"><FiltrationIcon/></div>
                        
                        <p>Filtration</p>
                    </button>

                    {(settingsData[SpaSettingsLabels.cfgOn]||settingsData[SpaSettingsLabels.cfgOzP1]||settingsData[SpaSettingsLabels.cfgOzP2])&&
                        <button 
                        className={`nav-btn`}
                        id={`sanitize`}
                        onClick={() => navigate(`/spa-control/${id}/sanitation`)}
                    >
                        {(settingsData[SpaSettingsLabels.cfgOzP1]||settingsData[SpaSettingsLabels.cfgOzP2]) ? <div className='sanitation-icon' id='ozone-icon'/>:<div className='sanitation-icon' id='onzen-icon'/>}
                        <p >Sanitation</p>
                    </button>}
                    {canAccessAdminPanel && <div className={`ampere desktop ${!isConnected && `offline`}`}><AmpereMeter value={isConnected ? getConvertedValue(liveData[SpaLiveLabels.Current]):0} size={15}/></div>}
                </div>
            </div>
            <div className='bottom-section'>
                <span className="action-buttons">
                {pumps.map((pump:any)=>
                    settingsData[pump.config] &&
                    <button 
                        key={pump.label}
                        className={`action-button ${isConnected ? pumpLevel(pump.label):`offline`}`}
                        id={pump.label}
                        onClick={() => command.executeSingle(pump.command)}
                        disabled={!isConnected||!settingsData[pump.config]}
                    ></button>
                )}
                {settingsData[SpaSettingsLabels.cfgFG] && <button disabled={!settingsData[SpaSettingsLabels.cfgFG]||!isConnected}
                    className={`action-button ${isConnected ? liveData[SpaLiveLabels.Fogger] as boolean ? `selected`:`default`:`offline`}`}
                    id={`Fogger`}
                    onClick={() => command.executeSingle(commandKeys.FgNext)}
                ></button>}
                {settingsData[SpaSettingsLabels.cfgSDS] && <button disabled={!settingsData[SpaSettingsLabels.cfgSDS]||!isConnected}
                    className={`action-button ${isConnected ? liveData[SpaLiveLabels.SDS] as boolean ? `selected`:`default`:`offline`}`}
                    id={`SDS`}
                    onClick={() => command.executeSingle(commandKeys.SDSNext)}
                ></button>}
                {settingsData[SpaSettingsLabels.cfgYESS] && <button disabled={!settingsData[SpaSettingsLabels.cfgYESS]||!isConnected}
                    className={`action-button ${isConnected ? liveData[SpaLiveLabels.Yess] as boolean ? `selected`:`default`:`offline`}`}
                    id={`YESS`}
                    onClick={() => command.executeSingle(commandKeys.YESSNext)}
                ></button>}
                    <button 
                        disabled={!isConnected}
                        className={`action-button ${isConnected ? liveData[SpaLiveLabels.Lights] as boolean ? `selected`:`default`:`offline`}`}
                        id={`Lights`}
                        onMouseDown={(e)=>handlePress(e)}
                        onMouseUp={handleRelease}
                    ></button> 
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        closeAfterTransition={false}
                        >
                            <div className="color-picker">
                                <Wheel 
                                    color={colorsys.rgb_to_hsv(rgb)}
                                    onChange={(color) => setRgb({ ...rgb, ...color.rgb })}
                                />
                                <div className="brightness-slider">
                                    <SunIcon/>
                                    <Slider 
                                        valueLabelDisplay="auto" 
                                        min={0} 
                                        max={225} 
                                        valueLabelFormat={valuetext}
                                        value={brightness}
                                        onChange={(e,value)=>setBrightness(Number(value))}
                                    />
                                </div>
                            </div>
                    </Popover>
                </span>
            </div>
        </div>
        {canAccessAdminPanel && <div className={`ampere mobile ${!isConnected && `offline`}`}><AmpereMeter value={getConvertedValue(liveData[SpaLiveLabels.Current])} size={10}/></div>}
    </div>
    );
};

export default SpaControlPage;
