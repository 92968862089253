import styles from "../../../../assets/scss/Modal.module.scss";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, TextField, Button,FormControl, CircularProgress } from '@mui/material';
import { AppDispatch, RootState } from '../../../../redux/store'; 
import { UserGroup } from "../../../../redux/models/userGroup.model";
import { fetchUserGroups, updateUserGroup } from "../../../../redux/actions/userGroups.actions";

interface ModalProps {
    isOpen: boolean, 
    setOpen: any,
    userGroup: UserGroup,
    params: string;
}

const UpdateUserGroupModal: React.FC<ModalProps> = ({isOpen, setOpen,userGroup,params}) => {
    const dispatch = useDispatch<AppDispatch>();
    const { accessToken } = useSelector((state: RootState) => state.user);
    const {data, loading, error} = useSelector((state: RootState) => state.userGroup);

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [submitted, setSubmitted] = useState(false);
  
      useEffect(() => {
        if (userGroup) {
          setName(userGroup.name);
          setDescription(userGroup.description);
        }
      }, [userGroup]);
  
    const handleSubmit = async (event: React.FormEvent) => {
      setSubmitted(true);
      await dispatch(updateUserGroup({id:userGroup.id, name:name, description:description}));
      if (!error){
        await dispatch(fetchUserGroups(params));
      }
    };

    const handleClose = () => {
      handleReset();
      setOpen(false);
    };

    const handleReset = () => {
      setName('');
      setDescription('');
      setSubmitted(false);
    };

if (!submitted || loading){
  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
      >
        <div className={styles.modal}>
          <div className={styles.header}>
              Update User Group
          </div>

            <div className={styles.body}>
              <FormControl fullWidth margin="normal">
                  <TextField
                    id="name"
                    label="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    disabled={loading}
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <TextField
                    id="description"
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    multiline
                    rows={4}
                    required
                    disabled={loading}
                  />
                </FormControl>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading || !accessToken}
                  sx={{ mt: 2 }}
                  onClick={handleSubmit}
                >
                  {loading ? (
                    <CircularProgress size={"24px"} style={{ color: "var(--pure-white)" }} />
                    ) : (
                    "Update User Group"
                    )} 
                </Button>
            </div>

            <div className="modal-btn-group">
                <button className="as-text" onClick={handleClose}> Close </button>
            </div>
        </div>
      </Modal>
    </div>
  )} else if (error){
    return (
      <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
      >
        <div className={styles.modal}>
          <div className={styles.header}>An Error Occurred</div>
          <div className={styles.body}><p>{error}</p></div>
          <div className="modal-btn-group">
          <button className="as-text" onClick={handleClose}> Close </button>
            <button className="as-text" onClick={handleReset}> Try Again </button>
          </div>
        </div>
      </Modal>
    </div>
    )
  } else {
    return (
      <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
      >
        <div className={styles.modal}>
          <div className={styles.header}>Success!</div>
          <div className={styles.body}>{data.message}</div>
          <div className="modal-btn-group">
              <button className="as-text" onClick={handleClose}> OK </button>
          </div>
        </div>
      </Modal>
    </div>
    )
  }
};

export default UpdateUserGroupModal;
