import { createAsyncThunk } from '@reduxjs/toolkit';
import RootState from "../states/root.state";
import { assignRoleApi, getRole, getRoles, getRoleUsers, putRole, removeRoleApi } from '../../services/api/roles.api';
import User from '../models/user.model';

export const fetchRoles = createAsyncThunk<any, string, { rejectValue: string; state: RootState}>(
    'roles',
    async (params, {rejectWithValue, getState}) => {
        try {
            const {user} = getState();
            const token = user.accessToken;
    
            const response = await getRoles(token, params);
            return response.data;
        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred while requesting logs.');
            }
        }
    }
);

export const fetchRole = createAsyncThunk<any, string, { rejectValue: string; state: RootState}>(
    'role',
    async (id, {rejectWithValue, getState}) => {
        try {
            const {user} = getState();
            const token = user.accessToken;
    
            const response = await getRole(token, id);
            return response.data;

        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred while requesting logs.');
            }
        }
    }
);

export const updateRole = createAsyncThunk<any, {id:string, name:string, description:string}, { rejectValue: string; state: RootState}>(
    'role/update',
    async (updateInfo, {rejectWithValue, getState}) => {
        try {
            const {user} = getState();
            const token = user.accessToken;

            const response = await putRole(token, updateInfo);

            return response.data;

        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred during updating.');
            }
        }
    } 

);

export const fetchRoleUsers = createAsyncThunk<any, {id:string,page:string}, { rejectValue: string; state: RootState}>(
    'role/user',
    async (params, {rejectWithValue, getState}) => {
        try {
            const {user} = getState();
            const token = user.accessToken;
    
            const response = await getRoleUsers(token, params);
            return response.data;
            
        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred while requesting users.');
            }
        }
    }
);

export const assignRole = createAsyncThunk<any, {role_id:string, users:User[]}, { rejectValue: string; state: RootState}>(
    'role/assign',
    async (updateInfo, {rejectWithValue, getState}) => {
        try {
            const {user} = getState();
            const token = user.accessToken;

            const response = await assignRoleApi(token, updateInfo);
            return response.data;

        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred during assigning.');
            }
        }
    } 
);

export const removeRole = createAsyncThunk<any, {role_id:string, users:User[]}, { rejectValue: string; state: RootState}>(
    'role/remove',
    async (updateInfo, {rejectWithValue, getState}) => {
        try {
            const {user} = getState();
            const token = user.accessToken;

            const response = await removeRoleApi(token, updateInfo);
            return response.data;

        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred during removal.');
            }
        }
    } 
);