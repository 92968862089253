import styles from "../../../assets/scss/Modal.module.scss";
import "../../../components/common/Modals/Modals.css";
import React, {useEffect, useState} from "react";
import {Modal} from '@mui/material';
import { AppDispatch } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../../redux/states/root.state";
import CircularProgress from "@mui/material/CircularProgress";
import CommandSelect from "../../../components/common/UserSelect/CommandSelect";
import { addSpaCommands } from "../../../redux/actions/commands.actions";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { ModalProps } from "../../../components/common/Interfaces/Modals";

const AddSpaCommandsModal: React.FC<ModalProps> = ({open, setOpen, selection}) => {
    const spas = selection??[];
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedCommand, setSelectedCommand] = useState<any>(null);
    const [selectedLogLevel, setSelectedLogLevel] = useState<any>('DEBUG');
    const [submitted, setSubmitted] = useState(false);
    const { loading, error } = useSelector((state: RootState)=>state.spa);
    const [results, setResults] = useState<any[]>([]);

    const handleClose = () => {
        setSubmitted(false);
        setSelectedCommand(null);
        setSelectedLogLevel('DEBUG');
        setResults([]);
        setOpen(false);
    };

    const handleSubmit = async () => {
        if((spas!==undefined && spas.length>0)&&(selectedCommand)){
            let newArray : any[] = [];
            spas.forEach(async (spa:any) => {
                await dispatch(addSpaCommands({command_id:selectedCommand.id, spa_id: spa.id, log_level: selectedLogLevel}));
                newArray.push({spa:spa, message:error??'Success'});
                setResults(newArray);;
            })
        };
        setSubmitted(true);

}

if(!submitted) {
return (
    <div>
        <Modal
        open={open}
        onClose={handleClose}
        >
        <div className={styles.modal}>
            <div className={`${styles.header} ${styles.ellipsis}`}>{spas.length > 1 ? `Command ${spas.length} Spas` : `Command  ${spas[0]?.nick_name} Spa`}</div>

            <div className={styles.body}>
                <p>
                    Assign a command <br/>
                    It may take up to 15 seconds for the command to reach the spa.
                </p>
                <CommandSelect setSelectedCommand={setSelectedCommand} setSelectedLogLevel={setSelectedLogLevel}/>
            </div>
            <div className="modal-btn-group">
                <button className="as-text" onClick={handleClose}> Cancel </button>
                <button className="as-button" onClick={handleSubmit} disabled={selectedCommand===null}> Command </button>
            </div>
        </div>
        </Modal>
    </div>
    );
} else if (loading) {
    return (
        <div>
            <Modal
            open={open}
            onClose={handleClose}
            >
            <div className={styles.modal}>
                <div className={styles.header}>Adding Commands...</div>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <CircularProgress />
                </Box>
            </div>
            </Modal>
        </div>
    );
} else {
    return (
        <div>
            <Modal
            open={open}
            onClose={handleClose}
            >
            <div className={styles.modal}>
                <div className={styles.header}>Commands Sent</div>
                <div className={styles.body}>
                    <p>Click on Spa's GUID to view their details page</p>
                    <div className="spa-select">
                        {results.map((res:any)=>(
                            <div key={res?.spa?.id} className="update-results">
                                <span className="clickable-icon" onClick={()=>navigate(`/spa/${res?.spa?.id}`)}>
                                {res?.spa?.nick_name ? 
                                <span className="name-guid">
                                    {`${res?.spa?.nick_name} (`}<span className="partial-guid">{res?.spa?.id}</span>{`)`}
                                </span>
                                :
                                <div className="partial-guid">{res?.spa?.id}</div>
                                } 
                                </span>
                                <span >{res?.message}</span>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="modal-btn-group">
                    <button className="as-text" onClick={handleClose}> Close </button>
                </div>
            </div>
            </Modal>
        </div>
        );
}

};

export default AddSpaCommandsModal;
