import axios from 'axios';
import { SpaError } from '../../redux/models/errorLog.model';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getSpaErrorLogs = async (spaId: string): Promise<{ data: SpaError[] }> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/spas/${spaId}/error-logs`);
    return response.data;;
  } catch (error) {
    console.log("Failed to error logs. ", error);
    throw error;
  }
};