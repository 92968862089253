import {createAsyncThunk} from '@reduxjs/toolkit';
import {getLogs, downloadLogFile} from '../../services/api/logs.api';
import RootState from "../states/root.state";

export const fetchLogs = createAsyncThunk<any, string, { rejectValue: string; state: RootState}>(
  'logs/list',
  async (params, {rejectWithValue, getState}) => {
    try {
      const {user} = getState();
      const token = user.accessToken;

      const response = await getLogs(token, params);

      return response.data;
    } catch (error: any) {
        if (error.response) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue('An error occurred during removal.');
        }
    }
  }
);

export const getLogsFile = createAsyncThunk<any, any, { rejectValue: string; state: RootState}>(
    'logs/get-file',
    async (params, {rejectWithValue, getState}) => {
      try {
        const {user} = getState();
        const token = user.accessToken;

        return await downloadLogFile(token, params.log_request_id);

      } catch (error: any) {
          if (error.response) {
              return rejectWithValue(error.response.data.message);
          } else {
              return rejectWithValue('An error occurred during removal.');
          }
      }
    }
);