import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import "./UserSelect.css"
import React, {useEffect, useState} from "react";
import RootState from "../../../redux/states/root.state";
import { fetchCommands } from "../../../redux/actions/commands.actions";
import { CircularProgress, Pagination, Box } from "@mui/material";

interface SelectProps {
    setSelectedCommand: any;
    setSelectedLogLevel: Function;
}

const CommandSelect: React.FC<SelectProps> = ({ setSelectedCommand, setSelectedLogLevel}) => {
const dispatch: AppDispatch = useDispatch();
const {data:commands, meta, loading} = useSelector((state: RootState) => state.commands);
const [pageNumber, setPageNumber] = useState(1);
const [title, setTitle] = useState(``);
const [query, setQuery] = useState<any>(null);
const [logLevel, setLogLevel] = useState('DEBUG');
const [submenuOpened, setSubmenuOpened] = useState(false);
const availableLogLevels = [
    'DEBUG',
    'INFO',
    'WARNING',
    'ERROR',
];

// Command changed:
const commandSelected = (selectedCommand: { title: string; }) => {
    setSelectedCommand(selectedCommand);

    if(selectedCommand.title == "update_log_level"){
        setSubmenuOpened(true);
    } else {
        setSubmenuOpened(false);
    }
}

const logLevelSelected = (level: React.SetStateAction<string>) => {
    setSelectedLogLevel(level);
    setLogLevel(level)
}


useEffect(() => {
    let param = `?page=${pageNumber}`;
    if (query){
        param = query + `&title=${query}`
    }
    dispatch(fetchCommands(param))
  }, [dispatch,pageNumber, query]);

  return (
    <>
    <div className="user-search-container">
        <input type="search" placeholder="Search title..." onChange={(e)=>setTitle(e.target.value)} disabled={commands.length===0}/>
        <button type="button" className="button" onClick={()=>setQuery(title)} disabled={commands.length===0}>Search</button>
    </div>

    {loading ?
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
        </Box>
        :
        commands.length>0 ? 
            <div className="select-container">
                <div className="user-select commands">
                {commands.map((command:any)=>(
                    <div className="command-row" key={command.id}>
                        <input type="radio" name="command-select" onChange={ () => commandSelected(command)}/>
                        <p>{command?.id}. </p>
                        <p>{command?.title.replaceAll('_', ' ')}</p>
                            {
                                submenuOpened && command.title === 'update_log_level' &&
                                <div className="sub-menu">
                                    {
                                        availableLogLevels.map((level) => (
                                                <div className="sub-menu-item" key={level}>
                                                    <input type="radio" name="log-level-select" value={logLevel}   checked={logLevel === level}
                                                           onChange={() => logLevelSelected(level)}/>
                                                    <span>
                                                        {level}
                                                    </span>
                                                </div>
                                            )
                                        )
                                    }
                                </div>
                            }
                    </div>
                ))}
                </div>
                {meta?.last_page > 1 && <Pagination
                    count={meta?.last_page} 
                    size="small"
                    onChange={(e,page)=>setPageNumber(page)}
                    page={pageNumber}
                    showLastButton 
                    showFirstButton 
                    disabled={meta?.last_page===1}
                />}
            </div>
        :
        <div>No Commands Found.</div>
        }
    </>
  );
};

export default CommandSelect;
