import styles from "../../../assets/scss/Modal.module.scss";
import React, { useEffect, useState } from "react";
import { AppDispatch } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../../redux/states/root.state";
import {
  Modal, 
  TextField, 
  CircularProgress,  
  Radio,
  RadioGroup,
  FormControlLabel,
  capitalize,
 } from '@mui/material';
import { fetchUserById } from "../../../redux/actions/users.actions";
import { assignRole } from "../../../redux/actions/roles.actions";
import { ModalProps } from "../Interfaces/Modals";
import { updateUser } from "../../../redux/actions/user.actions";
import useRoleCheck from "../../../hooks/useRoleCheck";

const EditUserModal: React.FC<ModalProps> = ({open, setOpen, object_id}) => {
  const user_id = object_id??null;

  const dispatch: AppDispatch = useDispatch();
  const {loading, error, data:user} = useSelector((state: RootState) => state.usersById);
  const {data:roles, loading: rolesLoading, error: rolesError} = useSelector((state: RootState) => state.roles);

  const [submitted, setSubmitted] = useState(false);
  const [updateInfo, setUpdateInfo] = useState<any>(null);
  const [role, setRole] = useState<any>(null);

  const isAdmin = useRoleCheck(['admin','developer']);

  const handleClose = () => {
    setSubmitted(false);
    setOpen(false)
  };

  useEffect(() => {
    if (user_id && open) {
      dispatch(fetchUserById(user_id));
    }
  }, [dispatch,user_id,open]);

  const handleSave = async () => {
    setSubmitted(true);
    
    if (role) {
      await dispatch(assignRole({role_id:role,users:[user]}))
    }

    if (updateInfo) {
      await dispatch(updateUser({...updateInfo,uuid:user?.id??''}))
    }
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
      >{loading || rolesLoading ?
        <div className={styles.modal}>
            <div className={styles.header}>
              <div></div>
            </div>
            <div className={styles.body}>
              <CircularProgress size={"24px"} sx={{position:'absolute', top:'50%', right:'50%'}}/>
            </div>
            <div className="modal-btn-group">
              <div></div>
              <div></div>
            </div>
        </div>
        :
        (error || rolesError) && submitted?
        <div className={styles.modal}>
            <div className={styles.header}>
              <p>An Error Occurred</p>
            </div>
            <div className={styles.body}>
              {rolesError ?? error }
            </div>
            <div className="modal-btn-group">
              <div></div>
              <button className="as-text" onClick={handleClose}> OK </button>
            </div>
        </div>
        :
        !(error || rolesError) && submitted?
        <div className={styles.modal}>
            <div className={styles.header}>
              <p>Success</p>
            </div>
            <div className={styles.body}>
              User information was successfully updated.
            </div>
            <div className="modal-btn-group">
              <div></div>
              <button className="as-text" onClick={handleClose}> OK </button>
            </div>
        </div>
        :
        <div className={styles.modal}>
            <div className={styles.header}>
                <p><strong>Edit {user?.name}</strong></p>
            </div>
            <div className="edit-modal-input">
                <TextField 
                  id="username" 
                  label="Username" 
                  variant="outlined" 
                  fullWidth 
                  defaultValue={user?.username} 
                  onChange={(e)=>setUpdateInfo({...updateInfo,username:e.target.value})}
                  disabled={!isAdmin}
                />

                <TextField 
                  id="email" 
                  label="Email" 
                  variant="outlined" 
                  fullWidth 
                  defaultValue={user?.email} 
                  onChange={(e)=>setUpdateInfo({...updateInfo,email:e.target.value})}
                  disabled={!isAdmin}
                />

                <TextField 
                  id="name" 
                  label="Name" 
                  variant="outlined" 
                  fullWidth 
                  defaultValue={user?.name} 
                  onChange={(e)=>setUpdateInfo({...updateInfo,name:e.target.value})}
                  disabled={!isAdmin}
                />

                <div className="edit-header">Roles</div>
                <div className="roles">
                <RadioGroup
                  aria-labelledby="radio-buttons-group-label"
                  name="radio-buttons-group"
                  onChange={(e) => setRole(e.target.value)}
                  defaultValue={user?.roles? user?.roles[0]?.id : ""}
                >
                  { rolesLoading ?
                  <CircularProgress size={"24px"}/>
                  :
                  roles.map((role:any)=>
                    <FormControlLabel key={role?.id} value={role?.id} control={<Radio />} label={capitalize(role?.name)}/>
                  )
                  }
                </RadioGroup>
                </div>

            </div>
            <div className="modal-btn-group">
                <button className="as-text" style={{color:'var(--font-red)'}} onClick={handleClose}> Cancel </button>
                <button className="as-button" onClick={handleSave} disabled={loading || rolesLoading || (!updateInfo && !role)}>                         
                  {loading || rolesLoading ? (
                    <CircularProgress size={"24px"} style={{ color: "as-white" }} />
                    ) : (
                    "Save"
                    )}
                </button>
                
            </div>
        </div>}
      </Modal>
    </div>
  );
};

export default EditUserModal;
