import "./DealershipsPage.css"
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { AppDispatch } from "../../redux/store";
import Header from "../../components/common/Header/Header";
import { fetchDealerships } from "../../redux/actions/dealership.actions";
import DealershipsContainer from "./components/DealershipsContainer";
import { fetchCountries } from "../../redux/actions/countries.actions";
import { fetchUserData } from "../../redux/actions/user.actions";
import EditDealershipModal from "../DealershipDetails/components/EditDealershipModal";
import DeleteDealershipModal from "./components/DeleteDealershipModal";
import AddDealershipModal from "./components/AddDealershipModal";

const DealershipsPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const searchParams = useSearchParams()[0];
  const [addOpen, setAddOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [dealershipToModify, setDealershipToModify] = useState<any>(null);

  useEffect(() => {
    dispatch(fetchDealerships(searchParams.toString()));
    dispatch(fetchCountries());
    dispatch(fetchUserData());
  }, [dispatch,searchParams]);

  return (
    <>
    <AddDealershipModal open={addOpen} setOpen={setAddOpen} />
    <EditDealershipModal open={editOpen} setOpen={setEditOpen} object={dealershipToModify} />
    <DeleteDealershipModal open={deleteOpen} setOpen={setDeleteOpen} object={dealershipToModify} />
    <Header/>
    <DealershipsContainer setDeleteOpen={setDeleteOpen} setEditOpen={setEditOpen} setAddOpen={setAddOpen} setDealershipToModify={setDealershipToModify}/>    
    </>
  );
};

export default DealershipsPage;
