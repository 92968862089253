import { createAsyncThunk } from '@reduxjs/toolkit';
import { assignStaffApi, destroyDealership, getDealership, getDealerships, getStaff, putDealership, putDealershipCorporate, removeStaffApi, storeDealership } from '../../services/api/dealerships.api';
import RootState from "../states/root.state";
import User from '../models/user.model';

export const fetchDealerships = createAsyncThunk<any, string, { rejectValue: string; state: RootState}>(
  'dealership/list',
  async (params, {rejectWithValue, getState}) => {
    try {
      const {user} = getState();
      const token = user.accessToken;

      const response = await getDealerships(token,params);
      return response.data;
    } catch (error: any) {
        if (error.response) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue('An error occurred during updating.');
        }
    } 
  }
);

export const fetchDealership = createAsyncThunk<any, string, { rejectValue: string; state: RootState}>(
  'dealership',
  async (id, {rejectWithValue, getState}) => {

    try {
      const {user} = getState();
      const token = user.accessToken;

      const response = await getDealership(token, id);
      return response.data;
    } catch (error: any) {
        if (error.response) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue('An error occurred during updating.');
        }
    } 
  }
);

export const fetchDealershipUsers = createAsyncThunk<any, {id:string, params:string}, { rejectValue: string; state: RootState}>(
  'dealership/users',
  async (getInfo, {rejectWithValue, getState}) => {
    try {
      const {user} = getState();
      const token = user.accessToken;

      const response = await getStaff(token, getInfo);
      return response.data;

    } catch (error: any) {
        if (error.response) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue('An error occurred during updating.');
        }
    } 
  }
);

export const fetchStaff = createAsyncThunk<any, {id:string, params:string}, { rejectValue: string; state: RootState}>(
  'dealership/staff',
  async (getInfo, {rejectWithValue, getState}) => {
    try {
      const {user} = getState();
      const token = user.accessToken;

      const response = await getStaff(token, getInfo);
      return response.data;

    } catch (error: any) {
        if (error.response) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue('An error occurred during updating.');
        }
    } 
  }
);

export const createDealership = createAsyncThunk<
  any, 
{
  params: string;
  name?:string;
  city?:string;
  phone?:string;
  email?:string;
  website_url?:string;
  supplies_url?:string;
  region_id?:string;
  country_id?:string;
},
{ rejectValue: string; state: RootState}>(
  'dealership/store',
  async (dealership, {rejectWithValue, getState}) => {
    try {
      const {user} = getState();
      const token = user.accessToken;

      const response = await storeDealership(token, dealership);
      return response.data;

    } catch (error: any) {
        if (error.response) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue('An error occurred during creation.');
        }
    }
  }
);

export const deleteDealership = createAsyncThunk<any, string, { rejectValue: string; state: RootState}>(
  'dealership/delete',
  async (id, {rejectWithValue, getState}) => {
      try {
          const {user} = getState();
          const token = user.accessToken;

          const response = await destroyDealership(token, id);
          return response.data;

      } catch (error: any) {
          if (error.response) {
              return rejectWithValue(error.response.data.message);
          } else {
              return rejectWithValue('An error occurred during deletion.');
          }
      }
  } 

);

export const updateDealership = createAsyncThunk<
  any, 
{
  id:string;
  name?:string;
  city?:string;
  phone?:string;
  email?:string;
  website_url?:string;
  supplies_url?:string;
  region_id?:string;
  country_id?:string;
},
{ rejectValue: string; state: RootState}>(
  'dealership/update',
  async (dealership, {rejectWithValue, getState}) => {
    try {
      const {user} = getState();
      const token = user.accessToken;

      const response = await putDealership(token, dealership);
      return response.data;

    } catch (error: any) {
        if (error.response) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue('An error occurred during updating.');
        }
    }
  }
);

export const updateDealershipCorporate = createAsyncThunk<
  any, 
{
  id:string;
  corporate_user_id?:string|null;
},
{ rejectValue: string; state: RootState}>(
  'dealership/corporate/update',
  async (dealership, {rejectWithValue, getState}) => {
    try {
      const {user} = getState();
      const token = user.accessToken;

      const response = await putDealershipCorporate(token, dealership);
      return response.data;

    } catch (error: any) {
        if (error.response) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue('An error occurred during updating.');
        }
    }
  }
);

export const assignStaff = createAsyncThunk<any, {dealership_id:string, users:User[], type:'owner'|'dealer'|'staff'}, { rejectValue: string; state: RootState}>(
  'dealership/assign',
  async (updateInfo, {rejectWithValue, getState}) => {
      try {
          const {user} = getState();
          const token = user.accessToken;

          const response = await assignStaffApi(token, updateInfo);
          return response.data;

      } catch (error: any) {
          if (error.response) {
              return rejectWithValue(error.response.data.message);
          } else {
              return rejectWithValue('An error occurred during assigning.');
          }
      }
  } 
);

export const removeStaff = createAsyncThunk<any, {dealership_id:string, users:User[], type:'owner'|'dealer'|'staff'}, { rejectValue: string; state: RootState}>(
  'dealership/remove',
  async (updateInfo, {rejectWithValue, getState}) => {
      try {
          const {user} = getState();
          const token = user.accessToken;

          const response = await removeStaffApi(token, updateInfo);
          return response.data;

      } catch (error: any) {
          if (error.response) {
              return rejectWithValue(error.response.data.message);
          } else {
              return rejectWithValue('An error occurred during removal.');
          }
      }
  } 
);