import './PermissionsPage.css'
import React, {  useEffect } from "react";
import Header from '../../components/common/Header/Header';
import { AppDispatch } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import PermissionsContainer from './components/PermissionsContainer';
import { fetchPermissions } from '../../redux/actions/permissions.actions';

const PermissionsPage = () => {
  const dispatch: AppDispatch = useDispatch();
  const searchParams = useSearchParams()[0];

  // Dispatch UseEffects
  useEffect(() => {
    dispatch(fetchPermissions(searchParams.toString()));
  }, [dispatch, searchParams]);
  
  return (
      <>
        <Header/>
        <PermissionsContainer/>
      </>
  );
};

export default PermissionsPage;
