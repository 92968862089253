import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Radio,
  RadioGroup ,
  FormControlLabel,
  FormLabel, 
  capitalize,
  CircularProgress
} from "@mui/material";
import UsersTable from "../../../components/common/Tables/UsersTable";
import RootState from "../../../redux/states/root.state";
import { useSearchParams } from "react-router-dom";
import { Role } from "../../../redux/models/role.model";
import useRoleCheck from "../../../hooks/useRoleCheck";

interface ContainerProps {
  checked: any[];
  setChecked: any;
  setEditOpen: any;
  setDeleteOpen: any;
  setApplyOpen: any;
  setUser: any;
  setRole: any;
}

const UsersContainer: React.FC<ContainerProps> = ({
  checked,
  setChecked,
  setEditOpen,
  setDeleteOpen,
  setApplyOpen,
  setUser,
  setRole,
}) => {
  // Data
  const {data:users} = useSelector((state: RootState) => state.users);
  const {data:roles,loading} = useSelector((state: RootState) => state.roles);
  const [searchParams, setSearchParams] = useSearchParams();

  const canAssignAdmin = useRoleCheck(['admin','developer']);
  const canAssignOwner = useRoleCheck(['corporate']) || canAssignAdmin;
  const canAssignDealer = useRoleCheck(['owner']) || canAssignOwner;
  const canAssignStaff = useRoleCheck(['dealer']) || canAssignDealer;

  // Search and Filter
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [userRole, setUserRole] = useState("");

  // Checked items
  const [isCheckAll, setCheckAll] = useState(false);

  useEffect(() => {
    setChecked(users?.map((u)=>{return {id:u?.id, is_checked:false}}))
  }, [users,setChecked]);

  const isDisabled = () => {
    return checked.find(obj=>obj?.is_checked===true)===undefined
  }

  const handleFilter = () => {
    searchParams.delete('page'); //reset page
    username==="" ? searchParams.delete('username') : searchParams.set('username', username);
    email==="" ? searchParams.delete('email') : searchParams.set('email', email);
    name==="" ? searchParams.delete('name') : searchParams.set('name', name);
    userRole==="" ? searchParams.delete('role') : searchParams.set('role', userRole);
    setSearchParams(searchParams);
  }

  const handleClear = () => {
    searchParams.delete('username');
    searchParams.delete('email');
    searchParams.delete('name');
    searchParams.delete('role');
    searchParams.delete('page');
    setSearchParams(searchParams);
  } 

  return (
    <>
    <div className="container">
        <div className="list">
          <div className="filters">
            <div className="search">
              <TextField
                id="username-search"
                label="Username"
                type="search"
                fullWidth={true}
                onChange={(e)=>setUsername(e.target.value)}
                onKeyDown={(e)=>(e.code==="Enter" ? handleFilter() : null)}
                size="small"
              />

              <TextField
                id="email-search"
                label="Email"
                type="search"
                fullWidth={true}
                onChange={(e)=>setEmail(e.target.value)}
                onKeyDown={(e)=>(e.code==="Enter" ? handleFilter() : null)}
                size="small"
              />

              <TextField
                id="name-search"
                label="Name"
                type="search"
                fullWidth={true}
                onChange={(e)=>setName(e.target.value)}
                onKeyDown={(e)=>(e.code==="Enter" ? handleFilter() : null)}
                size="small"
              />

              <FormControl fullWidth size="small">
                <InputLabel>Role</InputLabel>
                <Select
                  label="User Role"
                  value={userRole}
                  onChange={e => setUserRole(e.target.value)}
                >
                  <MenuItem value={""}>All</MenuItem>
                  <MenuItem value={"admin"}>Admin</MenuItem>
                  <MenuItem value={"developer"}>Developer</MenuItem>
                  <MenuItem value={"corporate"}>Corporate</MenuItem>
                  <MenuItem value={"dealer"}>Dealer</MenuItem>
                  <MenuItem value={"owner"}>Owner</MenuItem>
                  <MenuItem value={"technician"}>Technician</MenuItem>
                  <MenuItem value={"sales"}>Sales</MenuItem>
                  <MenuItem value={"customer"}>Customer</MenuItem>
                </Select>
              </FormControl>

              <div className="filter-btn-group">
                <button onClick={handleFilter}>Apply Filters</button>
                <button onClick={handleClear}>Clear</button>
              </div>
            </div>

            <div className="firmware-updates">
              <div className="role-checkboxes">
              <FormControl>
                <FormLabel id="role-radio-buttons">Roles</FormLabel>
                <RadioGroup
                  aria-labelledby="radio-buttons-group-label"
                  name="radio-buttons-group"
                  onChange={(e) => setRole(e.target.value)}
                >
                  { loading?
                  <CircularProgress size={"24px"}/>
                  :
                  
                  roles.map((role:Role)=>
                  (((role?.name==='admin'||role?.name==='developer'||role?.name==='corporate') && canAssignAdmin)
                  || ((role?.name==='owner') && canAssignOwner)
                  || ((role?.name==='dealer') && canAssignDealer)
                  || ((role?.name==='sales'||role?.name==='technician'||(role?.name==='customer')) && canAssignStaff)
                  )
                    &&
                    <FormControlLabel key={role?.id} value={role?.id} control={<Radio />} label={capitalize(role?.name)} disabled={isDisabled()}/>
                  )
                  }
                </RadioGroup>
              </FormControl>
              </div>

              <div className="filter-btn-group">
                <button disabled={isDisabled()} onClick={()=>setApplyOpen(true)}>Apply Roles</button>
              </div>
            </div>

          </div>
          
          <UsersTable 
            setEditOpen={setEditOpen} 
            setDeleteOpen={setDeleteOpen} 
            setUser={setUser} 
            isChecked={checked} 
            setChecked={setChecked} 
            isCheckAll={isCheckAll} 
            setCheckAll={setCheckAll} 
          />
              
        </div>
      </div>     
    </>
  );
};

export default UsersContainer;
