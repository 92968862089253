import axios from 'axios';
import { BASE_URL } from './apiconfig';

export const getCommands = async (token: string | null, params: string) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/commands?${params}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.log("Failed to pull list of commands. ", error);
    throw error;
  }
}

export const getSpaCommands = async (token: string | null, request: {spa_id:string,params: string}) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/spas/${request.spa_id}/commands?${request.params}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.log("Failed to pull list of commands. ", error);
    throw error;
  }
}

export const getSpaCommandsHistory= async (token: string | null, params:string) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/spa-commands?${params}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.log("Failed to pull list of commands. ", error);
    throw error;
  }
}

export const addCommandApi = async (token: string | null, commandInfo : {title: string, body: any}) => {
    try {
        const response = await axios.post(`${BASE_URL}/commands`, {
            title: commandInfo.title,
            body: commandInfo.body
        },{
            headers: { Authorization: `Bearer ${token}` }
        });

        return response.data;
    } catch (error) {
        console.log("Failed to add command. ", error);
        throw error;
    }
};

export const editCommandApi = async (token: string | null, commandInfo : {id:string, title: string, body: any}) => {
    try {
      const { data } = await axios.put(`${BASE_URL}/commands/${commandInfo.id}`, {
        title: commandInfo.title,
        body: commandInfo.body
    } , {
        headers: { Authorization: `Bearer ${token}` }
        // Get updated user 
      })
      return data;
    } catch (error) {
    console.log("Failed to edit command. ", error);
      throw error;
    }
  };

export const deleteCommandApi = async (token: string | null, id: string) => {
    try {
        const { data } = await axios.delete(`${BASE_URL}/commands/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
        })
        return data;
    } catch (error) {
        console.log("Failed to delete command. ", error);
        throw error;
    }
}

export const addSpaCommandsApi = async (token: string | null, params:{command_id:string, spa_id:string, log_level:string}) => {
    try {
      const { data } = await axios.post(`${BASE_URL}/commands/assign-to-spa`, {
        command_id:params.command_id,
        spa_id: params.spa_id,
        log_level: params.log_level
      },{
        headers: { Authorization: `Bearer ${token}` },
      })
      return data;
    } catch (error) {
      console.log("Failed to add commands to spa. ", error);
      throw error;
    }
  }
