import "./Dialog.css";
import React, { useEffect, useState } from 'react';

interface DialogProps {
    open: boolean,
    setOpen: any,
}

const ImpersonateDialog: React.FC<DialogProps> = ({open,setOpen}) => {
    const [selection, setSelection] = useState('customer');
    var impersonating = sessionStorage.getItem("impersonate");
    
    
      useEffect(() => {
        if (impersonating) {
            setSelection(impersonating);
        } else {
            setOpen(false);
        }
      }, [impersonating]);
    

    const handleSelection = (e:any) => {
        let val = e.target.value;
        setSelection(val);
        sessionStorage.setItem("impersonate", val);
        window.dispatchEvent( new Event('impersonate') ); 
    }

    const handleLeave = () => {
        setSelection('customer');
        sessionStorage.removeItem('impersonate');
        setOpen(false);
        window.dispatchEvent( new Event('impersonate') ); 
    }

    return (
        <div className={`dialog-container ${open? `show` : `hidden`}`}>
            <div className='dialog-content'>
                <div className="impersonation-action">
                    <p>Viewing Portal as </p>
                    <select 
                        className="impersonation-select" 
                        name="impersonation" 
                        id="impersonation-select" 
                        value={selection} 
                        onChange={(e)=>handleSelection(e)}>

                        <option value="owner">Owner</option>
                        <option value="dealer">Dealer</option>
                        <option value="sales">Sales</option>
                        <option value="technician">Technician</option>
                        <option value="customer">Customer</option>
                    </select>
                </div>
                <div className="modal-btn-group">
                    <button className="as-text" onClick={()=>handleLeave()}> LEAVE </button>
                </div>
            </div>
        </div>
    )
};

export default ImpersonateDialog;