import axios from 'axios';
import { BASE_URL } from './apiconfig';

export const getCountries = async (token: string | null) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/countries`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.log("Failed to pull countries list. ", error);
    throw error;
  }
}

export const getCountry = async (token: string | null, id: string) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/countries/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return data;
  } catch (error) {
    console.log("Failed to pull countries by id. ", error);
    throw error;
  }
}

export const getRegions = async (token: string | null, id: string) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/countries/${id}/regions`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return data;
  } catch (error) {
    console.log("Failed to pull regions by country. ", error);
    throw error;
  }
}