import { useState, useEffect } from 'react';
import roleCheck from '../components/Layout/roleCheck';
import { useSelector } from 'react-redux';
import RootState from '../redux/states/root.state';

const useRoleCheck = (rolesToCheck: string[], ignoreImpersonation?:boolean): any => {
    const {data:user} = useSelector((state: RootState) => state.user);
    const [canAccess, setCanAccess] = useState(false);

    window.addEventListener("impersonate", (event) => {
        var impersonating = sessionStorage.getItem("impersonate");
        let canImpersonate = !ignoreImpersonation  // not ignoring impersonation
                            && impersonating!==null  // there is an impersonation in storage
                            && !['admin','developer','corporate'].includes(impersonating)  // the impersonation is NOT of these roles
                            && roleCheck(user,['admin','developer','corporate']);  // the user IS one of these roles
        
        if (impersonating && canImpersonate) {
            setCanAccess(rolesToCheck.includes(impersonating)|| false);

        } else {
            setCanAccess(roleCheck(user,rolesToCheck))
        }
      });

    useEffect(() => {
        var impersonating = sessionStorage.getItem("impersonate");
        let canImpersonate = !ignoreImpersonation  // not ignoring impersonation
                            && impersonating!==null  // there is an impersonation in storage
                            && !['admin','developer','corporate'].includes(impersonating)  // the impersonation is NOT of these roles
                            && roleCheck(user,['admin','developer','corporate']);  // the user IS one of these roles
        
        if (impersonating && canImpersonate) {
            setCanAccess(rolesToCheck.includes(impersonating)|| false);

        } else {
            setCanAccess(roleCheck(user,rolesToCheck))
        }

    }, [ignoreImpersonation,user,rolesToCheck]);

    return canAccess;
};

export default useRoleCheck;
