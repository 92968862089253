import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import RoleState from '../states/role.state';
import { fetchRole, updateRole } from '../actions/roles.actions';

const initialState: RoleState = {
    loading: false,
    data: [],
    error: null,
};

const roleSlice = createSlice({
    name: 'role',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchRole.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchRole.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchRole.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload ?? null;
            })

            .addCase(updateRole.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateRole.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(updateRole.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload ?? null;
            });
    },
});

export default roleSlice.reducer;