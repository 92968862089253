// src/redux/reducers/errorLog.reducer.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import SpaHistoryState from '../states/spaHistory.state';
import { fetchSpaHistory } from '../actions/spaHistory.actions';

const initialState: SpaHistoryState = {
  loading: false,
  data: [],
  error: null,
};

const SpaHistorySlice = createSlice({
  name: 'spa-history',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSpaHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSpaHistory.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchSpaHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'An error occurred';
      });
  },
});

export default SpaHistorySlice.reducer;