import axios from 'axios';
import { BASE_URL } from './apiconfig';
import User from '../../redux/models/user.model';
import { Permission } from '../../redux/models/permission.model';

export const getUserGroups = async (token: string | null, params:string) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/admin/user-groups?${params}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.log("Failed to pull user groups list. ", error);
    throw error;
  }
}

export const getUserGroup = async (token: string | null, id:string) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/admin/user-groups/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.log("Failed to fetch user group. ", error);
    throw error;
  }
}

export const getUserGroupUsers = async (token: string | null, id:string) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/admin/user-groups/${id}/users`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.log("Failed to fetch user groups' users. ", error);
    throw error;
  }
}

export const putUserGroup = async (token: string | null, updateInfo:{id:string, name:string, description:string}) => {
  try {
    const { data } = await axios.put(`${BASE_URL}/admin/user-groups/${updateInfo.id}`,{
      name: updateInfo.name,
      description: updateInfo.description
    },{
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.log("Failed to update user group. ", error);
    throw error;
  }
}

export const deleteUserGroupApi = async (token: string | null, id:string) => {
  try {
    const { data } = await axios.delete(`${BASE_URL}/admin/user-groups/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.log("Failed to delete user group. ", error);
    throw error;
  }
}

export const assignUserGroupUsersApi = async (token: string | null, groupInfo: {id:string, users:User[]}) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/admin/user-groups/assign-user`, {
      user_group_id: groupInfo.id,
      users: groupInfo.users
    },{
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.log("Failed to assign user group to users. ", error);
    throw error;
  }
}

export const assignUserGroupPermissionsApi = async (token: string | null, groupInfo: {id:string, permissions:Permission[]}) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/admin/user-groups/assign-permission`, {
      user_group_id: groupInfo.id,
      permissions: groupInfo.permissions
    },{
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.log("Failed to assign user group to permissions. ", error);
    throw error;
  }
}

export const removeUserGroupUsersApi = async (token: string | null, groupInfo: {id:string, users:User[]}) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/admin/user-groups/remove-user`, {
      user_group_id: groupInfo.id,
      users: groupInfo.users
    },{
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.log("Failed to remove user group from users. ", error);
    throw error;
  }
}

export const removeUserGroupPermissionsApi = async (token: string | null, groupInfo: {id:string, permissions:Permission[]}) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/admin/user-groups/remove-permission`, {
      user_group_id: groupInfo.id,
      permissions: groupInfo.permissions
    },{
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.log("Failed to remove user group from permissions. ", error);
    throw error;
  }
}