import styles from "../../../assets/scss/Modal.module.scss";
import React, { useState } from "react";
import {CircularProgress, Modal} from '@mui/material';
import { ModalProps } from "../../../components/common/Interfaces/Modals";
import RootState from "../../../redux/states/root.state";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { deleteDealership } from "../../../redux/actions/dealership.actions";


const DeleteDealershipModal: React.FC<ModalProps> = ({open, setOpen, object}) => {
  const dealership = object;
  const dispatch: AppDispatch = useDispatch();
  const {loading, error} = useSelector((state: RootState) => state.dealership);
  const [submitted, setSubmitted] = useState(false);

  const handleClose = () => {
    setSubmitted(false);
    setOpen(false);
  };

  const handleDelete = async () => {
    setSubmitted(true);
    await dispatch(deleteDealership(dealership?.id));
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <div className={styles.modal}>
            { loading && !submitted ?
            <div className={styles.header}>
              <p> Loading... </p>
            </div>
            : loading && submitted ?
            <div className={styles.header}>
              <p> Deleting... </p>
            </div>
            :
            error ?
            <div className={styles.header}>
              <p> An Error Occurred </p>
            </div>
            :
            submitted ?
            <div className={styles.header}>
              <p> Success </p>
            </div>
            :
            <div className={styles.header}>
              <p> Delete <strong>{dealership?.name}</strong>?</p>
            </div>
            }

          { loading && !submitted ?
            <div className={styles.body}>
              <p><CircularProgress size={"24px"} sx={{position:'absolute', top:'50%', right:'50%'}}/></p>
            </div>
            : loading && submitted ?
            <div className={styles.body}>
                <p><CircularProgress size={"24px"} sx={{position:'absolute', top:'50%', right:'50%'}}/></p>
            </div>
            :
            error ?
            <div className={styles.body}>
                <p>{error}</p>
            </div>
            :
            submitted ?
            <div className={styles.body}>
                <p>Dealership successfully deleted.</p>
            </div>
            :
            <div className={styles.body}>
                <p>This action cannot be undone. </p>
            </div>
            }

            {
              error||submitted ?
              <div className="modal-btn-group">
                <></>
                <button className="as-text" onClick={handleClose}> OK </button>
              </div>
              :
              loading ? 
              <></>
              :
              <div className="modal-btn-group">
                <button className={styles.delete} onClick={handleDelete}> Delete </button>
                <button className="as-text" onClick={handleClose}> Cancel </button>
              </div>
            } 

        </div>
      </Modal>
    </div>
  );
};

export default DeleteDealershipModal;
