export enum LiveReportLabels {
    STemp="Spa Temp.",
    TSP="Set Point",
    HTemp="Heater Temp.",
    SBConnected="SpaBoy Connected",
    Filter="Filter",
    P1="Pump1",
    H1="Heater1",
    Current="Current",
    sbVout="Electrode Voltage",
    sbI1="Electrode1 Current",
    sbI2="Electrode2 Current",
    sbpH="pH",
    sbORP="ORP",
}

export enum PumpState {
    'Off'=0,
    'Low'=15,
    'High'=100,
}

export enum SBConState {
    'Disconnected'=0,
    'Connected'=1,
}