// src/redux/reducers/errorLog.reducer.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { deleteFirmware, fetchAvailableFirmware, updateFirmware, uploadFirmware } from '../actions/firmware.actions';
import FirmwareState from '../states/firmware.state';

const initialState: FirmwareState = {
  loading: false,
  data: [],
  error: null,
  meta: null,
};

const FirmwareSlice = createSlice({
  name: 'firmware',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAvailableFirmware.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAvailableFirmware.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchAvailableFirmware.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'An error occurred';
      })
      .addCase(deleteFirmware.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteFirmware.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = [...state.data].filter((firmware:any)=>{return firmware?.id!==action.payload});
      })
      .addCase(deleteFirmware.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'An error occurred';
      })
      .addCase(updateFirmware.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateFirmware.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = [...state.data].map((firmware:any)=>firmware?.id===action.payload?.id ? action.payload : firmware);
      })
      .addCase(updateFirmware.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'An error occurred';
      })

      .addCase(uploadFirmware.pending, (state) => {
        state.error = null;
      })
      .addCase(uploadFirmware.fulfilled, (state, action: PayloadAction<any>) => {
        state.data = [...state.data].map((firmware:any)=>firmware?.id===action.payload?.id ? action.payload : firmware?.is_latest && firmware?.type===action.payload?.type ? {...firmware, is_latest:false} : firmware);
      })
      .addCase(uploadFirmware.rejected, (state, action) => {
        state.error = action.payload || 'An error occurred';
      });
  },
});

export default FirmwareSlice.reducer;