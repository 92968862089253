import "./DealershipDetailsPage.css"
import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../redux/states/root.state";
import Header from "../../components/common/Header/Header";
import { AppDispatch } from "../../redux/store";
import { useParams, useSearchParams } from "react-router-dom";
import { fetchDealership, fetchStaff } from "../../redux/actions/dealership.actions";
import { CircularProgress } from "@mui/material";
import { EditIcon, LinkOut } from "../../assets/svg/svg";
import EditDealershipModal from "./components/EditDealershipModal";
import EditDealershipStaffModal from "./components/EditDealershipStaffModal";
import StaffUsersTable from "../../components/common/Tables/StaffUsersTable";
import useRoleCheck from "../../hooks/useRoleCheck";

const DealershipDetailsPage: React.FC = () => {
  // Data
  const {id} = useParams();
  const dispatch: AppDispatch = useDispatch();
  const searchParams = useSearchParams()[0];
  // Checked items
  const [roleName, setRoleName] = useState<any>(null);

  const [editOpen, setEditOpen] = useState(false);
  const [editStaffOpen, setEditStaffOpen] = useState(false);
  
  // Data
  const {data: dealership, loading:isLoading} = useSelector((state: RootState) => state.dealership);
  const {data:user} = useSelector((state: RootState) => state.user);

  const isCorporate = useRoleCheck(["corporate"]) && user?.id===dealership?.corporate?.id;
  const isOwner = useRoleCheck(["owner"]) && user?.id===dealership?.owner?.id;
  const isDealer = useRoleCheck(["dealer"]) && user?.id===dealership?.dealer?.id;
  const canEditDealership = useRoleCheck(["admin","developer"]) || isCorporate || isOwner || isDealer || false;

  useEffect(() => {
    if (id){
      dispatch(fetchDealership(id));
      dispatch(fetchStaff({id:id, params: searchParams.toString()}));
    }
  }, [dispatch,id,searchParams]);


  return (
    <>
    <EditDealershipModal object={dealership} open={editOpen} setOpen={setEditOpen}/>
    <EditDealershipStaffModal dealership_id={dealership?.id??''} isOpen={editStaffOpen} setOpen={setEditStaffOpen} role={roleName}/>
    <Header/>
    <div className="container">
    {isLoading ? 
        <CircularProgress size={"24px"} sx={{position:'absolute', top:'50%', right:'50%'}}/>
      :
        <div className="list">
          <div className="filters user">
            <div className="card-container details">
              
              <div className="card-title">
                <strong>{dealership?.name}</strong>
                {canEditDealership && <div className="clickable-icon" onClick={()=>setEditOpen(true)}><EditIcon/></div>}
              </div>
              <hr></hr>
              <div><strong>Corporation: </strong>{dealership?.corporate?.name??``}</div>
              <div><strong>Owner: </strong>{dealership?.owner?.name ?? ''}</div>
              <div><strong>Dealer: </strong>{dealership?.dealer?.name ?? ''}</div>
              <div><strong>City: </strong>{dealership?.city??``}</div>
              <div><strong>Region: </strong>{dealership?.region?.label??``}</div>
              <div><strong>Country: </strong>{dealership?.country?.label??``}</div>

            </div>

            <div className="card-container details">
              <div className="card-title">
                <strong>Contact</strong>
              </div>
              <hr></hr>
              <div><strong>Phone: </strong>{dealership?.phone ?? ''}</div>
              <div><strong>Email: </strong>{dealership?.email ?? ''}</div>
              <div className="links"><strong>Website: </strong>                      
              {dealership?.website_url?
                <a className="clickable-icon" href={dealership?.website_url} target="_blank" rel="noreferrer">
                  <LinkOut/>
                </a>
                :
                <></>
              }</div>
              <div className="links"><strong>Supplies: </strong>
              {dealership?.supplies_url?
                <a className="clickable-icon" href={dealership?.supplies_url} target="_blank" rel="noreferrer">
                  <LinkOut/>
                </a>
                :
                <></>
              }</div>
            </div>
          </div>

          <div className="table-section">
            <StaffUsersTable/>
            <div className="filter-btn-group spa-actions">
              {canEditDealership && <button onClick={()=>{setRoleName('sales');setEditStaffOpen(true)}}>Modify Sales Staff</button>}
              {canEditDealership && <button onClick={()=>{setRoleName('technician');setEditStaffOpen(true)}}>Modify Technicians</button>}
            </div>
          </div>   

        </div>}
      </div>     
    </>
  );
};

export default DealershipDetailsPage;
