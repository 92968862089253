import React, {useEffect, useState} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import RootState from "../../../redux/states/root.state";
import {
  CircularProgress, 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableRow,
  TableSortLabel,
  Box,
  Pagination,
} from "@mui/material";
import { visuallyHidden } from '@mui/utils';

type Order = 'asc' | 'desc';
const headers = [
  {id:'checked', label:'', align:'center'}, 
  {id:'id', label:'ID', align:null},
  {id:'name', label:'User', align:null},
  {id:'username', label:'Username', align:null},
  {id:'roles', label:'Roles', align:null},
];

interface TableProps {
  checked?: any,
  setChecked?: any,
  isCheckAll?: any,
  setCheckAll?:any,
  page?: 'roles'|'user_groups'|'dealership',
}

const SimpleUsersTable: React.FC<TableProps> = ({
  checked=null,
  setChecked=null,
  isCheckAll=null,
  setCheckAll=null,
  page='roles',
}) => {
  const navigate = useNavigate();
  const {data:users, loading:isLoading, meta} = useSelector((state: RootState) => state.roleUsers);
  const [searchParams, setSearchParams] = useSearchParams();
  
  // Table Sort
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('');
  const [sortedRows, setSortedRows] = useState<any[]>([]);
  
  useEffect(() => {
    setSortedRows(users);
  }, [users]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    header_id: string,
  ) => {
    const isAsc = orderBy === header_id && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(header_id);

    handleSort();
  };

  const handleSort = () => {
    let newArray = [...users].sort((a:any, b:any)=>{
      let isAsc = order ==='asc';
      switch (orderBy){
        default:
          return a[orderBy]===b[orderBy] ? 0 : isAsc ? a[orderBy]>b[orderBy] ? -1 : 1 : a[orderBy]<b[orderBy] ? -1 : 1;
       
      }
    })
    setSortedRows(newArray);
  }

  const handleChange = (event:any, value:any) => {
    searchParams.set("page", value);
    setSearchParams(searchParams);
  }

  // Handle Selecting Users
  const handleCheck = (spa:any) => {
    setCheckAll(false);
    let newArr = [...checked];
    let found = checked?.find((checked_spa:any)=>spa?.id===checked_spa?.id)!==undefined;
    if(found){
      newArr = newArr.filter((checked_spa:any)=>checked_spa?.id!==spa?.id);
    } else {
      newArr.push(spa);
    }
    setChecked(newArr);
  }

  const checkAll = (e:any) => {
    setCheckAll(e.target.checked);
    e.target.checked ? setChecked([...users]) : setChecked([])
  }

  const isChecked=(spa:any)=>{
    return checked?.find((checked_spa:any)=>spa?.id===checked_spa?.id)!==undefined
  }
  
  return (
    <>
      <div className="table" style={{padding:'1rem'}}>
            {isLoading ? ( 
              <CircularProgress size={"24px"} sx={{position:'absolute', top:'50%', right:'50%'}}/>
            ): 
            users.length>0 ? (
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                    {headers.map((header)=>(
                      ((page!=='dealership' && header.id!=='roles')||(page==='dealership' && header.id!=='checked')) &&
                    <TableCell key={header.id} sx={{color:'var(--title-gray)',background:`var(--as-white)`, fontSize:'1rem'}}>
                      {header.id!=='checked'? 
                      <TableSortLabel
                        active={orderBy === header.id}
                        direction={orderBy === header.id ? order : 'asc'}
                        onClick={(e)=>handleRequestSort(e,header.id)}
                        >
                        {header.label}
                        {orderBy === header.id ? (
                            <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                      </TableSortLabel>
                      :
                      (header.id!=='checked' ? header.label : <input id="all-checked" className="table-check" type="checkbox" checked={isCheckAll} onChange={(e)=>checkAll(e)}></input>)
                    }
                  </TableCell>
                      )
                    )}
                </TableRow>
              </TableHead>

              <TableBody>
                {sortedRows.map((user:any, i:number)=>(
                  <TableRow key={i}>
                    <TableCell>
                      <input id={`${user?.id}-checked`} className="table-check" type="checkbox" checked={isChecked(user)} onChange={()=>handleCheck(user)}></input>
                    </TableCell>

                    <TableCell>
                    <div className="clickable-icon" onClick={()=>navigate(`/user/${user.id}`)}>
                      {user?.id}
                    </div>
                    </TableCell>

                    <TableCell>
                      {user?.name}
                    </TableCell>

                    <TableCell>
                      {user?.username}
                    </TableCell>

                  {
                    page==='dealership' &&
                    <TableCell>
                      {user?.roles?.map((role:any)=>role?.name).toString()}
                    </TableCell>

                  }
                   
                  </TableRow>
                ))}
              </TableBody>

            </Table>
            ):(
            <div className="empty-table">No Users Found.</div>
            )
          }
          </div>

          <div className="table-footer">
            <div className="pagination">
              <Pagination 
                count={meta?.last_page} 
                variant="outlined" 
                onChange={handleChange}
                page={searchParams.get("page")?Number(searchParams.get("page")):1}
                showLastButton 
                showFirstButton 
                disabled={meta?.last_page===1}
              />
              <div className="total-count">Showing {users?.length??0}/{meta?.total??0} Users</div>
            </div>
          </div>
    </>
  );
};

export default SimpleUsersTable;
