import "./UserDetailsPage.css"
import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { AppDispatch } from "../../redux/store";
import { fetchUserSpas } from "../../redux/actions/spas.actions";
import RootState from "../../redux/states/root.state";
import Header from "../../components/common/Header/Header";
import { EditIcon } from "../../assets/svg/svg";
import EditUserModal from "../../components/common/Modals/EditUserModal";
import SpasTable from "../../components/common/Tables/SpasTable";
import { fetchUserById } from "../../redux/actions/users.actions";
import { CircularProgress } from "@mui/material";
import NotFound from "../General/NotFound";
import EditSpaModal from "../../components/common/Modals/EditSpaModal";
import DeleteSpaModal from "../../components/common/Modals/DeleteSpaModal";
import { fetchRoles } from "../../redux/actions/roles.actions";
import { fetchUserData } from "../../redux/actions/user.actions";

const UserDetailsPage: React.FC = () => {
  // Data
  const {id} = useParams();
  const dispatch: AppDispatch = useDispatch();
  const {data:user, error,} = useSelector((state: RootState) => state.usersById);
  const isLoading = [useSelector((state: RootState) => state.spas.loading), useSelector((state: RootState) => state.usersById.loading), id!==user?.id]
  const searchParams = useSearchParams()[0];

  //Modal Handling
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editSpaOpen, setEditSpaOpen] = useState(false);

  const [spaToModify, setSpaToModify] = useState<any>(null);


  useEffect(() => {
    dispatch(fetchUserData());
    if (id) {
      dispatch(fetchUserById(id));
      dispatch(fetchUserSpas({page:searchParams.toString(), uuid:id}));
      dispatch(fetchRoles(""));
    }
  }, [dispatch,id,searchParams]);

  if (error){
    return <NotFound/>
  }
  
  return (
    <>
    <EditUserModal open={editOpen} setOpen={setEditOpen} object_id={user?.id}/>
    <DeleteSpaModal open={deleteOpen} setOpen={setDeleteOpen} object={spaToModify}/>
    <EditSpaModal open={editSpaOpen} setOpen={setEditSpaOpen} object_id={spaToModify?.id}/>
      
    <Header/>
    <div className="container">
      {isLoading.includes(true) ? 
        <CircularProgress size={"24px"} sx={{position:'absolute', top:'50%', right:'50%'}}/>
      :
        <div className="list">
            <div className="filters">
              <div className="card-container details">
                
                <div className="card-title">
                  <strong>{user?.name}</strong>
                  <div className="clickable-icon" onClick={()=>setEditOpen(true)}><EditIcon/></div>
                </div>
                <hr></hr>
                <div>{user?.username}</div>
                <div>{user?.email}</div>

                <div className="card-title">
                  <strong>Roles</strong>
                </div>
                <hr></hr>
                
                <div className="roles">
                {user?.roles!==undefined && user?.roles.length>0 ?
                    user.roles.map((r:any, i:number)=>
                    <div key={i} style={{textTransform: 'capitalize'}}>{r.name}</div>
                    )
                    :
                    <div>None</div>
                  } 
                </div>
              </div>

              <div className="card-container details">
                <div className="card-title"><strong>Preferred Dealership</strong></div>
                <hr></hr>

              </div>

            </div>
            
            <SpasTable 
              pageName={`user-details`}
              setEditOpen={setEditSpaOpen}
              setDeleteOpen={setDeleteOpen}
              setSpaToModify={setSpaToModify}
              />
                
          </div>
        }
      </div>   
    </>
  );
};

export default UserDetailsPage;
