import styles from "../../../assets/scss/Modal.module.scss";
import React, { useState } from "react";
import {CircularProgress, Modal} from '@mui/material';
import { AppDispatch } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { removeRole } from "../../../redux/actions/roles.actions";
import { useParams } from "react-router-dom";
import RootState from "../../../redux/states/root.state";
import { ModalProps } from "../../../components/common/Interfaces/Modals";


const RemoveUsersModal: React.FC<ModalProps> = ({
  open, 
  setOpen, 
  selection,
  setSelection,
}) => {
  const users = selection??[];
  const dispatch: AppDispatch = useDispatch();
  const {loading, error} = useSelector((state: RootState) => state.roleUsers);
  const {id} = useParams();
  const [submitted, setSubmitted] = useState(false);

  const handleClose = () => {
    setSubmitted(false);
    setOpen(false);
  };

  const handleRemove = async () => {
    setSubmitted(true);
    await dispatch(removeRole({role_id:id??'', users:users}));
    setSelection([]);
  }

  if (!submitted) {
    return (
      <div>
        <Modal
          open={open}
          onClose={handleClose}
        >
          <div className={styles.modal}>
            <div className={styles.header}>
              {users.length>1 ? 
              <p>{`Remove ${users.length} users from role?`}</p>
              :
                <p className="name-guid">
                  {`Remove ${users[0]?.username} from role?`}
                </p>
              }
            </div>

            <div className={styles.body}>
            <div className="spas-title">{users.length>1? `Remove these users `:`Remove this user `}</div>
            {users.length>0 ? 
              <div className="select-container">
                  <div className="user-select">
                    {users.map((user:any)=>(
                      <div key={user.id}>{user?.username ? `${user?.username} (${user?.id})` :`${user?.id}`}</div>
                    ))}
                  </div>
                </div>
                :
                <div>No users selected.</div>
              }
              <br></br>
              <button className="button request" disabled={users.length===0} onClick={handleRemove}>Remove</button>
            </div>

            <div className="modal-btn-group">
              <button className="as-text" onClick={handleClose}> Close </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  } else {
    return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <div className={styles.modal}>
            <div className={styles.header}>
                {loading?
                  <p>Removing Users...</p>
                :
                error ?
                  <p>An Error Occurred</p>

                :
                  <p>Success</p>
                }
                
            </div>
            <div className={styles.body}>
                {
                loading ?
                  <CircularProgress size={"24px"} />
                              :
                error?
                  <div>{error}</div>
                :
                  <p> Users successfully removed from role. </p>
                }
            </div>
            {
              !loading ?
                <div className="modal-btn-group">
                  <div></div>
                  <button className="as-text" onClick={handleClose}> OK </button>                        
                </div>
            :
              <></>
            }
        </div>
      </Modal>
    </div>
    )
}
};

export default RemoveUsersModal;
