import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import "./UserSelect.css"
import React, {useEffect, useState} from "react";
import RootState from "../../../redux/states/root.state";
import { CircularProgress, Pagination } from "@mui/material";
import { fetchRoleUsers } from "../../../redux/actions/roles.actions";

interface UserSelectProps {
    setSelectedUser: any;
    currentUser: any;
    role: any;
}

const SingleSelectUserRole: React.FC<UserSelectProps> = ({ setSelectedUser, currentUser, role}) => {
const dispatch: AppDispatch = useDispatch();
const {data:users,meta,loading} = useSelector((state: RootState) => state.roleUsers);
const [pageNumber, setPageNumber] = useState(1);

useEffect(() => {
    let page = `?page=${pageNumber}`;
    dispatch(fetchRoleUsers({id:role?.id, page:page}))
  }, [dispatch,pageNumber, role]);

  return (
    <>
    { loading ?
        <div className="no-users"><CircularProgress size={"24px"}/></div>
    :
    users.length>0 ? 
        <div className="select-container">
            <div className="user-select">
                <div>
                    <label>
                        <input type="radio" name="user-select" defaultChecked={currentUser===null} onChange={()=>setSelectedUser(`none`)}/> {`( None )`} 
                    </label>
                </div>
                {users.map((user:any)=>(
                    <div key={user?.id}>
                        <label>
                            <input type="radio" name="user-select" defaultChecked={user?.id===currentUser?.id} onChange={()=>setSelectedUser(user)}/> {user?.name!==``? `${user?.name} (${user?.id})`:`${user?.id}`} 
                        </label>
                    </div>
                ))}
            </div>
            {
            meta?.last_page>1 &&
            <Pagination 
                count={meta?.last_page} 
                size="small"
                onChange={(e,page)=>setPageNumber(page)}
                page={pageNumber}
                showLastButton 
                showFirstButton 
                disabled={meta?.last_page===1}
            />
            }
        </div>
        :
        <div className="no-users">No Users Found.</div>
    }
    </>
  );
};

export default SingleSelectUserRole;
