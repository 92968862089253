import styles from "../../../assets/scss/Modal.module.scss";
import React, { useEffect, useState } from "react";
import {CircularProgress, Modal} from '@mui/material';
import { ModalProps } from "../../../components/common/Interfaces/Modals";
import RootState from "../../../redux/states/root.state";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { assignRole, fetchRole } from "../../../redux/actions/roles.actions";

const ApplyRolesModal: React.FC<ModalProps> = ({open, setOpen, selected, selection}) => {
  const [submitted, setSubmitted] = useState(false);

  const dispatch: AppDispatch = useDispatch();
  const {loading, error} = useSelector((state: RootState) => state.roleUsers);
  const {data:role, loading: roleLoading} = useSelector((state: RootState) => state.role);
  
  useEffect(() => {
    if (selected) {
      dispatch(fetchRole(selected))
    }
  }, [selected,dispatch]);

  const handleClose = () => {
    setSubmitted(false);
    setOpen(false);
  };

  const handleConfirm = async () => {
    await dispatch(assignRole({role_id:selected,users:selection??[]}));
    setSubmitted(true);
  }

if (loading||roleLoading) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <div className={styles.modal}>
            <div className={styles.body}>
                <CircularProgress size={"24px"} sx={{position:'absolute', top:'50%', right:'50%'}}/>
            </div>
        </div>
      </Modal>
    </div>
  );
} else if ((error ?? !selection) && submitted) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <div className={styles.modal}>
            <div className={styles.header}>
                <p>An Error Occurred</p>
            </div>
            <div className={styles.body}>
                <p>{error??`No users selected.`}</p>
            </div>
            <div className="modal-btn-group">
                <p></p>
                <button className="as-text" onClick={handleClose}> OK </button>
            </div>
            <div className="modal-btn-group">
              <p></p>
              <p></p>
            </div>
        </div>
      </Modal>
    </div>
  );
} else if (submitted) {
  return (
  <div>
    <Modal
      open={open}
      onClose={handleClose}
    >
      <div className={styles.modal}>
          <div className={styles.header}>
              <p>Success</p>
          </div>
          <div className={styles.body}>
              <p>{`Roles applied to selected user(s)`}</p>
          </div>
          <div className="modal-btn-group">
              <p></p>
              <button className="as-text" onClick={handleClose}> OK </button>
          </div>
      </div>
    </Modal>
  </div>
);
} else {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <div className={styles.modal}>
            <div className={styles.header}>
                <p>Give <strong>{role?.name}</strong> role to <strong>{selection?.length}</strong> {`${selection?.length===1 ? 'User':'Users'}`}?</p>
            </div>
            <div className={styles.body}>
                <p> </p>
            </div>
            <div className="modal-btn-group">
                <button className="as-text" onClick={handleClose}> Cancel </button>
                <button className="as-text" onClick={handleConfirm}> Confirm </button>
            </div>
        </div>
      </Modal>
    </div>
  );
}

};

export default ApplyRolesModal;
