import styles from "../../../assets/scss/Modal.module.scss";
import React, { useState } from "react";
import { AppDispatch } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../../redux/states/root.state";
import {
  Modal, 
  CircularProgress,
  Tabs,
  Tab,  
 } from '@mui/material';
import { assignStaff, fetchStaff, removeStaff } from "../../../redux/actions/dealership.actions";
import MultiSelectUserRole from "../../../components/common/UserSelect/MultiSelectUserRole";

interface ModalProps {
    isOpen: boolean, 
    setOpen: any,
    dealership_id: string;
    role: any;
}

const EditDealershipStaffModal: React.FC<ModalProps> = ({isOpen, setOpen, dealership_id, role}) => {
  const dispatch: AppDispatch = useDispatch();
  const {error, loading} = useSelector((state: RootState) => state.dealership);
  const {error:userError, loading:userLoading} = useSelector((state: RootState) => state.users);

  const [selected, setSelected] = useState<any[]>([]);
  const [value, setValue] = React.useState("0");

  const handleClose = () => {
    handleReset();
    setOpen(false);
  }

  const handleSave = async () => {
    if (value==='0') {
      if (window.confirm('This action will unlink the users from their current dealership if any. Continue?')===true) {
        await dispatch(assignStaff({dealership_id:dealership_id,users:selected,type:'staff'})) 
      }
    } else {
      await dispatch(removeStaff({dealership_id:dealership_id,users:selected,type:'staff'}))
    }

    if (!error) {
      dispatch(fetchStaff({id:dealership_id, params:''}));
      handleClose();
    }
  }


  const handleReset = () => {
    setSelected([]);
  }

  const handleChange = (e: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div>
      <Modal
        open={isOpen}
      >
        {loading||userLoading ?
        <CircularProgress size={"24px"} sx={{position:'absolute', top:'50%', right:'50%'}}/>
        :
        error||userError ?
        <div className={styles.modal}>
            <div className={styles.header}>An Error Occurred</div>

            <div className={styles.body}>
              {error??userError}
            </div>

            <div className="modal-btn-group">
                <div></div>
                <button className="as-text" onClick={handleClose}> OK </button>
            </div>
        </div>
        :
        <div className={styles.modal}>
            <div className={styles.header}>
            <Tabs value={value??"0"} aria-label="basic tabs example" onChange={handleChange}>
              <Tab label={`Add ${role==="sales" ? 'Sales Staff' : role==="technician" ? 'Technicians' : 'Staff'}`} value="0"/>
              <Tab label={`Remove ${role==="sales" ? 'Sales Staff' : role==="technician" ? 'Technicians' : 'Staff'}`} value="1"/>
            </Tabs>
            </div>


            {value==='0'?
            <div className="edit-modal-input">
              <MultiSelectUserRole checked={selected} setChecked={setSelected} role={role}/> 
            </div>
            :
            <div className="edit-modal-input">
              <MultiSelectUserRole checked={selected} setChecked={setSelected} dealership_id={dealership_id} role={role}/> 
            </div>
            }

            <div className="modal-btn-group">
                <button className="as-text" onClick={handleClose}> Cancel </button>
                {value==='0'?
                <button className="as-button" onClick={handleSave} disabled={loading||userLoading}>                         
                {loading||userLoading ? (
                  <CircularProgress size={"24px"} style={{ color: "as-white" }} />
                  ) : (
                  "Add"
                  )}
              </button>
              :
              <button className="as-button" onClick={handleSave} disabled={loading||userLoading}>                         
              {loading||userLoading ? (
                <CircularProgress size={"24px"} style={{ color: "as-white" }} />
                ) : (
                "Remove"
                )}
              </button>
            }
            </div>
        </div>
        }
      </Modal>
    </div>
  );
};

export default EditDealershipStaffModal;
