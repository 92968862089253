import "./SpaErrorsPage.css"
import React, {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { AppDispatch } from "../../redux/store";
import RootState from "../../redux/states/root.state";
import Header from "../../components/common/Header/Header";
import {
  CircularProgress, 
  Tooltip
} from "@mui/material";
import { fetchSpa } from "../../redux/actions/spa.actions";
import { ErrorsIcon } from "../../assets/svg/svg";
import NotFound from "../General/NotFound";
import { SpaErrorLabels } from "../../mqttData/errors/spaErrorLabels.enums";
import { SpaStatusLabels } from "../../mqttData/status/spaStatusLabels.enums";
import useMQTT from "../../hooks/useMQTT";
import { formatErrorCode, formatStatusCode } from "../SpaDetails/formatCodes";
import { StonlyLinks } from "./components/stonly.enums";
import { ArcticAbbr } from "./components/arcticAbbr.enums";
import { ArcticLabels } from "./components/arcticLabels.enums";

const SpaErrorsPage: React.FC = () => {
  // Data
  const {id} = useParams();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const {data:spa, isConnected, loading:isLoading, error} = useSelector((state: RootState) => state.spa);
  const errorsData = useSelector((state: RootState) => state.error.data);
  const statusData = useSelector((state: RootState) => state.status.data);
  
  useEffect(() => {
    if (id){
      dispatch(fetchSpa(id));
    }
  }, [dispatch, id]);

  // MQTT Functionality:
  useMQTT(spa.id ?? "");

  if (error){
    return <NotFound/>
  }

  return (
    <>
      <Header/>
      {isLoading?(
        <CircularProgress size={"24px"} sx={{position:'absolute', top:'50%', right:'50%'}}/>
      ):(
        <div className="errors-container">
            <div className="spa-basic-info">
            <div className="info">
              <div className="clickable-icon" onClick={()=>navigate(`/spa/${spa?.id}`)}><strong>{spa?.nick_name??`n/a`}</strong></div>
              {isConnected ? 
                (
                  <span className="status-indicator online"></span>
                ) : (
                  <span className="status-indicator offline"></span>
                )
              }
              </div>

              <div className="info">
                <div><strong>SN: </strong>{spa.arctic_serial_number??"n/a"}</div>
              </div>

          </div>

          <div className="grids">
            <div className="errors-grid">
              <div className="grid-header">Errors</div>
              {Object.values(SpaErrorLabels).map((code,i)=>
                ArcticLabels[code]!=="" &&
                <div key={i} className={errorsData[SpaErrorLabels[code]]?`error-item has-error`:`error-item`}>
                  <Tooltip title={
                    errorsData[SpaErrorLabels[code]] &&
                    <>
                      {StonlyLinks[code]!=="" &&
                      <div>
                        Learn more about <a className="stonly-link" href={StonlyLinks[code]} target="_blank" rel="noreferrer">{formatErrorCode(SpaErrorLabels[code])}</a>.
                      </div>}

                      
                      {StonlyLinks[code]!=="" &&
                        <br></br>
                      }

                      {<div>
                        For more information visit stonly.com: <a className="stonly-link" href="https://arcticspas-yfxhw.stonly.com/kb/en" target="_blank" rel="noreferrer">Stonly</a>.
                      </div>}
                    </>
                  }>
                    <div className="name"><ErrorsIcon hasErrors={errorsData[SpaErrorLabels[code]]}/>{formatErrorCode(SpaErrorLabels[code])}</div>
                  </Tooltip>
                  <div className="label">{ArcticAbbr[code]}</div>
                  <div className="label">{ArcticLabels[code]}</div>
                </div>
              )}
            </div>

            <div className="status-grid">
              <div className="grid-header">Statuses</div>
              {Object.values(SpaStatusLabels).map((code,i)=>
                ArcticLabels[code]!=="" &&
                <div key={i} className={statusData[SpaStatusLabels[code]]?`error-item has-error`:`error-item`}>
                <Tooltip title={
                    statusData[SpaStatusLabels[code]] &&
                    <>
                      {StonlyLinks[code]!=="" &&
                      <>
                        <div>
                          Learn more about <a className="stonly-link" href={StonlyLinks[code]} target="_blank" rel="noreferrer">{formatStatusCode(SpaStatusLabels[code])}</a>.
                        </div>
                      </>
                      }

                      {StonlyLinks[code]!=="" &&
                        <br></br>
                      }

                      {<div>
                        For more information, visit stonly.com: <a className="stonly-link" href="https://arcticspas-yfxhw.stonly.com/kb/en" target="_blank" rel="noreferrer">Stonly</a>.
                      </div>}
                    </>
                  }>
                    <div className="name"><ErrorsIcon hasStatus={statusData[SpaStatusLabels[code]]}/>{formatStatusCode(SpaStatusLabels[code])}</div>
                  </Tooltip>
                  <div className="label">{ArcticAbbr[code]}</div>
                  <div className="label">{ArcticLabels[code]}</div>
                </div>
              )}
            </div>
          </div>
      </div>)}
    </>
  );
};

export default SpaErrorsPage;
