import "./UpgradeRequestsPage.css";
import React, {useEffect} from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Header from "../../components/common/Header/Header";
import { AppDispatch } from "../../redux/store";
import { fetchUpgradeRequests } from "../../redux/actions/upgradeRequests.actions";
import UpdateHistoryContainer from "./components/UpdatesContainer";

const UpdateHistoryPage: React.FC = () => {
  // Data
  const dispatch: AppDispatch = useDispatch();
 const searchParams = useSearchParams()[0];

  useEffect(() => {
    dispatch(fetchUpgradeRequests(searchParams.toString()))
  }, [dispatch, searchParams]);

  return (
    <>
      <Header/>
      <UpdateHistoryContainer/>
    </>
  );
};

export default UpdateHistoryPage;
