import styles from "../../../assets/scss/Modal.module.scss";
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Box, Typography, TextField, Button, Select, MenuItem, FormControl, Input, CircularProgress, FormLabel } from '@mui/material';
import { AppDispatch, RootState } from '../../../redux/store'; 
import { fetchAvailableFirmware, uploadFirmware } from '../../../redux/actions/firmware.actions';
import { ModalProps } from "../../../components/common/Interfaces/Modals";
import { useSearchParams } from "react-router-dom";
import MDEditor from '@uiw/react-md-editor';

const UploadFirmwareModal: React.FC<ModalProps> = ({open, setOpen}) => {
    const searchParams = useSearchParams()[0];
    const handleClose = () => {
      handleReset();
      setOpen(false);
    };

    const handleReset = () => {
      setSubmitted(false);
      setFile(null);
      setType('yocto');
      setVersion('');
      setDescription('');
    };

    const dispatch = useDispatch<AppDispatch>();
    const { accessToken } = useSelector((state: RootState) => state.user);
    const {data, error,loading:uploading} = useSelector((state: RootState) => state.firmwareUpload);
    const {loading} = useSelector((state: RootState) => state.firmware);
    const [submitted, setSubmitted] = useState(false);
  
    const [file, setFile] = useState<File | null>(null);
    const [type, setType] = useState<'yocto' | 'lpc' | 'spaboy'>('yocto');
    const [version, setVersion] = useState('');
    const [description, setDescription] = useState('');
  
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        setFile(event.target.files[0]);
      }
    };
  
    const handleSubmit = async (event: React.FormEvent) => {
      event.preventDefault();
      if (!file || !accessToken) return;
      const formData = new FormData();
      formData.set("file",file);
      formData.set("type",type);
      formData.set("version",version);
      formData.set("description",description);

      await dispatch(uploadFirmware(formData));
      await dispatch(fetchAvailableFirmware(searchParams.toString()));
      setSubmitted(true);
    };

if (!submitted || loading || uploading){
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <div className={styles.modal}>
          <div className={styles.header}>
              Upload Firmware
          </div>

            <div className={styles.body}>
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth margin="normal">
                  <Box>
                    <Input
                      type="file"
                      id="file"
                      inputProps={{ accept: '.bin,.swu,.s19,.pages' }}
                      onChange={handleFileChange}
                      required
                      disabled={loading||uploading}
                    />
                  </Box>
                  <Typography variant="caption" sx={{ mt: 1, color:'var(--font-gray)' }}>
                    Note: Files will be renamed to "{type}_{version}.extension" when uploaded.
                  </Typography>
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <Select
                    labelId="type-label"
                    id="type"
                    value={type}
                    onChange={(e) => setType(e.target.value as 'yocto' | 'lpc' | 'spaboy')}
                    required
                    disabled={loading||uploading}
                  >
                    <MenuItem value="yocto">Yocto</MenuItem>
                    <MenuItem value="lpc">LPC</MenuItem>
                    <MenuItem value="spaboy">Spa Boy</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <TextField
                    id="version"
                    label="Version"
                    value={version}
                    onChange={(e) => setVersion(e.target.value)}
                    required
                    disabled={loading||uploading}
                  />
                </FormControl>
    
                <FormControl fullWidth margin="normal" required sx={{textAlign:'left'}} data-color-mode={localStorage.getItem('data-theme') ?? (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')}>            
                  <FormLabel required> Description</FormLabel>
                  <MDEditor value={description} onChange={(value) => setDescription(value??"")} aria-required={true}/>
                </FormControl>
                
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading||uploading || !accessToken || description==="" || version==="" }
                  sx={{ mt: 2 }}
                >
                  {loading||uploading ? (
                    <CircularProgress size={"24px"} style={{ color: "var(--pure-white)" }} />
                    ) : (
                    "Upload Firmware"
                    )} 
                </Button>
              </form>
            </div>

            <div className="modal-btn-group">
                <button className="as-text" onClick={handleClose}> Close </button>
            </div>
        </div>
      </Modal>
    </div>
  )} else if (error){
    return (
      <div>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <div className={styles.modal}>
          <div className={styles.header}>An Error Occurred</div>
          <div className={styles.body}><p>{error}</p></div>
          <div className="modal-btn-group">
          <button className="as-text" onClick={handleClose}> Close </button>
            <button className="as-text" onClick={handleReset}> Try Again </button>
          </div>
        </div>
      </Modal>
    </div>
    )
  } else {
    return (
      <div>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <div className={styles.modal}>
          <div className={styles.header}>Success!</div>
          <div className={styles.body}>{data?.message ?? "Firmware uploaded successfully"}</div>
          <div className="modal-btn-group">
              <button className="as-text" onClick={handleClose}> Close </button>
              <button className="as-text" onClick={handleReset}> Upload Another </button>
          </div>
        </div>
      </Modal>
    </div>
    )
  }
};

export default UploadFirmwareModal;
