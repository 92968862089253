import React, { useEffect, useMemo, useState } from "react";
import Header from "../../../components/common/Header/Header";
import useMQTT from "../../../hooks/useMQTT";
import Command from "../../../classes/command.class";
import { useParams } from "react-router-dom";
import { fetchSpa } from "../../../redux/actions/spa.actions";
import { AppDispatch } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../../redux/states/root.state";
import { Divider, Tooltip } from "@mui/material";
import "./SpaBoy.css";
import { IndicatorColours } from "../../../components/common/Indicators/IndicatorColours";
import { CircleIndicator, PersonCountIcon } from "../../../assets/svg/svg";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { SpaConstLabels } from "../../../mqttData/consts/spaConstsLabels.enums";
import useDebounce from "../../../hooks/useDebounce";
import { inputDebounce, settingsDebounce } from "../debounceTimes";
import { SpaLiveLabels } from "../../../mqttData/live/spaLiveLabels.enums";
import useRoleCheck from "../../../hooks/useRoleCheck";

const SpaBoy = () => {
  const { id } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const liveData = useSelector((state: RootState) => state.live.data);
  const settingsData = useSelector((state: RootState) => state.settings.data);
  const constData = useSelector((state: RootState) => state.const.data);
  const isConnected = useSelector((state: RootState) => state.spa.isConnected);

  const canAccessAdminPanel = useRoleCheck(['admin','developer','corporate','owner','dealer'])

  const [orpLevelBase, setOrpLevelBase] = useState(0);
  const [spaboyHoursPerDay, setSpaboyHoursPerDay] = useState(0);

  const [settingsORP, setSettingsORP] = useState(0);
  const [settingsSBHrs, setSettingsSBHrs] = useState(0);

  /* DEBOUNCES */
  const debouncedORP = useDebounce(orpLevelBase, inputDebounce);
  const debouncedSBHrs = useDebounce(spaboyHoursPerDay, inputDebounce);

  const debouncedSettingsORP = useDebounce(settingsORP, settingsDebounce);
  const debouncedSettingsSBHrs = useDebounce(settingsSBHrs, settingsDebounce);

  let orpLevelMapper: { [p: number]: [number, number] };

  orpLevelMapper = {
    0: [545, 555],
    50: [645, 655],
    100: [745, 755],
  };

  
  const electrodeWearRange = (wear:number) => {
    switch(true){
      case (wear<=20):
        return 'E1';

      case (wear<=40):
        return 'E2';

      case (wear<=60):
        return 'E3';

      case (wear<=80):
        return 'E4';

      case (wear<=100):
        return 'E5';
    }
  }


  // Fetch SPA From the DB
  useEffect(() => {
    if (id != null) {
      dispatch(fetchSpa(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (!settingsData) return;

    if (settingsData.SBORPlo) setSettingsORP((settingsData.SBORPlo - 545) / 2);

    if (settingsData.SBHr) {
      setSettingsSBHrs(settingsData.SBHr);
    }
  }, [settingsData.SBORPlo, settingsData.SBHr]);

  // MQTT Functionality:
  const [commandTopic] = useMQTT(id ?? "");
  const command = new Command(commandTopic);

  /* ORP LEVEL CHANGE */
  const handleClLevelChange = (value:number) => {
    setOrpLevelBase(value);
    setSettingsORP((value));
  };

  useMemo(() => {
    if (debouncedSettingsORP!==debouncedORP) {
      command.updateSettings({
        SBORPlo: orpLevelMapper[debouncedORP as number][0],
        SBORPhi: orpLevelMapper[debouncedORP as number][1],
      });
    }
  }, [debouncedORP]);

  useMemo(() => {
      if (debouncedSettingsORP!==debouncedORP) {
        setOrpLevelBase(debouncedSettingsORP);
      }
  }, [debouncedSettingsORP]);


  /* SPABOY CONTROL */
  const handleSpaboyHoursChange = (value: number) => {
    setSpaboyHoursPerDay(value as number);
    setSettingsSBHrs(value as number);
  };

  useMemo(() => {
    if (debouncedSettingsSBHrs!==debouncedSBHrs) {
      command.updateSettings({
        setSBHrs: debouncedSBHrs as number,
      });
    }
  }, [debouncedSBHrs]);

  useMemo(() => {
      if (debouncedSettingsSBHrs!==debouncedSBHrs) {
        setSpaboyHoursPerDay(debouncedSettingsSBHrs);
      }
  }, [debouncedSettingsSBHrs]);
  
  return (
    <div className="page">
      <Header logo="spaboy"/>
      <div className="controls-page-container spaboy">
        <div className="levels">
          <div className="levels-container">
            <div></div>
            <div>Low</div>
            <div></div>
            <div>OK</div>
            <div></div>
            <div>High</div>
            <Tooltip enterTouchDelay={0} title={<span className="tooltip-title">{`${liveData[SpaLiveLabels.sbORP]??0} mV`}</span>} placement="top" arrow><div>CL</div></Tooltip>
            <Tooltip enterTouchDelay={0} title={<span className="tooltip-title">{`< 0.1 ppm (FCL)`}</span>} placement="top" arrow>
              <div>
                <CircleIndicator color={(settingsData.sbORPind??0)===0?Object.values(IndicatorColours)[settingsData.sbORPind??0]:'none'}/>
              </div>
            </Tooltip>

            <Tooltip enterTouchDelay={0} title={<span className="tooltip-title">{`0.1 - 0.5 ppm (FCL)`}</span>} placement="top" arrow>
              <div>
                <CircleIndicator color={settingsData.sbORPind===1?Object.values(IndicatorColours)[settingsData.sbORPind]:'none'}/>
              </div>
            </Tooltip>

            <Tooltip enterTouchDelay={0} title={<span className="tooltip-title">{`0.6 - 1.5 ppm (FCL)`}</span>} placement="top" arrow>
              <div>
                <CircleIndicator color={settingsData.sbORPind===2?Object.values(IndicatorColours)[settingsData.sbORPind]:'none'}/>
              </div>
            </Tooltip>

            <Tooltip enterTouchDelay={0} title={<span className="tooltip-title">{`1.6 - 3.0 ppm (FCL)`}</span>} placement="top" arrow>
              <div>
                <CircleIndicator color={settingsData.sbORPind===3?Object.values(IndicatorColours)[settingsData.sbORPind]:'none'}/>
              </div>
            </Tooltip>

            <Tooltip enterTouchDelay={0} title={<span className="tooltip-title">{`> 3.0 ppm (FCL)`}</span>} placement="top" arrow>
              <div>
                <CircleIndicator color={settingsData.sbORPind>=4?Object.values(IndicatorColours)[4]:'none'}/>
              </div>
            </Tooltip>

            <Tooltip enterTouchDelay={0} title={<span className="tooltip-title">{`${(liveData[SpaLiveLabels.sbpH]??0)/100}`}</span>} placement="top" arrow><div>pH</div></Tooltip>
              <Tooltip enterTouchDelay={0} title={<span className="tooltip-title">{`< 6.8`}</span>} placement="top" arrow>
                <div>
                  <CircleIndicator color={(settingsData.sbpHind??0)===0?Object.values(IndicatorColours)[settingsData.sbpHind??0]:'none'}/>
                </div>
              </Tooltip>

              <Tooltip enterTouchDelay={0} title={<span className="tooltip-title">{`6.8 - 7.2`}</span>} placement="top" arrow>
                <div>
                  <CircleIndicator color={settingsData.sbpHind===1?Object.values(IndicatorColours)[settingsData.sbpHind]:'none'}/>
                </div>
              </Tooltip>

              <Tooltip enterTouchDelay={0} title={<span className="tooltip-title">{`7.3 - 7.8`}</span>} placement="top" arrow>
                <div>
                  <CircleIndicator color={settingsData.sbpHind===2?Object.values(IndicatorColours)[settingsData.sbpHind]:'none'}/>
                </div>
              </Tooltip>

              <Tooltip enterTouchDelay={0} title={<span className="tooltip-title">{`7.9 - 8.2`}</span>} placement="top" arrow>
                <div>
                  <CircleIndicator color={settingsData.sbpHind===3?Object.values(IndicatorColours)[settingsData.sbpHind]:'none'}/>
                </div>
              </Tooltip>

              <Tooltip enterTouchDelay={0} title={<span className="tooltip-title">{`> 8.2`}</span>} placement="top" arrow>
                <div>
                  <CircleIndicator color={settingsData.sbpHind>=4?Object.values(IndicatorColours)[4]:'none'}/>
                </div>
              </Tooltip>
          </div>
        </div>
        <div className="icon-indicators">
          <Tooltip enterTouchDelay={0} title={<span className="tooltip-title">Electrode Wear: {liveData[SpaLiveLabels.sbWear]??0}%</span>} placement="top" arrow>
              <div 
                  className={`action-button electrode`}
                  id={electrodeWearRange(liveData[SpaLiveLabels.sbWear]??0)}
              ></div>
            </Tooltip>
        </div>
        {canAccessAdminPanel && <div className="controls">
          <button disabled={!isConnected} className={`spaboy-controls control-button toggle ${spaboyHoursPerDay===0 && `active`}`} onClick={()=>handleSpaboyHoursChange(0)}>AUTO</button>
          <button disabled={!isConnected} className={`spaboy-controls control-button toggle ${spaboyHoursPerDay>0 && `active`}`} onClick={()=>handleSpaboyHoursChange(1)}>MANUAL</button>
        </div>}
        {canAccessAdminPanel && <Divider sx={{background:'var(--disabled-gray)'}} variant="middle"/>}
        
        <div className="settings">
          {spaboyHoursPerDay === 0 || !canAccessAdminPanel? 
            <span className="spaboy-manual">

              <button 
                disabled={!isConnected} 
                className={`spaboy control-button toggle ${orpLevelBase===0 && `active`}`} 
                onClick={()=>handleClLevelChange(0)}>
                  <span className="count-icon"><PersonCountIcon count={2}/></span>
                  <span>LOW</span>
              </button>

              <button 
                disabled={!isConnected} 
                className={`spaboy control-button toggle ${orpLevelBase===50 && `active`}`} 
                onClick={()=>handleClLevelChange(50)}>
                  <span className="count-icon"><PersonCountIcon count={4}/></span>
                  <span>MED</span>
              </button>

              <button 
                disabled={!isConnected} 
                className={`spaboy control-button toggle ${orpLevelBase===100 && `active`}`} 
                onClick={()=>handleClLevelChange(100)}>
                  <span className="count-icon"><PersonCountIcon count={4} plus={true}/></span>
                  <span>HIGH</span>
              </button>

            </span>
            :
            <div className="spaboy-hours">
              <button className={`spaboy control-button arrow`} onClick={()=>handleSpaboyHoursChange(spaboyHoursPerDay-1)} disabled={spaboyHoursPerDay===1||!isConnected}>
                <KeyboardArrowDownIcon fontSize='large' sx={{transform:'scale(2)'}}/>
              </button>
              <div className="value spaboy">
                  {spaboyHoursPerDay}
                  <span className="units">{spaboyHoursPerDay===1?`hour/day`:`hours/day`}</span>
              </div>
              <button className={`spaboy control-button arrow`} onClick={()=>handleSpaboyHoursChange(spaboyHoursPerDay+1)} disabled={spaboyHoursPerDay===(constData[SpaConstLabels.setSBHrsmax]??8)||!isConnected}>
                <KeyboardArrowUpIcon fontSize='large' sx={{transform:'scale(2)'}}/>
              </button>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default SpaBoy;
