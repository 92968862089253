import { createAsyncThunk } from '@reduxjs/toolkit';
import RootState from "../states/root.state";
import { addCommandApi, addSpaCommandsApi, deleteCommandApi, editCommandApi, getCommands, getSpaCommands, getSpaCommandsHistory } from '../../services/api/commands.api';
import { getSpaById } from '../../services/api/spas.api';

export const fetchCommands = createAsyncThunk<any, string, { rejectValue: string; state: RootState}>(
  'commands/list',
  async (params, {rejectWithValue, getState}) => {
    try {
        const {user} = getState();
        const token = user.accessToken;
    
        const response = await getCommands(token,params);
        return response.data;
        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred during removal.');
            }
        }
  }
);

export const fetchSpaCommands = createAsyncThunk<any, {spa_id:string, params:string}, { rejectValue: string; state: RootState}>(
    'spas/commands/list',
    async (request, {rejectWithValue, getState}) => {
      try {
        const {user} = getState();
        const token = user.accessToken;
        const response = await getSpaCommands(token,request);
        return response.data;
        
        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred during removal.');
            }
        }
    }
  );

  export const fetchSpaCommandsHistory = createAsyncThunk<any, string, { rejectValue: string; state: RootState}>(
    'spas/commands/history',
    async (params, {rejectWithValue, getState}) => {
      try {
        const {user} = getState();
        const token = user.accessToken;
        const response = await getSpaCommandsHistory(token,params);
        return response.data;
        
        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred during removal.');
            }
        }
    }
  );


export const addCommand = createAsyncThunk<any, {title: string, body: any, params:string}, { rejectValue: string; state: RootState}>(
  'commands/add',
  async (commandInfo, {rejectWithValue, getState}) => {
      try {
          const {user} = getState();
          const token = user.accessToken;
          const response = await addCommandApi(token,{title:commandInfo.title, body:commandInfo.body});
          
          return response.data;

      } catch (error: any) {
          if (error.response) {
              return rejectWithValue(error.response.data.message);
          } else {
              return rejectWithValue('An error occurred during posting.');
          }
      }
  }
);

export const editCommand = createAsyncThunk<any, {id:string, title: string, body: any, params:string}, { rejectValue: string; state: RootState}>(
  'commands/update',
  async (updateInfo, {rejectWithValue, getState}) => {
      try {
          const {user} = getState();
          const token = user.accessToken;
          const response = await editCommandApi(token,{id: updateInfo.id, title:updateInfo.title, body:updateInfo.body});
          return response.data;

      } catch (error: any) {
          if (error.response) {
              return rejectWithValue(error.response.data.message);
          } else {
              return rejectWithValue('An error occurred during editing.');
          }
      }
  }
);

export const deleteCommand = createAsyncThunk<any, {command_id:string, params:string}, { rejectValue: string; state: RootState}>(
  'commands/delete',
  async (updateInfo, {rejectWithValue, getState}) => {
      try {
          const {user} = getState();
          const token = user.accessToken;
          const response = await deleteCommandApi(token,updateInfo.command_id);
          return response.data;

      } catch (error: any) {
          if (error.response) {
              return rejectWithValue(error.response.data.message);
          } else {
              return rejectWithValue('An error occurred during deletion.');
          }
      }
  }
);

export const addSpaCommands = createAsyncThunk<any, {command_id:string, spa_id:string, log_level:string}, { rejectValue: string; state: RootState}>(
  'commands/assign-to-spa',
  async (params, {rejectWithValue, getState}) => {
      try {
          const {user} = getState();
          const token = user.accessToken;
          const response = await addSpaCommandsApi(token,params).then(()=> {return getSpaById(token, params.spa_id)});
          return response.data;

      } catch (error: any) {
          if (error.response) {
              return rejectWithValue(error.response.data.message);
          } else {
              return rejectWithValue('An error occurred while adding commands to spa.');
          }
      }
  }
);