import React from 'react';
import arcticLogo from '../../assets/logos/logo.png';
import sbLogo from '../../assets/logos/SpaBoyLogo.png';

interface LogoProps {
  height?: string;
  logo?: string;
}

const Logo: React.FC<LogoProps> = ({ height = '7rem', logo='arctic' }) => {
  return (
    <img
      src={logo==='arctic'?arcticLogo:sbLogo}
      alt="Logo"
      style={{ height }}
    />
  );
};

export default Logo;