import "./Header.css"
import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../../redux/store";
import { logout } from "../../../redux/actions/user.actions";
import { CommandsIcon, CommentsIcon, DarkModeIcon, DealershipIcon, FirmwareIcon, HomeIcon, LightModeIcon, LogoutIcon, LogsIcon, PermissionsIcon, RolesIcon, SettingIcon, TubIcon, UserGroupIcon, UsersIcon } from "../../../assets/svg/svg";
import Logo from "../../Layout/Logo";
import {IconButton, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from '@mui/material';
import RootState from "../../../redux/states/root.state";
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useRoleCheck from "../../../hooks/useRoleCheck";

const Header = ({ withBackButton = true, withNavBtn = true, withLogo = true, withUserAvatar=true,withBetaLink = false, header="", logo='arctic'}) => {
  const dispatch: AppDispatch = useDispatch();
  const {data:user} = useSelector((state: RootState) => state.user);
  const canAccessAdminPanel = useRoleCheck(["dealer", "admin", "owner", "corporate", "developer"]);
  const canAccessDeveloperPanel = useRoleCheck(["admin","developer"]);
  const canAccessDealershipPanel = useRoleCheck(["admin","developer","corporate"]);
  const [theme, setTheme] = useState<any>('light');
  const [toggled, setToggled] = useState(false);
  const navigate = useNavigate();
  var mode = localStorage.getItem('data-theme');
  
  
  useEffect(() => {
    if (mode) {
      setTheme(mode);
    } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      setTheme('dark');
    }
  }, [mode]);

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleLogin = () => {
    navigate('/login');
  };

  const handleToggle = (isToggled:boolean) => {
    setToggled(isToggled);
  };

  const handleChange = () => {
    if (theme==='dark'){
      setTheme('light');
      document.body.setAttribute('data-theme', 'light');
      localStorage.setItem('data-theme', 'light');
    } else {
      setTheme('dark');
      document.body.setAttribute('data-theme', 'dark');
      localStorage.setItem('data-theme', 'dark');
    }
    window.dispatchEvent( new Event('storage') );
  }

  return (
    <>
      <div className="header">
        <div className="right-group">

          <div className="back-button">
            {withBackButton && (
            <IconButton aria-label="menu" sx={{color:`var(--as-gray)`}} size="large" onClick={()=>navigate(-1)}>
              <ArrowBackIcon/>
            </IconButton>
            )}
          </div>

          {withNavBtn && 
          <div className="back-button">
            <IconButton aria-label="menu" sx={{color:`var(--as-gray)`}} size="large" onClick={()=>handleToggle(true)}>
              <MenuIcon/>
            </IconButton>
          </div>}

        </div>

        <Drawer className="drawer" open={toggled} onClose={()=>handleToggle(false)} PaperProps={{sx: {backgroundColor: "var(--pop-over)"}}}>
          <List>

          <ListItem>
                <ListItemIcon>
                  {<div className="user-avatar">
                    <button>{user?.name[0] ?? `U`}</button>
                    </div>}
                </ListItemIcon>
                <ListItemText primary={`Welcome, ${user?.username}.`} />
            </ListItem>

           
            <ListItem>
              <ListItemButton onClick={()=>navigate('/')}>
                <ListItemIcon>
                  {<HomeIcon/>}
                </ListItemIcon>
                <ListItemText primary={'Home'} />
              </ListItemButton>
            </ListItem>

            {canAccessAdminPanel &&
              <>
                <ListItem>
                  <ListItemButton onClick={()=>navigate('/spas')}>
                    <ListItemIcon>
                    {<TubIcon/>}
                    </ListItemIcon>
                    <ListItemText primary={'Spas'} />
                  </ListItemButton>
                </ListItem>
                

                <ListItem>
                  <ListItemButton onClick={()=>navigate('/users')}>
                    <ListItemIcon>
                    {<UsersIcon/>}
                    </ListItemIcon>
                    <ListItemText primary={'Users'} />
                  </ListItemButton>
                </ListItem>
              </>
              }

                {canAccessDealershipPanel && 
                <>
                <ListItem>
                  <ListItemButton onClick={()=>navigate('/dealerships')}>
                    <ListItemIcon>
                    {<DealershipIcon/>}
                    </ListItemIcon>
                    <ListItemText primary={'Dealerships'} />
                  </ListItemButton>
                </ListItem>

                <ListItem>
                  <ListItemButton onClick={()=>navigate('/comments')}>
                    <ListItemIcon>
                    {<CommentsIcon/>}
                    </ListItemIcon>
                    <ListItemText primary={'Comments'} />
                  </ListItemButton>
                </ListItem>
              </>}

              {canAccessDeveloperPanel && 
                <ListItem>
                  <ListItemButton onClick={()=>navigate('/logs')}>
                    <ListItemIcon>
                    {<LogsIcon/>}
                    </ListItemIcon>
                    <ListItemText primary={'Log History'} />
                  </ListItemButton>
                </ListItem>}


            {canAccessDeveloperPanel &&
            <>
              <ListItem>
                <ListItemButton onClick={()=>navigate('/firmware')}>
                  <ListItemIcon>
                  {<FirmwareIcon/>}
                  </ListItemIcon>
                  <ListItemText primary={'Manage Firmware'} />
                </ListItemButton>
              </ListItem>

              <ListItem>
                <ListItemButton onClick={()=>navigate('/spa-commands')}>
                  <ListItemIcon>
                  {<CommandsIcon/>}
                  </ListItemIcon>
                  <ListItemText primary={'Spa Commands'} />
                </ListItemButton>
              </ListItem>

              <ListItem>
                <ListItemButton onClick={()=>navigate('/roles')}>
                  <ListItemIcon>
                  {<RolesIcon/>}
                  </ListItemIcon>
                  <ListItemText primary={'Roles'} />
                </ListItemButton>
              </ListItem>
              
              <ListItem>
                <ListItemButton onClick={()=>navigate('/user-groups')}>
                  <ListItemIcon>
                  {<UserGroupIcon/>}
                  </ListItemIcon>
                  <ListItemText primary={'User Groups'} />
                </ListItemButton>
              </ListItem>
              
              <ListItem>
                <ListItemButton onClick={()=>navigate('/permissions')}>
                  <ListItemIcon>
                  {<PermissionsIcon/>}
                  </ListItemIcon>
                  <ListItemText primary={'Permissions'} />
                </ListItemButton>
              </ListItem>
            </>
            }

            <ListItem>
              <ListItemButton onClick={()=>navigate('/settings')}>
                <ListItemIcon>
                  {<SettingIcon/>}
                </ListItemIcon>
                <ListItemText primary={'Settings'} />
              </ListItemButton>
            </ListItem>

            <ListItem>
              <ListItemButton onClick={()=>handleChange()}>
                <ListItemIcon>
                {theme==='dark'? <LightModeIcon/>:<DarkModeIcon/>}
                </ListItemIcon>
                <ListItemText primary={theme==='dark'?'Light Mode':'Dark Mode'} />
              </ListItemButton>
            </ListItem>

            <ListItem sx={{background:'var(--as-red)', color:'var(--pure-white)'}}>
              <ListItemButton onClick={()=>handleLogout()}>
                <ListItemIcon>
                  {<LogoutIcon/>}
                </ListItemIcon>
                <ListItemText primary={'Logout'} />
              </ListItemButton>
            </ListItem>


          </List>
        </Drawer>

        {withLogo &&
        <div className="logo">
          <Logo height={logo==='arctic'?`4rem`:`4vmax`} logo={logo}/>
        </div>
        }

        {header!=="" && 
        <div className="logo">
          <p>{header}</p>
        </div>
        }

      {(withNavBtn) && 
        <div className="right-buttons">
        {withBetaLink &&
          <a className="logout" href="https://beta.myarcticspa.com/Spa/MySpa.aspx?ignore_redirect=true">
            Beta Site
          </a>
        }
        {user ?
            <div onClick={()=>handleLogout()} className="logout">
              Logout
            </div>
            :
            <div onClick={()=>handleLogin()} className="logout">
              Login
            </div>
          }
        </div>
        }
      </div>
    </>
  );
};

export default Header;
