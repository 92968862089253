import React from "react";
import PermissionsTable from "./PermissionsTable";

const PermissionsContainer: React.FC = () => {
  return (
    <div className="container">
        <div className="list">
            <PermissionsTable/>
        </div>
    </div>
)
};

export default PermissionsContainer;