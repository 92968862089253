import React, {useEffect, useState} from "react";
import { AppDispatch } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fetchSpaCommands } from "../../../redux/actions/commands.actions";
import RootState from "../../../redux/states/root.state";
import {
  CircularProgress, 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableRow,
  TableSortLabel,
  Box,
  Pagination
} from "@mui/material";
import { visuallyHidden } from '@mui/utils';

type Order = 'asc' | 'desc';
const headers = [
  {id:'id', label:'ID', align:"center"}, 
  {id:'is_sent', label:'Is Sent', align:"center"}, 
  {id:'title', label:'Title',align:null}, 
  {id:'body', label:'Body',align:null}, 
  {id:'updated_at', label:'Last Updated',align:null}, 
  {id:'created_at', label:'Created',align:null},
];

interface ReportsTableProps {
    spa_guid?: string;
    startDate?: string;
    endDate?: string;
}

const SpaCommandsTable: React.FC<ReportsTableProps> = ({spa_guid=null, startDate=null, endDate=null}) => {
    const dispatch: AppDispatch = useDispatch();
    const {data:spaCommands, loading, meta} = useSelector((state: RootState) => state.commands);

    // Table Sort
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [sortedRows, setSortedRows] = useState<any[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
      if (spa_guid && startDate && endDate) {
        dispatch(fetchSpaCommands({spa_id:spa_guid, params:`from=${startDate}&until=${endDate}`}))
      }        
    },[dispatch,spa_guid,startDate,endDate]);

    useEffect(() => {
      setSortedRows(spaCommands);
    }, [spaCommands]);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        header_id: string,
      ) => {
        const isAsc = orderBy === header_id && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(header_id);
    
        handleSort();
      };
    
      const handleSort = () => {
        let newArray = [...spaCommands].sort((a:any, b:any)=>{
          return a[orderBy] === b[orderBy] ? 0 : order==='asc'? a[orderBy] ? -1:1 : b[orderBy] ? 1:-1;
        })
        setSortedRows(newArray);
        
      }
    
      const handleChange = (event:any, value:any) => {
        setSearchParams({page: value})  
      }

    return (
      <>
        <div className="table">
          {loading ? ( 
            <CircularProgress size={"24px"} sx={{position:'absolute', top:'50%', right:'50%'}}/>
          ): 
          spaCommands.length>0 ? (
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {headers.map((header)=>(
                  <TableCell key={header.id} align={header.align?"center":"left"}>
                    {header.id!=='edit' && header.id!=='delete' ? 
                    <TableSortLabel
                      active={orderBy === header.id}
                      direction={orderBy === header.id ? order : 'asc'}
                      onClick={(e)=>handleRequestSort(e,header.id)}
                    >
                      {header.label}
                      {orderBy === header.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                    :
                    (header.label)
                  }

                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
            {sortedRows.map((spaCommand,i)=>(
              <TableRow key={i}>
                <TableCell  align='center'>
                  {spaCommand?.id}
                </TableCell>

                <TableCell align='center'>
                  {spaCommand?.is_sent?<span className="check">✓</span> :<span className="ex">X</span>}
                </TableCell>

                <TableCell>
                  {spaCommand?.command?.title}
                </TableCell>

                <TableCell>
                  {JSON.stringify(spaCommand?.command?.body)}
                </TableCell>

                <TableCell>
                  {new Date(spaCommand?.updated_at).toUTCString()}
                </TableCell>

                <TableCell>
                  {new Date(spaCommand?.created_at).toUTCString()}
                </TableCell>             
              </TableRow>
              ))}
            </TableBody>

          </Table>
          ):(
          <div className="empty-table">No Commands Found.</div>
          )
        }
        </div>

        <div className="table-footer">
          <div className="pagination">
            <Pagination 
              count={meta?.last_page} 
              variant="outlined" 
              onChange={handleChange}
              page={searchParams.get("page")?Number(searchParams.get("page")):1}
              showLastButton 
              showFirstButton 
              disabled={meta?.total_pages===1}
              />
            <div className="total-count">Showing {spaCommands?.length}/{meta?.total??0} Spa Commands</div>
          </div>
        </div>  
      </>
    
  );
};

export default SpaCommandsTable;
