import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import UsersState from '../states/spas.state';
import { fetchUsers } from '../actions/users.actions';
import { assignStaff, fetchStaff, removeStaff } from '../actions/dealership.actions';
import { assignRole, removeRole } from '../actions/roles.actions';
import { updateUser } from '../actions/user.actions';

const initialState: UsersState = {
  loading: false,
  data: [],
  error: null,
  meta: null,
};

const UsersSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      })

      .addCase(updateUser.pending, (state) => {
        state.error = null;
      })
      .addCase(updateUser.fulfilled, (state, action: PayloadAction<any>) => {
        state.data = [...state.data].map((user:any)=>user?.id===action.payload?.id ? action.payload : user);
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.error = action.payload ?? null;
      })

      .addCase(fetchStaff.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchStaff.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchStaff.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      })

      .addCase(assignStaff.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(assignStaff.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = [...Array.from(new Set([...state.data,...action.payload.staff]))];
      })
      .addCase(assignStaff.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      })

      .addCase(removeStaff.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removeStaff.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = [...state.data].filter((staff:any)=> ![...action.payload.staff].find(({id})=>staff?.id===id));
      })
      .addCase(removeStaff.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      })

      .addCase(assignRole.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(assignRole.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = [...state.data].map((user:any)=>{return [...action.payload.roleUsers].find(({id})=>user?.id===id)??user});
      })
      .addCase(assignRole.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      })

      .addCase(removeRole.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removeRole.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = [...state.data].filter((user:any)=> ![...action.payload.roleUsers].find(({id})=>user?.id===id));
      })
      .addCase(removeRole.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      });
  },
});

export default UsersSlice.reducer;