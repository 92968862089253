import "./SpaCommandsPage.css"
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { AppDispatch } from "../../redux/store";
import Header from "../../components/common/Header/Header";
import SpaCommandsContainer from "./components/SpaCommandsContainer";
import { fetchSpaCommandsHistory } from "../../redux/actions/commands.actions";

const SpaCommandsPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const searchParams = useSearchParams()[0];

  useEffect(() => {
    dispatch(fetchSpaCommandsHistory(searchParams.toString()));
  }, [dispatch,searchParams]);

  return (
    <>
    <Header/>
    <SpaCommandsContainer/>
    </>
  );
};

export default SpaCommandsPage;
