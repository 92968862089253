import styles from "../../../assets/scss/Modal.module.scss";
import React, {useEffect} from "react";
import {Modal} from '@mui/material';
import { AppDispatch } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
    CircularProgress,
    Box,
  } from "@mui/material";
import RootState from "../../../redux/states/root.state";
import { upgradeFirmware } from "../../../redux/actions/firmware.actions";
import useMQTT from "../../../hooks/useMQTT";
import { UpdateStatusLabels } from "../../../mqttData/updateStatus/updateStatusLabels.enums";

interface ModalProps {
    isOpen: boolean, 
    setOpen: any,
    id: string,
    firmwareType: string,
    version: string
}

const SingleUpdateModal: React.FC<ModalProps> = ({isOpen, setOpen,id,firmwareType,version}) => {
    const dispatch: AppDispatch = useDispatch();
    const {data,loading, error} = useSelector((state: RootState) => state.firmwareUpgrade);

    const {data:updateData} = useSelector((state: RootState) => state.updateStatus);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
      if (isOpen) {
        const sendUpdateData = async (id:string) => {
          await dispatch(
            upgradeFirmware({spa_id:id,platform:firmwareType,version:version})
          );
        }
        sendUpdateData(id);
      }
    }, [dispatch, id, isOpen, firmwareType, version]);
    

  useMQTT(id ?? "");


  const getUpdateInfo = () => {
    if (updateData.spa_id === undefined || updateData.spa_id !== id) {
      return "No Data Available";
    }
    return `${updateData.type} version: ${updateData.version} | Progress: ${updateData.progress}%`;
  }

  const shouldShowProgress = () => {
    return updateData.spa_id !== undefined && updateData.spa_id === id;
  }

if (loading) {
    return (
      <div>
        <Modal
          open={isOpen}
        >
          <div className={styles.modal}>
            <div className={styles.header}><p>{`Requesting Update...`}</p></div>
            <div className={styles.body}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
              </Box>
            </div>
          </div>
        </Modal>
      </div>
    );
  } else if (error) {
    return (
      <div>
        <Modal
          open={isOpen}
        >
          <div className={styles.modal}>
            <div className={styles.header}><p>An Error Occurred</p></div>
            <div className={styles.body}>
              <p>{error}</p>
              <p>{data?.message}</p>
            </div>
            <div className="modal-btn-group">
              <p></p>
              <button className="as-text" onClick={handleClose}> OK </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  } else if (shouldShowProgress() && updateData.progress<100) {
    return (
      <div>
        <Modal
          open={isOpen}
        >
          <div className={styles.modal}>
            <div className={styles.header}>
              <div style={{textTransform: "capitalize", whiteSpace: "nowrap"}}>
                  {getUpdateInfo()}
              </div>
            </div>
            <div className={styles.updateBody}>
              <div className="progress-container">
                  <div
                      className="progress-bar"
                      style={{width: `${updateData.progress ?? 0}%`}}
                  ></div>
                  <span className="progress-text">{updateData.progress}%</span>
              </div>
            </div>
            <div className="modal-btn-group">
              <p></p>
              <button className="as-text" onClick={handleClose}> OK </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  } else if (shouldShowProgress() && updateData.progress===100) {
    return (
      <div>
        <Modal
          open={isOpen}
        >
          <div className={styles.modal}>
            <div className={styles.header}><p>{updateData[UpdateStatusLabels.success]?`Updated Successfully`:`Update Failed`}</p></div>
            <div className="modal-btn-group">
              <p></p>
              <button className="as-text" onClick={handleClose}> OK </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  } else {
    return (
      <div>
        <Modal
          open={isOpen}
        >
          <div className={styles.modal}>
            <div className={styles.header}><p>{data?.message}</p></div>
            <div className="modal-btn-group">
              <p></p>
              <button className="as-text" onClick={handleClose}> OK </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default SingleUpdateModal;
