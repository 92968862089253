import GaugeChart from "react-gauge-chart";
interface AmpereMeterProps {
  value: number;
  size: number;
}

export const AmpereMeter: React.FC<AmpereMeterProps> = ({ value, size}) => {
  return (
    <GaugeChart
      id="gauge-chart"
      animateDuration={5000}
      percent={(value>60?1:value/60)} // 60 amps is the max
      nrOfLevels={3}
      style={{width:`${size}rem`}}
      textColor={"var(--font-black)"}
      cornerRadius={3}
      colors={["#3EE814", "#F7D317", "#F71717"]}
      needleColor={"var(--as-gray)"}
      needleBaseColor={"var(--as-gray)"}
      formatTextValue={(percent) => value + " Amps"}
      animate={false}
    />
  );
};
