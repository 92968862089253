import styles from "../../../assets/scss/Modal.module.scss";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, TextField, Button, FormControl, CircularProgress } from '@mui/material';
import { AppDispatch, RootState } from '../../../redux/store'; 
import { editCommand } from "../../../redux/actions/commands.actions";
import { useSearchParams } from "react-router-dom";
import { ModalProps } from "../../../components/common/Interfaces/Modals";

const UpdateCommandModal: React.FC<ModalProps> = ({open, setOpen,object}) => {
    const searchParams = useSearchParams()[0];
    const command = object;

    const handleClose = () => {
      handleReset();
      setOpen(false);
    };

    const handleReset = () => {
      setSubmitted(false);
      setTitle('');
      setBody('');
    };

    const dispatch = useDispatch<AppDispatch>();
    const { accessToken } = useSelector((state: RootState) => state.user);
    const { error, loading } = useSelector((state: RootState) => state.commands);
    
    const [submitted, setSubmitted] = useState(false);
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');

    useEffect(() => {
      if (command){
        setTitle(command.title);
        setBody(JSON.stringify(command.body));
      }
    }, [command]);
  
    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      
      setSubmitted(true);
      
      await dispatch(editCommand({id:command?.id,title:title, body:body, params:searchParams.toString()}));
    };


if (!submitted || loading ){
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <div className={styles.modal}>
          <div className={styles.header}>
              Update <strong>{command?.title}</strong>
          </div>

            <div className={styles.body}>
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    id="title"
                    label="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                    disabled={loading}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <TextField
                    id="body"
                    label="Body"
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                    multiline
                    required
                    disabled={loading}
                  />
                </FormControl>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading || !accessToken}
                  sx={{ mt: 2 }}
                >
                  {loading ? (
                    <CircularProgress size={"24px"} style={{ color: "var(--pure-white)" }} />
                    ) : (
                    "Update Command"
                    )} 
                </Button>
              </form>
            </div>

            <div className="modal-btn-group">
                <button className="as-text" onClick={handleClose}> Close </button>
            </div>
        </div>
      </Modal>
    </div>
  )} else if (error){
    return (
      <div>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <div className={styles.modal}>
          <div className={styles.header}>An Error Occurred</div>
          <div className={styles.body}><p>{error}</p></div>
          <div className="modal-btn-group">
          <button className="as-text" onClick={handleClose}> Close </button>
            <button className="as-text" onClick={handleReset}> Try Again </button>
          </div>
        </div>
      </Modal>
    </div>
    )
  } else {
    return (
      <div>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <div className={styles.modal}>
          <div className={styles.header}>Success!</div>
          <div className={styles.body}>{`Command successfully updated.`}</div>
          <div className="modal-btn-group">
              <p></p>
              <button className="as-text" onClick={handleClose}> OK </button>
          </div>
        </div>
      </Modal>
    </div>
    )
  }
};

export default UpdateCommandModal;
