import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import AccessDenied from '../../pages/General/AccessDenied';
import RootState from '../../redux/states/root.state';
import useRoleCheck from '../../hooks/useRoleCheck';
import Spinner from '../common/Spinner';

interface ProtectedRouteProps {
    children: React.ReactNode;
    roles: string[];
}

const RoleRoute: React.FC<ProtectedRouteProps> = ({children,roles}) => {
    const canAccess = useRoleCheck(roles);
    const {data:user, accessToken,loading} = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();

    useEffect(() => {
        if (!accessToken) {
            navigate('/login');
        }

    }, [accessToken, user, roles, navigate]);


    return (accessToken && canAccess) ? <>{children}</> : loading ? <Spinner /> : <AccessDenied />

};

export default RoleRoute;