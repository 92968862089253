import styles from "../../../assets/scss/Modal.module.scss";
import React from 'react';
import { Modal} from '@mui/material';
import { ModalProps } from "../../../components/common/Interfaces/Modals";
import MDEditor from '@uiw/react-md-editor';

const FirmwareDetailsModal: React.FC<ModalProps> = ({open, setOpen,object}) => {
  const firmware = object;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        open={open}
      >
        <div className={styles.modal}>
          <div className={styles.header}>
              <div style={{textTransform:'capitalize'}}><strong>{firmware?.type}</strong> </div>
              <small>{firmware?.version}</small>
              <div>{firmware?.is_latest ? <span className="check">✓</span>:``}</div>
          </div>

            <div className="markdown-body" data-color-mode={localStorage.getItem('data-theme') ?? (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')}>
              <MDEditor.Markdown source={firmware?.description} className="markdown-preview" />
            </div>

            <div className="modal-btn-group">
                <p></p>
                <button className="as-text" onClick={handleClose}> Close </button>
            </div>
        </div>
      </Modal>
    </div>
  )};
;

export default FirmwareDetailsModal;
