import styles from "../../../assets/scss/Modal.module.scss";
import React, {useState} from "react";
import {Modal, CircularProgress} from '@mui/material';
import { AppDispatch } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { deleteFirmware } from "../../../redux/actions/firmware.actions";
import { ModalProps } from "../../../components/common/Interfaces/Modals";
import RootState from "../../../redux/states/root.state";

const DeleteFirmwareModal: React.FC<ModalProps> = (
  {
    open, 
    setOpen, 
    object, 
  }) => {
  const firmware = object;
  const {loading, error} = useSelector((state: RootState) => state.firmware);
  const dispatch: AppDispatch = useDispatch();

  const [submitted, setSubmitted] = useState(false);
  
  const handleClose = () => {
    setSubmitted(false);
    setOpen(false);
  };

  const handleDelete = async () => {
    await dispatch(deleteFirmware(firmware?.id));
    setSubmitted(true);
  }

  return (
    <div>
    <Modal
      open={open}
      onClose={handleClose}
    >

      <div className={styles.modal}>
          <div className={styles.header}>
              {error?
                <p>An Error Occurred</p>

              : loading ?
                <p>Deleting...</p>
                
              : !submitted ?
                <p> Delete firmware <b>{`${firmware?.type} (${firmware?.version})`}</b>? </p>

              :
              <p>Success</p>
              }
              
          </div>
          <div className={styles.body}>
              {error?
                <span>
                  <div>An error occured while deleting, please try again later.</div>
                  <br></br>
                  <div>{error}</div>
                </span>
              :
              loading ?
                <CircularProgress size={"24px"} />
              : !submitted ?
                <p> This action cannot be undone. </p>
              :
                <div>Firmware successfully deleted.</div>
              }
          </div>
          {
              error || submitted ?
              <div className="modal-btn-group">
                  <div></div>
                  <button className="as-text" onClick={handleClose}> OK </button>                        
          </div>
          : !loading ?
          <div className="modal-btn-group">
                <button className={styles.delete} onClick={handleDelete}> Delete </button>
                <button className="as-text" onClick={handleClose}> Cancel </button>
          </div>
          :
          <></>
          }
      </div>
    </Modal>
  </div>
);
};
    
export default DeleteFirmwareModal;
