import React from "react";
import RolesTable from "./RolesTable";

const RolesContainer: React.FC = () => {
  return (
    <div className="container">
        <div className="list">
            <RolesTable/>
        </div>
    </div>
)
};

export default RolesContainer;