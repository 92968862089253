import styles from "../../../assets/scss/Modal.module.scss";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, FormControl, CircularProgress,Checkbox, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import { AppDispatch, RootState } from '../../../redux/store'; 
import { updateFirmware } from "../../../redux/actions/firmware.actions";
import { ModalProps } from "../../../components/common/Interfaces/Modals";
import MDEditor from '@uiw/react-md-editor';

const UpdateFirmwareInfoModal: React.FC<ModalProps> = ({open, setOpen,object}) => {
    const firmware = object;
    const dispatch = useDispatch<AppDispatch>();
    const { accessToken } = useSelector((state: RootState) => state.user);

    const { error, loading } = useSelector((state: RootState) => state.firmware);
    const [submitted, setSubmitted] = useState(false);
    const [description, setDescription] = useState('');
    const [isLatest, setIsLatest] = useState(false);
      
    useEffect(() => {
      if (firmware && open){
        setIsLatest(firmware.is_latest);
        setDescription(firmware.description);
      }
    }, [firmware,open]);
    
    const handleSubmit = async (event: React.FormEvent) => {
      await dispatch(updateFirmware({firmware_id:firmware.id, is_latest:isLatest, description:description}));
      setSubmitted(true);
    };

    const handleClose = () => {
      setIsLatest(false);
      setDescription('');
      setSubmitted(false);
      setOpen(false);
    };

if ((!submitted && !error) || loading ) {
  return (
    <div>
      <Modal
        open={open}
      >
        <div className={styles.modal}>
          <div className={styles.header}>
              Update Firmware
          </div>

            <div className={styles.body}>
              <span className="update-form">
    
              <FormControl fullWidth margin="normal" required sx={{textAlign:'left'}} data-color-mode={localStorage.getItem('data-theme') ?? (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')}>            
                  <FormLabel required>Description</FormLabel>
                  <MDEditor value={description} onChange={(value) => setDescription(value??"")} aria-required/>
                </FormControl>

                <FormGroup>
                  <FormControlLabel 
                  label="Latest"
                  control={
                    <Checkbox
                      checked={isLatest}
                      onChange={(e) => setIsLatest(e.target.checked)}
                      disabled={loading}
                    />
                  } />
                </FormGroup>
              </span>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading || !accessToken || description===""}
                sx={{ mt: 2 }}
                onClick={handleSubmit}
              >
                {loading ? (
                  <CircularProgress size={"24px"} style={{ color: "var(--pure-white)" }} />
                  ) : (
                  "Update Firmware Info"
                  )} 
              </Button>

            </div>

            <div className="modal-btn-group">
                <button className="as-text" onClick={handleClose}> Close </button>
            </div>
        </div>
      </Modal>
    </div>
  )
}  else if (error) {
  return (
    <div>
      <Modal
        open={open}
      >
        <div className={styles.modal}>
          <div className={styles.header}>
              {`An Error Occured`}
          </div>

            <div className={styles.body}>
              {error}
            </div>

            <div className="modal-btn-group">
                <p></p>
                <button className="as-text" onClick={handleClose}> OK </button>
            </div>
        </div>
      </Modal>
    </div>
  )} else {
  return (
    <div>
      <Modal
        open={open}
      >
        <div className={styles.modal}>
          <div className={styles.header}>
              {error? `An Error Occured`:`Success`}
          </div>

            <div className={styles.body}>
              {'Firmware successfully updated'}
            </div>

            <div className="modal-btn-group">
                <p></p>
                <button className="as-text" onClick={handleClose}> OK </button>
            </div>
        </div>
      </Modal>
    </div>
  )};
};

export default UpdateFirmwareInfoModal;
