import axios from 'axios';
import { BASE_URL } from './apiconfig';
import User from '../../redux/models/user.model';

export const getDealerships = async (token: string | null, params:string) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/dealerships?${params}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.log("Failed to pull dealerships list. ", error);
    throw error;
  }
}

export const getDealership = async (token: string | null, id: string) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/dealerships/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return data;
  } catch (error) {
    console.log("Failed to pull dealership by id. ", error);
    throw error;
  }
}

export const getStaff = async (token: string | null, getInfo:{id:string, params:string}) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/dealerships/${getInfo.id}/staff?${getInfo.params}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return data;
  } catch (error) {
    console.log("Failed to pull dealership staff. ", error);
    throw error;
  }
}

export const storeDealership = async (token: string | null, 
  dealership:{
    name?:string;
    city?:string;
    phone?:string;
    email?:string;
    website_url?:string;
    supplies_url?:string;
    region_id?:string;
    country_id?:string;
  }) => {
    try {
      const { data } = await axios.post(`${BASE_URL}/admin/dealerships`,
        dealership,
      {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      return data;
    } catch (error) {
      console.log("Failed to create dealership. ", error);
      throw error;
    }
  }

export const destroyDealership = async (token: string | null, id: string) => {
  try {
    const { data } = await axios.delete(`${BASE_URL}/admin/dealerships/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    return data;
  } catch (error) {
    console.log("Failed to delete dealership. ", error);
    throw error;
  }
}

export const putDealership = async (token: string | null, 
dealership:{
  id:string;
  name?:string;
  city?:string;
  phone?:string;
  email?:string;
  website_url?:string;
  supplies_url?:string;
  region_id?:string;
  country_id?:string;
}) => {
  try {
    const { data } = await axios.put(`${BASE_URL}/dealerships/${dealership.id}`,
      dealership,
    {
      headers: { Authorization: `Bearer ${token}` },
    });

    return data;
  } catch (error) {
    console.log("Failed to update dealership. ", error);
    throw error;
  }
}

export const putDealershipCorporate = async (token: string | null, 
  dealership:{
    id:string;
    corporate_user_id?:string|null;
  }) => {
    try {
      const { data } = await axios.put(`${BASE_URL}/admin/dealerships/${dealership.id}`,
        dealership,
      {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      return data;
    } catch (error) {
      console.log("Failed to update dealership's corporation. ", error);
      throw error;
    }
  }

  export const assignStaffApi = async (token: string | null, dealershipInfo: {dealership_id:string, users:User[], type:'owner'|'dealer'|'staff'}) => {
    try {
      let url = `${BASE_URL}/dealerships/`;
      switch (dealershipInfo.type) {
        case 'staff':
          url+=`assignStaff`
          break;
        case 'dealer':
          url+=`assignDealer`
          break;
        case 'owner':
          url+=`assignOwner`
          break;
      }
      const { data } = await axios.post(url, {
        dealership_id: dealershipInfo.dealership_id,
        users: dealershipInfo.users
      },{
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (error) {
      console.log("Failed to assign dealership to users. ", error);
      throw error;
    }
  }

  export const removeStaffApi = async (token: string | null, dealershipInfo: {dealership_id:string, users:User[], type:'owner'|'dealer'|'staff'}) => {
    try {
      let url = `${BASE_URL}/dealerships/`;
      switch (dealershipInfo.type) {
        case 'staff':
          url+=`removeStaff`
          break;
        case 'dealer':
          url+=`removeDealer`
          break;
        case 'owner':
          url+=`removeOwner`
          break;
      }
      const { data } = await axios.post(url, {
        dealership_id: dealershipInfo.dealership_id,
        users: dealershipInfo.users
      },{
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (error) {
      console.log("Failed to remove dealership from users. ", error);
      throw error;
    }
  }
