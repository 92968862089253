import styles from "../../../assets/scss/Modal.module.scss";
import React, { useState, useEffect } from "react";
import { AppDispatch } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../../redux/states/root.state";
import {
  Modal, 
  TextField, 
  CircularProgress,  
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
 } from '@mui/material';
import { fetchCountries, fetchRegions } from "../../../redux/actions/countries.actions";
import { assignStaff, removeStaff, updateDealership, updateDealershipCorporate } from "../../../redux/actions/dealership.actions";
import EditDealershipOwnersModal from "./EditDealershipOwnersModal";
import { fetchRoles } from "../../../redux/actions/roles.actions";
import { ModalProps } from "../../../components/common/Interfaces/Modals";
import useRoleCheck from "../../../hooks/useRoleCheck";

const EditDealershipModal: React.FC<ModalProps> = ({open, setOpen, object}) => {
  const dealership = object;

  const dispatch: AppDispatch = useDispatch();
  const {error, loading} = useSelector((state: RootState) => state.dealership);
  const {data:countries} = useSelector((state: RootState) => state.countries);
  const {data:regions} = useSelector((state: RootState) => state.regions);
  const {data:user} = useSelector((state: RootState) => state.user);

  const isAdmin = useRoleCheck(["admin","developer"]);
  const isCorporate = useRoleCheck(["corporate"]) && user?.id===dealership?.corporate?.id;
  const isOwner = useRoleCheck(["owner"]) && user?.id===dealership?.owner?.id;

  // User selection
  const [roleName, setRoleName] = useState<any>(null);
  const [role, setRole] = useState<any>(null);

  const {data:roles} = useSelector((state: RootState) => state.roles);
  const [openUserSelect, setOpenUserSelect] = useState(false);

  const [corporateInput, setCorporateInput] = useState<any>(null);
  const [ownerInput, setOwnerInput] = useState<any>(null);
  const [dealerInput, setDealerInput] = useState<any>(null);

  // Text Input
  const [regionInput, setRegionInput] = useState("");
  const [countryInput, setCountryInput] = useState("");

  const [success, setSuccess] = useState(false);

  const [updateInfo, setUpdateInfo] = useState<any>(null);

  const handleClose = () => {
    handleReset();
    setOpen(false)
  };

  useEffect(() => {
    if (roleName){
      dispatch(fetchRoles(`?name=${roleName}`));
    }
  }, [dispatch,roleName]);

  useEffect(() => {
    if (roles){
      setRole(roles[0]);
    }
  }, [roles]);

  useEffect(() => {
    if (dealership && open){
      setCountryInput(dealership.country?.id??"");
      setRegionInput(dealership.region?.id??"");
    }
  }, [open, dealership]);

  useEffect(() => {
    if (open) {
      dispatch(fetchCountries());
      if (countryInput!=='') {
        dispatch(fetchRegions(countryInput));
      }
    }
  }, [dispatch,open,countryInput]);

  const handleSelect = (event: SelectChangeEvent, selectField: string) => {
    switch (selectField){
      case 'country':
        setCountryInput(event.target.value as string);
        setUpdateInfo({...updateInfo, country_id:event.target.value as string})
        setRegionInput('');
        break;

      case 'region':
        setRegionInput(event.target.value as string);
        setUpdateInfo({...updateInfo, region_id:event.target.value as string})
        break;
    }
  };

  const handleEdit = async () => {
    if ((ownerInput && ownerInput!=='none')||(dealerInput && dealerInput!=='none')) {
      if (window.confirm('This action will unlink the users from their current dealership if any. Continue?')!==true) {
        return;
      }
    }

    if (updateInfo) await dispatch(updateDealership({...updateInfo,id:dealership?.id}));

    if (corporateInput) await dispatch(updateDealershipCorporate({id:dealership?.id, corporate_user_id:corporateInput==='none'?null:corporateInput.id}));

    if (ownerInput) {
      ownerInput==='none'? 
        await dispatch(removeStaff({dealership_id:dealership?.id,users:[dealership?.owner?.id],type:'owner'}))
        : 
        await dispatch(assignStaff({dealership_id:dealership?.id,users:[ownerInput],type:'owner'}))
    }

    if (dealerInput) {
      dealerInput==='none' ?
        await dispatch(removeStaff({dealership_id:dealership?.id,users:[dealership?.dealer?.id],type:'dealer'}))
      :
        await dispatch(assignStaff({dealership_id:dealership?.id,users:[dealerInput],type:'dealer'}))
    }

    if (!error){
      setSuccess(true);
    }
    
  }

  const handleReset = () => {
    setCorporateInput(null);
    setOwnerInput(null);
    setDealerInput(null);
    setCountryInput("");
    setRegionInput("");
    setSuccess(false);
  }

  return (
    <>
      <EditDealershipOwnersModal 
        role={role} 
        setCorporate={setCorporateInput} 
        setOwner={setOwnerInput} 
        setDealer={setDealerInput} 
        isOpen={openUserSelect} 
        setOpen={setOpenUserSelect} dealership={dealership} 
      />

      <div>
        <Modal
          open={open}
        >
          {loading ?
          <div className={styles.modal}>
            <div className={styles.header}>
              <p>Updating Dealership...</p>
            </div>

            <div className={styles.body}>
              <p><CircularProgress size={"24px"} sx={{position:'absolute', top:'50%', right:'50%'}}/></p>
            </div>
          </div>
          :
          error ?
          <div className={styles.modal}>
            <div className={styles.header}>
              <p>An Error Occurred</p>
            </div>

            <div className={styles.body}>
              <p>{error}</p>
            </div>

            <div className="modal-btn-group">
              <div></div>
              <button className="as-button" onClick={handleClose}>OK</button>  
            </div>

          </div>
          :
          success ?
          <div className={styles.modal}>
            <div className={styles.header}>
              <p>Success</p>
            </div>

            <div className={styles.body}>
              <p>Dealership Successfully Updated</p>
            </div>

            <div className="modal-btn-group">
              <div></div>
              <button className="as-button" onClick={handleClose}>OK</button>  
            </div>
          </div>
          :
          <div className={styles.modal}>
              <div className={styles.header}>
                  <p><strong>Edit {dealership?.name}</strong></p>
              </div>
              <div className="edit-modal-input">

                  <div className="dealership-user-edit">
                    <span>Corporation: {corporateInput ? corporateInput==='none'? 'None':corporateInput.name : dealership?.corporate?.name}</span>
                    {isAdmin && <button className="button" onClick={()=>{setRoleName('corporate');setOpenUserSelect(true)}}>Edit</button>}
                  </div>

                  <div className="dealership-user-edit">
                  <span>Owner: {ownerInput ? ownerInput==='none'? 'None':ownerInput.name : dealership?.owner?.name}</span>
                    {(isAdmin||isCorporate) && <button className="button" onClick={()=>{setRoleName('owner');setOpenUserSelect(true)}}>Edit</button>}
                  </div>
                  <div className="dealership-user-edit">
                  <span>Dealer: {dealerInput ? dealerInput==='none'? 'None':dealerInput.name : dealership?.dealer?.name}</span>
                    {(isAdmin||isCorporate||isOwner) && <button className="button" onClick={()=>{setRoleName('dealer');setOpenUserSelect(true)}}>Edit</button>}
                  </div>

                  <TextField 
                    id="name" 
                    label="Name" 
                    variant="outlined" 
                    fullWidth 
                    defaultValue={dealership?.name??""} 
                    onChange={(e)=>setUpdateInfo({...updateInfo, name:e.target.value})}/>

                  <TextField 
                    id="city" 
                    label="City" 
                    variant="outlined" 
                    fullWidth 
                    defaultValue={dealership?.city??""}
                    onChange={(e)=>setUpdateInfo({...updateInfo, city:e.target.value})}/>

                  <FormControl fullWidth>
                    <InputLabel>Country</InputLabel>
                    <Select
                      label="Country"
                      value={countryInput}
                      onChange={e => handleSelect(e,'country')}
                    >
                      <MenuItem value={''}>All</MenuItem>
                      { countries?.length>0 &&
                        countries.map((country:any)=>
                          <MenuItem key={country?.id} value={country?.id}>{country?.label}</MenuItem>
                        )
                      }
                    </Select>
                  </FormControl>

                <FormControl fullWidth>
                  <InputLabel>{countryInput==='' || regions.length>0?`Region`:`No Regions Found.`}</InputLabel>
                  <Select
                    value={regionInput}
                    onChange={e => handleSelect(e,'region')}
                    disabled={regions.length===0}
                  >
                    <MenuItem value={''}>All</MenuItem>
                    { regions?.length>0 && countryInput!=='' &&
                      regions.map((region:any)=>
                        <MenuItem key={region?.id} value={region?.id}>{region?.label}</MenuItem>
                      )
                    }
                  </Select>
                </FormControl>
                <div className="edit-header">Contact</div>
                <TextField 
                    id="phone" 
                    label="Phone" 
                    variant="outlined" 
                    fullWidth 
                    defaultValue={dealership?.phone??""}
                    onChange={(e)=>setUpdateInfo({...updateInfo, phone:e.target.value})}/>

                  <TextField 
                    id="email" 
                    label="Email" 
                    variant="outlined" 
                    fullWidth 
                    defaultValue={dealership?.email??""}
                    onChange={(e)=>setUpdateInfo({...updateInfo, email:e.target.value})}/>

                  <TextField 
                    id="website_url" 
                    label="Website" 
                    variant="outlined" 
                    fullWidth 
                    defaultValue={dealership?.website_url??""}
                    onChange={(e)=>setUpdateInfo({...updateInfo, website_url:e.target.value})}/>

                  <TextField 
                    id="supplies_url" 
                    label="Supplies" 
                    variant="outlined" 
                    fullWidth 
                    defaultValue={dealership?.supplies_url??""}
                    onChange={(e)=>setUpdateInfo({...updateInfo, supplies_url:e.target.value})}/>
              </div>

              <div className="modal-btn-group">
                  <button className="as-text" onClick={handleClose}> Cancel </button>
                  <button className="as-button" onClick={handleEdit} disabled={loading}>                         
                    {loading ? (
                      <CircularProgress size={"24px"} style={{ color: "as-white" }} />
                      ) : (
                      "Save"
                      )}
                  </button>
                  
              </div>
          </div>}
        </Modal>
      </div>
    </>
  );
};

export default EditDealershipModal;
