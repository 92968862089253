import React, {useEffect, useMemo, useState} from "react";
import "./Filtration.css";
import "../SpaControlPage.css";
import Header from "../../../components/common/Header/Header";
import {useParams} from "react-router-dom";
import {AppDispatch} from "../../../redux/store";
import {useDispatch, useSelector} from "react-redux";
import RootState from "../../../redux/states/root.state";
import {fetchSpa} from "../../../redux/actions/spa.actions";
import useMQTT from "../../../hooks/useMQTT";
import Command from "../../../classes/command.class";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import useDebounce from "../../../hooks/useDebounce";
import { inputDebounce, settingsDebounce } from "../debounceTimes";


const Filtration = () => {
    // Configs:
    const { id } = useParams();
    const dispatch: AppDispatch = useDispatch();

    // SPA:
    const spa = useSelector((state: RootState) => state.spa.data);
    const isConnected = useSelector((state: RootState) => state.spa.isConnected);

    const settingsData = useSelector((state: RootState) => state.settings.data);
    const constData = useSelector((state: RootState) => state.const.data);

    // MQTT Functionality:
    const [ commandTopic] = useMQTT(id ?? '')
    const command = new Command(commandTopic)

    /* Filters functionality */
    const [hasFilters, setHasFilters] = useState(0)

    const [stopFilterAbove3, setStopFilterAbove3] = useState(false)
    const [filterFrequencey, setFilterFrequencey] = useState(0)
    const [filterDuration, setfilterDuration] = useState(0)

    const debouncedFS = useDebounce(stopFilterAbove3, inputDebounce);
    const debouncedFF = useDebounce(filterFrequencey, inputDebounce);
    const debouncedFD = useDebounce(filterDuration, inputDebounce);

    /* Filters functionality */
    const [settingsFS, setSettingsFS] = useState(false)
    const [settingsFF, setSettingsFF] = useState(0)
    const [settingsFD, setSettingsFD] = useState(0)

    const debouncedSettingsFS = useDebounce(settingsFS, settingsDebounce);
    const debouncedSettingsFF = useDebounce(settingsFF, settingsDebounce);
    const debouncedSettingsFD = useDebounce(settingsFD, settingsDebounce);

    // Fetch SPA From the DB
    useEffect(() => {
        if (id != null) {
            dispatch(fetchSpa(id));
        }
    }, [dispatch, id]);

  useEffect(() => {
      if(!settingsData) return;

      if(settingsData.RFIDConnected)
        setHasFilters(settingsData.RFIDConnected)

      if(settingsData.FS)
        setSettingsFS(settingsData.FS)

      if(settingsData.FF)
        setSettingsFF(settingsData.FF)

      if(settingsData.FD)
        setSettingsFD(settingsData.FD)

  }, [settingsData]);


  /* FILTER SUSPENSION CHANGE */
    const handleStopFilterChange = (value: boolean) => {
        setStopFilterAbove3(value);
        setSettingsFS(value);
    }

    useMemo(() => {
        if (debouncedSettingsFS!==debouncedFS) {
            command.updateSettings({
                setFS: debouncedFS,
            })
        }
    }, [debouncedFS]);

    useMemo(() => {
        if (debouncedSettingsFS!==debouncedFS) {
            setStopFilterAbove3(debouncedSettingsFS);
        }
    }, [debouncedSettingsFS]);


  /* FILTER FREQUENCY CHANGE */
  const handleFilterFrequencyChange = (value: number) => {
      setFilterFrequencey(value);
      setSettingsFF(value)
  }

    useMemo(() => {
        if (debouncedSettingsFF!==debouncedFF) {
            command.updateSettings({
                setFF: debouncedFF as number,
            })
        }
    }, [debouncedFF]);

    useMemo(() => {
        if (debouncedSettingsFF!==debouncedFF) {
            setFilterFrequencey(debouncedSettingsFF as number);
        }
    }, [debouncedSettingsFF]);

    /* FILTER DURATION CHANGE */
  const handleFilterDurationChange = (value: number) => {
      setfilterDuration(value);
      setSettingsFD(value);
    }

    useMemo(() => {
        if (debouncedSettingsFD!==debouncedFD) {
            command.updateSettings({
                setFD: debouncedFD as number,
            })
        }
        }, [debouncedFD]);

    useMemo(() => {
        if (debouncedSettingsFD!==debouncedFD) {
            setfilterDuration(debouncedSettingsFD as number);
        }
    }, [debouncedSettingsFD]);

    return (
        <div className="page">
            <Header/>
            <div className="controls-page-container filtration">
                <div className="filter-status">Filter Status</div>
                <div className="filter-indicators">
                    <div id="filter-one" className={hasFilters ? `good`:`danger`}>
                        {!hasFilters && <span>{`Error`}</span>}
                        {hasFilters ? <span>{`Good`}</span>:<span>{`No Filter Present`}</span>}
                    </div>
                    <div id="filter-two" className={hasFilters ? `good`:`danger`}>
                        {!hasFilters && <span>{`Error`}</span>}
                        {hasFilters ? <span>{`Good`}</span>:<span>{`No Filter Present`}</span>}
                    </div>
                </div>
                <div className="filter-settings">
                    <div className="duration">
                        <button className={`filter control-button`}  onClick={()=>handleFilterDurationChange(filterDuration-1)} disabled={filterDuration<=(constData.setFDmin??0)||!isConnected}>
                            <KeyboardArrowDownIcon fontSize='large' sx={{transform:'scale(2)'}}/>
                        </button>
                        <div className="filter value">
                            {filterDuration}
                            <span className="units">{`Hours`}</span>
                        </div>
                        <button className={`filter control-button`}  onClick={()=>handleFilterDurationChange(filterDuration+1)} disabled={filterDuration>=(constData.setFDmax??2)||!isConnected}>
                            <KeyboardArrowUpIcon fontSize='large' sx={{transform:'scale(2)'}}/>
                        </button>
                    </div>
                    <div className="frequency">
                        <button className={`filter control-button`} onClick={()=>handleFilterFrequencyChange(filterFrequencey-1)} disabled={filterFrequencey<=(constData.setFFmin??0)||!isConnected}>
                            <KeyboardArrowDownIcon fontSize='large' sx={{transform:'scale(2)'}}/>
                        </button>
                        <div className="filter value">
                            {filterFrequencey}
                            <span className="filter units">{`Times per Day`}</span>
                        </div>
                        <button className={`filter control-button`} onClick={()=>handleFilterFrequencyChange(filterFrequencey+1)} disabled={filterFrequencey>=(constData.setFFmax??12)||!isConnected}>
                            <KeyboardArrowUpIcon fontSize='large' sx={{transform:'scale(2)'}}/>
                        </button>
                    </div>
                </div>
                <div className="filter-summary">
                    <span>Filter will run for {filterDuration*filterFrequencey} {filterDuration*filterFrequencey!==1 ? `hours`:`hour`} everyday</span>
                </div>
                <div className="filter-setpoint">
                    <span>Stop filter 3° above set temperature: </span>
                    <button disabled={!isConnected} className={`filter control-button toggle ${stopFilterAbove3?`active`:``}`} onClick={()=>handleStopFilterChange(!stopFilterAbove3)}>{stopFilterAbove3?`ON`:`OFF`}</button>
                </div>
            </div>
                
        </div>
    );
};

export default Filtration;
