import React, {useEffect, useMemo, useState} from "react";
import "./Sanitation.css";
import Header from "../../../components/common/Header/Header";
import {useDispatch, useSelector} from "react-redux";
import RootState from "../../../redux/states/root.state";
import Command from "../../../classes/command.class";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { SpaConstLabels } from "../../../mqttData/consts/spaConstsLabels.enums";
import { SpaLiveLabels } from "../../../mqttData/live/spaLiveLabels.enums";
import { SpaSettingsLabels } from "../../../mqttData/settings/spaSettingsLabels";
import useMQTT from "../../../hooks/useMQTT";
import { useParams } from "react-router-dom";
import { AppDispatch } from "../../../redux/store";
import { fetchSpa } from "../../../redux/actions/spa.actions";
import useDebounce from "../../../hooks/useDebounce";
import { inputDebounce, settingsDebounce } from "../debounceTimes";

const Sanitation = () => {
    const dispatch: AppDispatch = useDispatch();
    const {id} = useParams();
    const spa = useSelector((state: RootState) => state.spa.data);
    const isConnected = useSelector((state: RootState) => state.spa.isConnected);
    const settingsData = useSelector((state: RootState) => state.settings.data);
    const liveData = useSelector((state: RootState) => state.live.data);
    const constData = useSelector((state: RootState) => state.const.data);

    
    // Fetch SPA From the DB
    useEffect(() => {
        if (id != null) {
            dispatch(fetchSpa(id));
        }
    }, [dispatch, id]);

    // MQTT Functionality:
    const [commandTopic] = useMQTT(id ?? "");
    const command = new Command(commandTopic);

    let valueMapper: { [p: number]: string };

    valueMapper = {
      0:'OFF',
      1:'P1 low',
      2:'P1 high',
      3:'Yess',
    }

    let subtitleMapper: { [p: number]: string };

    subtitleMapper = {
      0:'Do not run Ozone',
      1:'Ozone will run in conjunction with Pump 1 low speed',
      2:'Ozone will run in conjunction with Pump 1 high speed',
      3:'Ozone will run in conjunction with Yess',
    }

    /* Filters functionality */
    const [scaling, setScaling] = useState(1);
    const [ozoneCycle, setOzoneCycle] = useState(0);
    const [ozoneHours, setOzoneHours] = useState(0);
    const [onzenHours, setOnzenHours] = useState(0);

    const debouncedOzCy = useDebounce(ozoneCycle, inputDebounce);
    const debouncedOzHr = useDebounce(ozoneHours, inputDebounce);
    const debouncedOnHr = useDebounce(onzenHours, inputDebounce);

    const [settingsOzCy, setSettingsOzCy] = useState(0);
    const [settingsOzHr, setSettingsOzHr] = useState(0);
    const [settingsOnHr, setSettingsOnHr] = useState(0);

    const debouncedSettingsOzCy = useDebounce(settingsOzCy, settingsDebounce);
    const debouncedSettingsOzHr = useDebounce(settingsOzHr, settingsDebounce);
    const debouncedSettingsOnHr = useDebounce(settingsOnHr, settingsDebounce);

    useEffect(() => {
        let arr = [settingsData[SpaSettingsLabels.cfgOzP1],settingsData[SpaSettingsLabels.cfgOzP2],settingsData[SpaSettingsLabels.cfgOn]];
        arr = arr.filter((cfg:any)=>cfg);
        switch (arr.length) {
            case 0:
            case 1:
                setScaling(1.75);
                break;
            case 2:
                setScaling(1.25);
                break;
            case 3:
                setScaling(1);
                break;
        }

        if(!settingsData) return;

        if(settingsData.OzCy)
            setSettingsOzCy(settingsData.OzCy)

        if(settingsData.OzHr)
            setSettingsOzHr(settingsData.OzHr)

        if(settingsData.OnHr)
            setSettingsOnHr(settingsData.OnHr)
    }, [settingsData]);

    /* OZONE CYCLE CHANGE */
    const handleOzCycleChange = (value: number) => {
        setOzoneCycle(value);
        setSettingsOzCy(value);
    }

    useMemo(() => {
        command.updateSettings({
            setOzCy: debouncedOzCy,
        })
    }, [debouncedOzCy]);

    useMemo(() => {
        if (debouncedSettingsOzCy!==debouncedOzCy) {
            setOzoneCycle(debouncedSettingsOzCy);
        }
    }, [debouncedSettingsOzCy]);

    /* OZONE HOURS CHANGE */
    const handleOzHourChange = (value: number) => {
        setOzoneHours(value);
        setSettingsOzHr(value);
    }

    useMemo(() => {
        command.updateSettings({
            setOzHr: debouncedOzHr,
        })
    }, [debouncedOzHr]);

    useMemo(() => {
        if (debouncedSettingsOzHr!==debouncedOzHr) {
            setOzoneHours(debouncedSettingsOzHr);
        }
    }, [debouncedSettingsOzHr]);


    /* ONZEN HOUR CHANGE */
    const handleOnHourChange = (value: number) => {
        setOnzenHours(value);
        setSettingsOnHr(value);
    }

    useMemo(() => {
        command.updateSettings({
            setOnHr: debouncedOnHr,
        })
    }, [debouncedOnHr]);

    useMemo(() => {
        if (debouncedSettingsOnHr!==debouncedOnHr) {
            setOnzenHours(debouncedSettingsOnHr);
        }
    }, [debouncedSettingsOnHr]);

    return (
        <div className="page">
            <Header/>

            <div className="controls-page-container sanitation">
                {settingsData[SpaSettingsLabels.cfgOzP1] &&
                <>
                    <div className="sanitation-setting" id="ozone-peak1">
                        <div className="settings-header">Ozone Peak I</div>
                        <div className="status-icon">
                            <div className={`status-avatar ${liveData[SpaLiveLabels.Oz] as boolean ? `selected`:`default`}`} id="Ozone"></div>
                        </div>
                        <div className="status-text">
                            {isConnected ? liveData[SpaLiveLabels.Oz] as boolean ? `Active`:`Idle`:`Offline`}
                        </div>
                        <div className="controls">
                            <button className="sanitation control-button" onClick={()=>handleOzCycleChange(ozoneCycle-1)} disabled={ozoneCycle===(constData[SpaConstLabels.setOzCymin]??0)||!isConnected}><KeyboardArrowLeftIcon sx={{transform:'scale(2)'}} fontSize="large"/></button>
                            <div className="sanitation value" style={{transform:`scale(${scaling})`}}>{valueMapper[ozoneCycle]}</div>
                            <button className="sanitation control-button" onClick={()=>handleOzCycleChange(ozoneCycle+1)} disabled={ozoneCycle===(constData[SpaConstLabels.setOzCymax]??3)||!isConnected}><KeyboardArrowRightIcon sx={{transform:'scale(2)'}} fontSize="large"/></button>
                        </div>
                        <span className="units">
                            {``}
                        </span>
                        <div className="summary">
                            {subtitleMapper[ozoneCycle]}
                        </div>
                    </div>
                </>
                }
                {settingsData[SpaSettingsLabels.cfgOzP2] &&
                <>
                    <div className="sanitation-setting" id="ozone-peak2">
                        <div className="settings-header">Ozone Peak II</div>
                        <div className="status-icon">
                            <div className={`status-avatar ${liveData[SpaLiveLabels.Oz] as boolean ? `selected`:`default`}`} id="Ozone"></div>
                        </div>
                        <div className="status-text">
                            {isConnected ? liveData[SpaLiveLabels.Oz] as boolean ? `Active`:`Idle`:`Offline`}
                        </div>
                        <div className="controls">
                            <button className="sanitation control-button" onClick={()=>handleOzHourChange(ozoneHours-1)} disabled={ozoneHours===(constData[SpaConstLabels.setOzHrmin]??0)||!isConnected}><KeyboardArrowDownIcon sx={{transform:'scale(2)'}} fontSize="large"/></button>
                            <div className="sanitation value" style={{transform:`scale(${scaling})`}}>
                                {ozoneHours}
                                <span className="units">{`${ozoneHours===1?`Hour`:`Hours`}/Day`}</span>
                            </div>
                            <button className="sanitation control-button" onClick={()=>handleOzHourChange(ozoneHours+1)} disabled={ozoneHours===(constData[SpaConstLabels.setOzHrmax]??24)||!isConnected}><KeyboardArrowUpIcon sx={{transform:'scale(2)'}} fontSize="large"/></button>
                        </div>
                        <div className="summary">
                            {`Peak II Ozone will operate for a total of ${ozoneHours} ${ozoneHours===1?`hour`:`hours`} per day.`}
                        </div>
                    </div>

                </>}
                
                {settingsData[SpaSettingsLabels.cfgOn] &&
                <div className="sanitation-setting" id="onzen">
                    <div className="settings-header">Onzen</div>
                    <div className="status-icon">
                        <div className={`status-avatar ${liveData[SpaLiveLabels.On] as boolean ? `selected`:`default`}`} id="Onzen"></div>
                    </div>
                    <div className="status-text">
                        {isConnected ? liveData[SpaLiveLabels.On] as boolean ? `Active`:`Idle`:`Offline`}
                    </div>
                    <div className="controls">
                        <button className="sanitation control-button" onClick={()=>handleOnHourChange(onzenHours-1)} disabled={onzenHours===(constData[SpaConstLabels.setOnHrmin]??0)||!isConnected}><KeyboardArrowDownIcon sx={{transform:'scale(2)'}} fontSize="large"/></button>
                        <div className="sanitation value" style={{transform:`scale(${scaling})`}}>
                            {onzenHours}
                            <span className="units">{`Hours/Day`}</span>
                        </div>
                        <button className="sanitation control-button" onClick={()=>handleOnHourChange(onzenHours+1)} disabled={onzenHours===(constData[SpaConstLabels.setOnHrmax]??24)||!isConnected}><KeyboardArrowUpIcon sx={{transform:'scale(2)'}} fontSize="large"/></button>
                    </div>
                    <div className="summary">
                        {`Onzen will operate for a total of ${onzenHours} ${onzenHours===1?`hour`:`hours`} per day.`}
                    </div>
                </div>}
                
            </div>
                
        </div>
    );
};

export default Sanitation;
