import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import CountriesState from '../states/countries.state';
import { fetchCountries } from '../actions/countries.actions';

const initialState: CountriesState = {
  loading: false,
  data: [],
  error: null,
};

const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountries.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCountries.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(fetchCountries.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      });
  },
});

export default countriesSlice.reducer;