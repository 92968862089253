import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import RootState from "../../../redux/states/root.state";
import {
  CircularProgress, 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableRow,
  TableSortLabel,
  Box,
  Pagination
} from "@mui/material";
import { visuallyHidden } from '@mui/utils';
import { DeleteIcon, EditIcon } from "../../../assets/svg/svg";
import { useSearchParams } from "react-router-dom";

type Order = 'asc' | 'desc';
const headers = [
  {id:'id', label:'ID', align:"center"}, 
  {id:'title', label:'Title',align:null}, 
  {id:'body', label:'Body',align:null}, 
  {id:'updated_at', label:'Last Updated',align:null}, 
  {id:'created_at', label:'Created',align:null},
  {id:'edit', label:'Edit', align:"center"},
  {id:'delete', label:'Delete', align:"center"},
];


interface TableProps {
  setCommandToModify?: any,
  setEditOpen?: any,
  setDeleteOpen?: any,
  page?: 'commands'|'reports'
}

const CommandsTable: React.FC<TableProps> = (
  {
    setCommandToModify=null,
    setEditOpen=null,
    setDeleteOpen=null,
    page='commands'
  }
) => {

  const {data:commands, loading, meta} = useSelector((state: RootState) => state.commands);
  // Table Sort
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [sortedRows, setSortedRows] = useState<any[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setSortedRows(commands);
  }, [commands]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    header_id: string,
  ) => {
    const isAsc = orderBy === header_id && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(header_id);

    handleSort();
  };

  const handleSort = () => {
    let newArray = [...commands].sort((a:any, b:any)=>{
      return a[orderBy] === b[orderBy] ? 0 : order==='asc'? a[orderBy] ? -1:1 : b[orderBy] ? 1:-1;
    })
    setSortedRows(newArray);
    
  }

  const handleChange = (event:any, value:any) => {
    setSearchParams({page: value})  
  }

  return (
    <>
          <div className="table">
            {loading ? ( 
              <CircularProgress size={"24px"} sx={{position:'absolute', top:'50%', right:'50%'}}/>
            ): 
            
            commands.length>0 ? (
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {headers.map((header)=>(
                    ((page==='reports' && !['edit','delete'].includes(header.id))||(page==='commands')) &&
                    <TableCell key={header.id} align={header.align?"center":"left"}>
                      {header.id!=='edit' && header.id!=='delete' ? 
                      <TableSortLabel
                        active={orderBy === header.id}
                        direction={orderBy === header.id ? order : 'asc'}
                        onClick={(e)=>handleRequestSort(e,header.id)}
                      >
                        {header.label}
                        {orderBy === header.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                      :
                      (header.label)
                    }

                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
              {sortedRows.map((command,i)=>(
                <TableRow key={i}>
                  <TableCell  align='center'>
                    {command?.id}
                  </TableCell>

                  <TableCell>
                    {command?.title}
                  </TableCell>

                  <TableCell>
                    {JSON.stringify(command?.body)}
                  </TableCell>

                  <TableCell>
                    {new Date(command?.updated_at).toUTCString()}
                  </TableCell>

                  <TableCell>
                    {new Date(command?.created_at).toUTCString()}
                  </TableCell>

                  {page==='commands' && 
                  
                  <TableCell align='center'>
                    <div className="clickable-icon" onClick={()=>{setCommandToModify(command); setEditOpen(true)}}>
                      <EditIcon/>
                    </div>
                  </TableCell>
                  }

                  {page==='commands' &&
                  <TableCell align='center'>
                    <div className="clickable-icon" onClick={()=>{setCommandToModify(command); setDeleteOpen(true)}}>
                      <DeleteIcon/>
                    </div>
                  </TableCell>
                  }
                    
                </TableRow>
                ))}
              </TableBody>

            </Table>
            ):(
            <div className="empty-table">No Commands Found.</div>
            )
          }
          </div>

          <div className="table-footer">
            <div className="pagination">
              <Pagination 
                count={meta?.last_page} 
                variant="outlined" 
                onChange={handleChange}
                page={searchParams.get("page")?Number(searchParams.get("page")):1}
                showLastButton 
                showFirstButton 
                disabled={meta?.total_pages===1}
                />
              <div className="total-count">Showing {commands?.length}/{meta?.total??0} Spa Commands</div>
            </div>
          </div>  
    </>
  );
};

export default CommandsTable;
