import "./Modals.css";
import styles from "../../../assets/scss/Modal.module.scss";
import React, {useState, useEffect} from "react";
import {Modal} from '@mui/material';
import { deregisterSpaUser, editSpa, fetchSpa, registerSpaUser } from "../../../redux/actions/spa.actions";
import { AppDispatch } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../../redux/states/root.state";
import CircularProgress from "@mui/material/CircularProgress";
import UserSelect from "../UserSelect/UserSelect";
import DealershipSelect from "../UserSelect/DealershipSelect";
import { ModalProps } from "../Interfaces/Modals";
import useRoleCheck from "../../../hooks/useRoleCheck";

const EditSpaModal: React.FC<ModalProps> = ({open, setOpen, object_id}) => {
    const id = object_id;
    
    const dispatch: AppDispatch = useDispatch();
    const [updateInfo, setUpdateInfo] = useState<any>(null);
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [selectedDealership, setSelectedDealership] = useState<any>(null);

    const canEditUser = useRoleCheck(['admin','developer']);

    const {data:spa, loading, error} = useSelector((state: RootState)=>state.spa)

    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        if (id && open){
            dispatch(fetchSpa(id));
        }
    }, [id,open, dispatch]);

    useEffect(() => {
        if (selectedDealership){
            setUpdateInfo({...updateInfo,dealership_id:selectedDealership.id});
        }
    }, [selectedDealership]);

    const handleClose = () => {
        setUpdateInfo(null);
        setSelectedUser(null);
        setSubmitted(false);
        setOpen(false);
        setSelectedDealership(null);
    };

    /* Handle text input */
    const handleInput = (e: any) => {
        let input = e.target.value;
        setUpdateInfo({...updateInfo,nick_name:input})
    };

    const handleEdit = async () => {
        setSubmitted(true);
        if (updateInfo){
            await dispatch(editSpa({...updateInfo,guid:spa?.id}));
        }

        if(selectedUser && !error){
            if (selectedUser!=='none'){
                await dispatch(registerSpaUser({guid:spa?.id, user_id:selectedUser.id}));
            } else {
                await dispatch(deregisterSpaUser({guid:spa?.id, user_id:selectedUser.id}));
            } 
        }
    }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
      >

        <div className={styles.modal}>
            <div className={styles.header}>
            {error ?
                <span>
                    <p>An Error Occurred</p>

                </span>
                : loading && submitted?
                    <p>Updating...</p>
                : loading && !submitted?
                    <p>Loading...</p>
                : !loading && submitted ?
                    <p> Success </p>
                :
                    <p> Edit Spa <b>{spa?.nick_name}</b> </p>
            }
                
            </div>
            <div className={styles.body}>
                {error ?
                    <p>
                        {error}
                    </p>
                :
                loading ?
                    <CircularProgress size={"24px"} />
                : submitted ?
                <p> Spa Successfully Updated. </p>
                :
                <div>
                    <form className={styles.forms}>
                        <label className={styles.formLabel}>Nick Name:</label>
                        <input type="text" id="nick-name-input" placeholder="Enter new nickname" className={styles.textInput} defaultValue={spa?.nick_name} onChange={handleInput}></input>
                    </form>

                    {canEditUser && <>
                        <br></br>
                        
                        <div className={styles.forms}>
                            <label className={styles.formLabel}>Change User: {spa?.user?spa?.user.name:`No current user.`}</label>
                        </div>
                        <UserSelect setSelectedUser={setSelectedUser} currentUser={spa?.user}/>
                    </>}

                    {canEditUser && <>
                        <br></br>
                        <div className={styles.forms}>
                            <label className={styles.formLabel}>Change Dealership: {spa?.dealership ? spa?.dealership.name:`No current dealership.`}</label>
                        </div>
                        <DealershipSelect setSelected={setSelectedDealership} current={spa?.dealership}/>
                    </>}
                    
                </div>
                }
            </div>
            {
                error||submitted  ?
                <div className="modal-btn-group">
                    <div></div>
                    <button className="as-text" onClick={handleClose}> OK </button>                        
            </div>
            : !submitted ?
            <div className="modal-btn-group">
                <button className="as-text" onClick={handleClose}> Cancel </button>                        
                <button className="as-button" onClick={handleEdit} disabled={loading || (!updateInfo && !selectedUser)}>Confirm</button>
            </div>
            :
            <></>
            }
        </div>
      </Modal>
    </div>
  );
};

export default EditSpaModal;
