import { createAsyncThunk } from '@reduxjs/toolkit';
import RootState from "../states/root.state";
import { getCountries, getCountry, getRegions } from '../../services/api/countries.api';

export const fetchCountries = createAsyncThunk<any, void, { rejectValue: string; state: RootState}>(
  'countries/list',
  async (_, {rejectWithValue, getState}) => {
      try {
        const {user} = getState();
        const token = user.accessToken;
  
        return await getCountries(token);

          
      } catch (error: any) {
          if (error.response) {
              return rejectWithValue(error.response.data.message);
          } else {
              return rejectWithValue('An error occurred during removal.');
          }
      }
  }
);

export const fetchCountry = createAsyncThunk<any, string, { rejectValue: string; state: RootState}>(
  'country',
  async (id, {rejectWithValue, getState}) => {
      try {
        const {user} = getState();
        const token = user.accessToken;
  
        return await getCountry(token, id);

      } catch (error: any) {
          if (error.response) {
              return rejectWithValue(error.response.data.message);
          } else {
              return rejectWithValue('An error occurred during removal.');
          }
      }
  }
);

export const fetchRegions = createAsyncThunk<any, string, { rejectValue: string; state: RootState}>(
  'country/regions',
  async (id, {rejectWithValue, getState}) => {
      try {
        const {user} = getState();
        const token = user.accessToken;
  
        return await getRegions(token, id);

      } catch (error: any) {
          if (error.response) {
              return rejectWithValue(error.response.data.message);
          } else {
              return rejectWithValue('An error occurred during removal.');
          }
      }
  }
);