import axios from 'axios';
import { BASE_URL } from './apiconfig';

export const getPermissions = async (token: string | null, params:string) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/admin/permissions?${params}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.log("Failed to fetch permissions list. ", error);
    throw error;
  }
}

export const getPermission = async (token: string | null, id:string) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/admin/permissions/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.log("Failed to fetch permission. ", error);
    throw error;
  }
};