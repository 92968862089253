import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import "./UserSelect.css"
import React, {useEffect, useState} from "react";
import RootState from "../../../redux/states/root.state";
import { CircularProgress, Pagination } from "@mui/material";
import { fetchDealerships } from "../../../redux/actions/dealership.actions";

interface UserSelectProps {
    setSelected: any;
    current: any;
}

const DealershipSelect: React.FC<UserSelectProps> = ({ setSelected, current }) => {
const dispatch: AppDispatch = useDispatch();
const {data:dealerships,meta,loading} = useSelector((state: RootState) => state.dealerships);
const [pageNumber, setPageNumber] = useState(1);
const [name, setName] = useState(``);
const [nameQuery, setNameQuery] = useState<any>(null);

useEffect(() => {
    setName(current?.name??'')
  }, [current]);

useEffect(() => {
    let query = `?page=${pageNumber}`;
    if (nameQuery){
        query = query + `&name=${nameQuery}`
    }
    dispatch(fetchDealerships(query))
  }, [dispatch,pageNumber, nameQuery]);

  return (
    <>
    <div className="user-search-container">
        <input type="search" placeholder="Search name..." onChange={(e)=>setName(e.target.value)} value={name}/>
        <button type="button" className="button" onClick={()=>setNameQuery(name)}>Search</button>
    </div>

        { loading ?
            <div className="no-users"><CircularProgress size={"24px"}/></div>
        :
        dealerships.length>0 ? 
        <div className="select-container">
            <div className="user-select">
                {dealerships.map((dealership:any)=>(
                    <div key={dealership?.id}>
                        <label>
                            <input type="radio" name="dealership-select" defaultChecked={dealership?.id===current?.id} onChange={()=>setSelected(dealership)}/> {dealership?.name!==``? `${dealership?.id} ${dealership?.name}`:`${dealership?.id}`} 
                        </label>
                    </div>
                ))}
            </div>
            {
            meta?.last_page>1 &&
            <Pagination 
                count={meta?.last_page} 
                size="small"
                onChange={(e,page)=>setPageNumber(page)}
                page={pageNumber}
                showLastButton 
                showFirstButton 
                disabled={meta?.last_page===1}
            />
            }
        </div>
        :
        <div>No Dealerships Found.</div>
    }
    </>
  );
};

export default DealershipSelect;
