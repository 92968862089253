import React, {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {
    TextField,
    Button,
    Box,
    Typography,
    Container,
    SxProps,
    Theme,
} from "@mui/material";
import { styled } from "@mui/system";
import {resetPassword} from "../../../redux/actions/user.actions";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../../../redux/store";
import RootState from "../../../redux/states/root.state";

const StyledContainer = styled(Container)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "32px",
    backgroundColor: "var(--as-white)",
    borderRadius: "4px",
});

const formStyles: SxProps<Theme> = (theme) => ({
    width: "100%",
    marginTop: theme.spacing(1),
});

const buttonStyles: SxProps<Theme> = (theme) => ({
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "var(--as-red)",
    "&:hover": {
        backgroundColor: "var(--as-red)",
    },
});

const ResetPassword: React.FC = () => {
    const [email, setEmail] = useState("");
    const [token, setToken] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const resetError = useSelector((state: RootState) => state.user.error);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get("token");
        const email = params.get("email");

        if (token) {
            setToken(token);
        }

        if(email){
            setEmail(email)
        }

    }, [location]);

    const handleResetPassword = async (e: React.FormEvent) => {
        e.preventDefault();
        const result = await dispatch(resetPassword({ email: email, password: password, password_confirmation: passwordConfirmation, token: token }));

        if (resetPassword.fulfilled.match(result)) {
            navigate("/login");
        }
    };

    return (
        <StyledContainer maxWidth="xs">
            <Typography component="h1" variant="h4" gutterBottom>
                Reset Password
            </Typography>
            <Box component="form" onSubmit={handleResetPassword} noValidate sx={formStyles}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{
                        "& .MuiInputLabel-root": {
                          color: "var(--as-gray)",
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: "var(--as-gray)",
                        },
                      }}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="New Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    sx={{
                        "& .MuiInputLabel-root": {
                          color: "var(--as-gray)",
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: "var(--as-gray)",
                        },
                      }}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password_confirmation"
                    label="Confirm New Password"
                    type="password"
                    id="password_confirmation"
                    value={passwordConfirmation}
                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                    sx={{
                        "& .MuiInputLabel-root": {
                          color: "var(--as-gray)",
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: "var(--as-gray)",
                        },
                      }}
                />
                {resetError && (
                    <Typography color="error" variant="body2" paragraph>
                        {Object.values(JSON.parse(resetError)).map((error:any)=>
                            <p>{error[0].toString()}</p>
                        )}
                    </Typography>
                )}
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={buttonStyles}
                >
                    Reset Password
                </Button>

                <Box mt={2} display="flex" justifyContent="center">
                    <Link to={"/login"} style={{color: "var(--as-blue)"}}>
                        {"Login"}
                    </Link>
                </Box>
            </Box>
        </StyledContainer>
    );
};

export default ResetPassword;
