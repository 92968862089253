import React, { useEffect, useState } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import SpasTable from "../../../components/common/Tables/SpasTable";
import { useSelector } from "react-redux";
import RootState from "../../../redux/states/root.state";
import { useSearchParams } from "react-router-dom";
import { SpaErrorLabels } from "../../../mqttData/errors/spaErrorLabels.enums";
import { ArcticLabels } from "../../SpaErrors/components/arcticLabels.enums";
import { formatErrorCode } from "../../SpaDetails/formatCodes";

interface ContainerProps {
    checked: any[];
    setChecked: any;
    setConfirmUpdate: any;
    setConfirmLogs: any;
    setDeleteOpen: any;
    setEditOpen:any;
    setAddCommandsOpen: any;
    setSpaToModify:any;
}


const SpasContainer: React.FC<ContainerProps> = ({
    checked,
    setChecked,
    setConfirmUpdate,
    setConfirmLogs,
    setDeleteOpen,
    setEditOpen,
    setAddCommandsOpen,
    setSpaToModify,
}) => {
  const {data: currentUser} = useSelector((state: RootState) => state.user);
  const {data: firmware} = useSelector((state: RootState) => state.firmware);
  const [isAdminDev, setIsAdminDev] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (currentUser){
      setIsAdminDev(currentUser.roles?.some((role: any) =>["admin","developer"].includes(role.name)) || false)
    }
  }, [currentUser]);

  // Search and Filter
  const [name, setName] = useState("");
  const [serial, setSerial] = useState("");
  const [ip, setIp] = useState("");
  const [email, setEmail] = useState("");
  const [online, setOnline] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [error, setError] = useState("");

  const [yoctoMin, setYoctoMin] = useState("");
  const [yoctoMax, setYoctoMax] = useState("");
  const [sbMin, setSBMin] = useState("");
  const [sbMax, setSBMax] = useState("");
  const [lpcMin, setLPCMin] = useState("");
  const [lpcMax, setLPCMax] = useState("");

  useEffect(() => {
    setName(searchParams.get('nick_name')??'');
    setSerial(searchParams.get('arctic_serial_number')??'');
    setIp(searchParams.get('ip_address')??'');
    setEmail(searchParams.get('user_email')??'');
    setError(searchParams.get('error')??'');
    setHasErrors(searchParams.get('has_errors')!==null);
    setOnline(searchParams.get('online')!==null);
    setRegistered(searchParams.get('registered')!==null);

    setYoctoMin(searchParams.get('yocto_min')??'');
    setYoctoMax(searchParams.get('yocto_max')??'');
    setSBMin(searchParams.get('sb_min')??'');
    setSBMax(searchParams.get('sb_max')??'');
    setLPCMin(searchParams.get('lpc_min')??'');
    setLPCMax(searchParams.get('lpc_max')??'');
  }, [searchParams]);

  const handleFilter = () => {
    searchParams.delete('page'); //reset page
    name==="" ? searchParams.delete('nick_name') : searchParams.set('nick_name', name);
    serial==="" ? searchParams.delete('arctic_serial_number') : searchParams.set('arctic_serial_number', serial);
    ip==="" ? searchParams.delete('ip_address') : searchParams.set('ip_address', ip);
    email==="" ? searchParams.delete('user_email') : searchParams.set('user_email', email);
    error==="" ? searchParams.delete('error') : searchParams.set('error', error);

    !online ? searchParams.delete('online') : searchParams.set('online', 'true');
    !registered ? searchParams.delete('registered') : searchParams.set('registered', 'true');
    !hasErrors ? searchParams.delete('has_errors') : searchParams.set('has_errors', 'true');

    yoctoMin==="" ? searchParams.delete('yocto_min') : searchParams.set('yocto_min', yoctoMin);
    yoctoMax==="" ? searchParams.delete('yocto_max') : searchParams.set('yocto_max', yoctoMax);
    sbMin==="" ? searchParams.delete('sb_min') : searchParams.set('sb_min', sbMin);
    sbMax==="" ? searchParams.delete('sb_max') : searchParams.set('sb_max', sbMax);
    lpcMin==="" ? searchParams.delete('lpc_min') : searchParams.set('lpc_min', lpcMin);
    lpcMax==="" ? searchParams.delete('lpc_max') : searchParams.set('lpc_max', lpcMax);

    setSearchParams(searchParams);
  }

  const handleClear = () => {
    setName("");
    searchParams.delete('nick_name');

    setSerial("");
    searchParams.delete('arctic_serial_number');

    setIp("");
    searchParams.delete('ip_address');

    setEmail("");
    searchParams.delete('user_email');

    setOnline(false);
    searchParams.delete('online');

    setRegistered(false);
    searchParams.delete('registered');

    setHasErrors(false);
    searchParams.delete('has_errors');

    setError("");
    searchParams.delete('error');

    setYoctoMin("");
    searchParams.delete('yocto_min');
    setYoctoMax("");
    searchParams.delete('yocto_max');

    setLPCMin("");
    searchParams.delete('lpc_min');
    setLPCMax("");
    searchParams.delete('lpc_max');
    
    setSBMin("");
    searchParams.delete('sb_min');
    setSBMax("");
    searchParams.delete('sb_max');

    setSearchParams(searchParams);
  }

  const getOnlineSelectedSpas = () => {
    return [...checked].filter((spa:any)=>spa?.status === 'connected');
  }

  return (
        <div className="list">
          <div className="filters">
            <div className="search">
                <TextField
                  id="name-search"
                  label="Nickname"
                  type="search"
                  fullWidth={true}
                  value={name??""}
                  onChange={(e)=>setName(e.target.value)}
                  onKeyDown={(e)=>(e.code==="Enter" ? handleFilter() : null)}
                  size="small"
                />

                <TextField
                  id="serial-search"
                  label="Serial Number"
                  type="search"
                  fullWidth={true}
                  value={serial??""}
                  onChange={(e)=>setSerial(e.target.value)}
                  onKeyDown={(e)=>(e.code==="Enter" ? handleFilter() : null)}
                  size="small"  
                />

                <TextField
                  id="ip-search"
                  label="IP Address"
                  type="search"
                  fullWidth={true}
                  value={ip??""}
                  onChange={(e)=>setIp(e.target.value)}
                  onKeyDown={(e)=>(e.code==="Enter" ? handleFilter() : null)}
                  size="small"
                />

                <TextField
                  id="email-search"
                  label="Email Address"
                  type="search"
                  fullWidth={true}
                  value={email??""}
                  onChange={(e)=>setEmail(e.target.value)}
                  onKeyDown={(e)=>(e.code==="Enter" ? handleFilter() : null)}
                  size="small"
                />
                <FormControl fullWidth size="small" disabled={!hasErrors}>
                  <InputLabel>Errors</InputLabel>
                  <Select
                    label="error-selection"
                    defaultValue="" 
                    disabled={!hasErrors}
                    onChange={(e)=>setError(e.target.value)}
                    value={error}
                  >
                    {Object.values(SpaErrorLabels).map((code,i)=>
                      ArcticLabels[code]!=="" &&
                        <MenuItem key={i} value={code}>{formatErrorCode(code)} {ArcticLabels[code]}</MenuItem>
                      )
                    }
                  </Select>
                </FormControl>
                <label><input type="checkbox" checked={hasErrors} onChange={(e)=>setHasErrors(e.target.checked)}></input>Has Errors</label>
              <hr></hr>
              <span>Yocto: </span>
              <div className="firmware-versions">
                <FormControl fullWidth size="small">
                  <InputLabel>Min</InputLabel>
                  <Select
                    label="yocto-selection-min"
                    defaultValue="" 
                    value={yoctoMin}
                    onChange={e => setYoctoMin(e.target.value)}
                  >
                    {firmware.filter((f:any)=>f.type==='yocto' && (yoctoMax!==''?f.version<=yoctoMax:true)).map((f:any)=>
                      <MenuItem value={f.version}>{f.version}</MenuItem>
                    )
                    }
                  </Select>
                </FormControl>

                <FormControl fullWidth size="small">
                  <InputLabel>Max</InputLabel>
                  <Select
                    label="yocto-selection-max"
                    defaultValue="" 
                    value={yoctoMax}
                    onChange={e => setYoctoMax(e.target.value)}
                  >
                    {firmware.filter((f:any)=>f.type==='yocto' && (yoctoMin!==''?f.version>=yoctoMin:true)).map((f:any)=>
                      <MenuItem value={f.version}>{f.version}</MenuItem>
                    )
                    }
                  </Select>
                </FormControl>
              </div>
              <span>LPC: </span>
              <div className="firmware-versions">
                <FormControl fullWidth size="small">
                  <InputLabel>Min</InputLabel>
                  <Select
                    label="lpc-selection-min"
                    defaultValue="" 
                    value={lpcMin}
                    onChange={e => setLPCMin(e.target.value)}
                  >
                    {firmware.filter((f:any)=>f.type==='lpc' && (lpcMax!==''?f.version<=lpcMax:true)).map((f:any)=>
                      <MenuItem value={f.version}>{f.version}</MenuItem>
                    )
                    }
                  </Select>
                </FormControl>

                <FormControl fullWidth size="small">
                  <InputLabel>Max</InputLabel>
                  <Select
                    label="lpc-selection-max"
                    defaultValue="" 
                    value={lpcMax}
                    onChange={e => setLPCMax(e.target.value)}
                  >
                    {firmware.filter((f:any)=>f.type==='lpc' && (lpcMin!==''?f.version>=lpcMin:true)).map((f:any)=>
                      <MenuItem value={f.version}>{f.version}</MenuItem>
                    )
                    }
                  </Select>
                </FormControl>
              </div>
              <span>SpaBoy: </span>
              <div className="firmware-versions">
                <FormControl fullWidth size="small">
                  <InputLabel>Min</InputLabel>
                  <Select
                    label="spaboy-selection-min"
                    defaultValue="" 
                    value={sbMin}
                    onChange={e => setSBMin(e.target.value)}
                  >
                    {firmware.filter((f:any)=>f.type==='spaboy' && (sbMax!==''?f.version<=sbMax:true)).map((f:any)=>
                      <MenuItem value={f.version}>{f.version}</MenuItem>
                    )
                    }
                  </Select>
                </FormControl>

                <FormControl fullWidth size="small">
                  <InputLabel>Max</InputLabel>
                  <Select
                    label="spaboy-selection-max"
                    defaultValue="" 
                    value={sbMax}
                    onChange={e => setSBMax(e.target.value)}
                  >
                    {firmware.filter((f:any)=>f.type==='spaboy' && (sbMin!==''?f.version>=sbMin:true)).map((f:any)=>
                      <MenuItem value={f.version}>{f.version}</MenuItem>
                    )
                    }
                  </Select>
                </FormControl>
              </div>
              <hr></hr>
              <div className="checkbox-filters">
                <label><input type="checkbox" checked={online} onChange={(e)=>setOnline(e.target.checked)}></input>Online</label>
                <label><input type="checkbox" checked={registered} onChange={(e)=>setRegistered(e.target.checked)}></input>Registered</label>
              </div>
              <hr></hr>
              <div className="filter-btn-group">
                <button onClick={handleFilter}>Apply Filters</button>
                <button onClick={handleClear}>Clear</button>
              </div>
            </div>
          </div>

          <div className="table-section">
            <SpasTable 
              setChecked={setChecked} 
              checked={checked}
              setDeleteOpen={setDeleteOpen}
              setEditOpen={setEditOpen}
              setSpaToModify={setSpaToModify}
            />
            <div className="filter-btn-group spa-actions">
              <button 
                disabled={getOnlineSelectedSpas().length===0} 
                onClick={()=>setConfirmUpdate(true)}>
                  Update Firmware {getOnlineSelectedSpas().length>0&&`(${getOnlineSelectedSpas().length})`}
                </button>

              <button 
                disabled={getOnlineSelectedSpas().length===0} 
                onClick={()=>setConfirmLogs(true)}>
                  Request Logs {getOnlineSelectedSpas().length>0&&`(${getOnlineSelectedSpas().length})`}
                </button>

              {isAdminDev && <button 
                disabled={checked.length===0} 
                onClick={()=>setAddCommandsOpen(true)}> 
                Send API Commands {checked.length>0&&`(${checked.length})`}
              </button>}
            </div>
          </div>
        </div>
)
};

export default SpasContainer;