import axios from "axios";
import { BASE_URL } from "./apiconfig";
import {requestLogsResponse} from "../../redux/actions/logsRequest.actions";

export const requestLogsApi = async (token: string | null, spaInfo : {guid: string}): Promise<requestLogsResponse> => {
    try {
        const response = await axios.post(`${BASE_URL}/logs/request`, {
            spa_guid: spaInfo.guid
        },{
            headers: { Authorization: `Bearer ${token}` }
        });

        return response.data;
    } catch (error) {
        console.log("Failed to request logs. ", error);
        throw error;
    }
};