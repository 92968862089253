import axios from "axios";
import { BASE_URL } from "./apiconfig";

export const getHistory = async (token: string | null, spaInfo : {guid: string, date_from: number, date_to: number, keys: any[], type: string}) => {
    try {
        const response = await axios.post(`${BASE_URL}/spas/history`, {
            spa_id: spaInfo.guid,
            date_from: spaInfo.date_from,
            date_to: spaInfo.date_to,
            keys: spaInfo.keys,
            type: spaInfo.type,
        },{
            headers: { Authorization: `Bearer ${token}` }
        });
      return response.data;
    } catch (error) {
      console.log(`Failed to pull ${spaInfo.type} history.`, error);
      throw error;
    }
}
