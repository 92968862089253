export enum ArcticLabels {
    ERR0="NO FLOW",
    ERR1="FLOW SWITCH",
    ERR2="HEATER OVER TEMPERATURE",
    ERR3="SPA OVER TEMPERATURE",
    ERR4="SPA TEMPERATURE PROBE",
    ERR5="SPA HIGH LIMIT",
    ERR6="SEEPROM ERROR",
    ERR7="FREEZE PROTECT",
    ERR8="PH HIGH",
    ERR9="HEATER PROBE DISCONNECTED",
    ERR10="",
    ERR11="SPABOY COMM ERROR",
    ERR12="",
    ERR13="HEATER WAY ABOVE WATER TEMP",
    ERR14="ORP NOT RESPONDING TO PRODUCTION",
    ERR15="PH TOO LOW (<6.5)",
    ERR16="",
    ERR17="",
    ERR18="",
    ERR19="",
    ERR20="",
    ERR21="",
    ERR22="",
    ERR23="",
    ERR24="",
    ERR25="",
    ERR26="",
    ERR27="",
    ERR28="",
    ERR29="",
    ERR30="",
    ERR31="",
    ERR32="",
    ERR33="",
    ERR34="",
    ERR35="",
    ERR36="",
    ERR37="",
    ERR38="",
    ERR39="",
    ERR40="",
    ERR41="",
    ERR42="",
    ERR43="",
    ERR44="",
    ERR45="",
    ERR46="",
    ERR47="",
    ERR48="",
    ERR49="",
    ERR50="",
    ERR51="",
    ERR52="",
    ERR53="",
    ERR54="",
    ERR55="",
    ERR56="",
    ERR57="",
    ERR58="",
    ERR59="",
    ERR60="",
    ERR61="",
    ERR62="",
    ERR63="",

    STAT0="",
    STAT1="",
    STAT2="",
    STAT3="",
    STAT4="",
    STAT5="",
    STAT6="",
    STAT7="",
    STAT8="",
    STAT9="",
    STAT10="HEATER PROBE TEST FAILED",
    STAT11="",
    STAT12="HEATER SPA MISMATCH",
    STAT13="",
    STAT14="",
    STAT15="",
    STAT16="PH PUMP NONRESPONSIVE",
    STAT17="TARGET TEMPERATURE REACHED",
    STAT18="",
    STAT19="",
    STAT20="NO CURRENT PUMP1L",
    STAT21="NO CURRENT PUMP1H",
    STAT22="NO CURRENT PUMP2",
    STAT23="NO CURRENT PUMP3",
    STAT24="NO CURRENT PUMP4",
    STAT25="NO CURRENT YESS",
    STAT26="NO CURRENT ONZEN",
    STAT27="NO CURRENT HEATER1",
    STAT28="NO CURRENT HEATER2",
    STAT29="",
    STAT30="",
    STAT31="",
    STAT32="",
    STAT33="PUMP1 START CAP FAILED",
    STAT34="PUMP1H START CAP FAILED",
    STAT35="PUMP2 START CAP FAILED",
    STAT36="PUMP3 START CAP FAILED",
    STAT37="PUMP4 START CAP FAILED",
    STAT38="YESS START CAP FAILED",
    STAT39="ONZEN START CAP FAILED",
    STAT40="",
    STAT41="",
    STAT42="",
    STAT43="",
    STAT44="",
    STAT45="",
    STAT46="",
    STAT47="",
    STAT48="",
    STAT49="",
    STAT50="",
    STAT51="",
    STAT52="",
    STAT53="",
    STAT54="SPABOY ORP TIMEOUT",
    STAT55="",
    STAT56="",
    STAT57="",
    STAT58="",
    STAT59="PCBID0",
    STAT60="PCBID1",
    STAT61="PCBID2",
    STAT62="PCBID3",
    STAT63="PCBID4",
}