import styles from "../../../../assets/scss/Modal.module.scss";
import React, {useState} from "react";
import {Modal,CircularProgress} from '@mui/material';
import { AppDispatch } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../../../redux/states/root.state";
import { useParams } from "react-router-dom";
import { assignUserGroupPermissions } from "../../../../redux/actions/userGroups.actions";
import MultiSelectPermissions from "../MultiSelectPermissions";

interface ModalProps {
    isOpen: boolean, 
    setOpen: any,
}

const AddPermissionsModal: React.FC<ModalProps> = ({isOpen, setOpen}) => {
    const dispatch: AppDispatch = useDispatch();
    const {id} = useParams();

    const {loading, error} = useSelector((state: RootState) => state.userGroup);
    const [selected, setSelected] = useState<any[]>([]);

    const [fulfilled, setFulfilled] = useState(false);
    const [rejected, setRejected] = useState(false);

    const handleClose = () => {
        handleReset();
        setOpen(false);
    };

    const handleReset = () => {
        setSelected([]);
        setFulfilled(false);
        setRejected(false);
    }

    const handleAdd = async () => {
        if(id && selected.length>0){
            const response = await dispatch(
                assignUserGroupPermissions({id:id, permissions:selected})
            );

            switch(response.type){
                case `user-group/assign-permissions/fulfilled`:
                    setFulfilled(true);
                    break;

                case `user-group/assign-permissions/rejected`:
                    setRejected(true);
                    break;
            }
        }
    };

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
        >
        <div className={styles.modal}>
            <div className={styles.header}>
                {loading ?
                    <p>Adding...</p>
                :
                rejected && error ?
                    <p>An Error Occurred</p>
                :
                fulfilled ?
                    <p>Success</p>
                :
                    <p>Add permissions to user group</p>
                }
            </div>
            <div className={styles.body}>
                {loading ?
                    <CircularProgress size={"24px"} />
                :
                rejected && error ?
                    <p>{error}</p>
                :
                fulfilled ?
                <p>Permissions successfully added to user group.</p>
                :
                <div className={styles.body}>
                <div className="spas-title">{`Add ${selected.length} ${selected.length===1?`permission`:`permissions`}.`}</div>
                    <MultiSelectPermissions checked={selected} setChecked={setSelected}/>
                  <br></br>
                  <button className="button request" disabled={selected.length===0} onClick={handleAdd}>Add</button>
                </div>
                }
            </div>

            {
            !loading && !fulfilled?
            <div className="modal-btn-group">
                <button className="as-text" onClick={handleClose}> Close </button>                        
            </div>
            :
            fulfilled ?
            <div className="modal-btn-group">
                <button className="as-text" onClick={handleClose}> Close </button>    
                <button className="as-text" onClick={handleReset}> Add More </button>                        
            </div>
            :
            <></>
            }
        </div>
      </Modal>
    </div>
  );
};

export default AddPermissionsModal;
