import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import CountryState from '../states/country.state';
import { fetchCountry } from '../actions/countries.actions';

const initialState: CountryState = {
    loading: false,
    data: [],
    error: null,
};

const countrySlice = createSlice({
    name: 'country',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCountry.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCountry.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchCountry.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload ?? null;
            });
    },
});

export default countrySlice.reducer;