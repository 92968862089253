import React, { useEffect, useState } from "react";
import {
  TextField,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import SpaCommandsTable from "../../SpaReport/components/SpaCommandsTable";

const SpaCommandsContainer: React.FC = () => {
  //Filters
  const [title, setTitle] = useState(``);
  const [searchParams, setSearchParams] = useSearchParams();
  
  useEffect(() => {
    setTitle(searchParams.get('title')??'')
  }, [searchParams]);

  const handleFilter = () => {
    searchParams.delete('page'); //reset page
    title==="" ? searchParams.delete('title') : searchParams.set('title', title);
    setSearchParams(searchParams);
  }

  const handleClear = () => {
    searchParams.delete('page'); //reset page
    setTitle("");
    searchParams.delete('title');
    setSearchParams(searchParams);
  }

  return (
    <div className="container">
        <div className="list">
          <div className="filters">
            <div className="search">
              <TextField
                id="title-search"
                label="Search Title..."
                type="search"
                fullWidth={true}
                value={title??""}
                onChange={(e)=>setTitle(e.target.value)}
                size="small"
              />

              <div className="filter-btn-group">
                <button onClick={handleFilter}>Apply Filters</button>
                <button onClick={handleClear}>Clear</button>
              </div>
            </div></div>

          <div className="table-section">  
            <SpaCommandsTable/>
          </div>   
        </div>
      </div>      
  );
};

export default SpaCommandsContainer;
