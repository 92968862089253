import "./SpasPage.css"
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { AppDispatch } from "../../redux/store";
import { fetchSpas} from "../../redux/actions/spas.actions";
import RootState from "../../redux/states/root.state";
import Header from "../../components/common/Header/Header";
import FirmwareUpdateModal from "../../components/common/Modals/FirmwareUpdateModal";
import RequestLogsModal from "../../components/common/Modals/RequestLogsModal";
import { fetchAvailableFirmware } from "../../redux/actions/firmware.actions";
import SpasContainer from "./components/SpasContainer";
import SingleUpdateModal from "../../components/common/Modals/SingleUpdateModal";
import DeleteSpaModal from "../../components/common/Modals/DeleteSpaModal";
import EditSpaModal from "../../components/common/Modals/EditSpaModal";
import ConfirmFirmwareModal from "../../components/common/Modals/ConfirmFirmwareModal";
import ConfirmLogsModal from "../../components/common/Modals/ConfirmLogsModal";
import AddSpaCommandsModal from "./components/AddSpaCommandsModal";
import { fetchUserData } from "../../redux/actions/user.actions";

const SpasPage: React.FC = () => {
  // Modals
  const [confirmUpdate, setConfirmUpdate] = useState(false);
  const [confirmLogs, setConfirmLogs] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [singleUpdateOpen, setSingleUpdateOpen] = useState(false);
  const [requestOpen, setRequestOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [addCommandsOpen, setAddCommandsOpen] = useState(false);

  const [spaToModify, setSpaToModify] = useState<any>(null);

  // Data
  const dispatch: AppDispatch = useDispatch();
  const {data: spas} = useSelector((state: RootState) => state.spas);
  const {data: currentUser} = useSelector((state: RootState) => state.user);
  const searchParams = useSearchParams()[0];
  const [isAdminDev, setIsAdminDev] = useState(false);

  useEffect(() => {
    dispatch(fetchSpas(searchParams.toString()));
    dispatch(fetchAvailableFirmware(""));
    dispatch(fetchUserData());
  }, [dispatch, searchParams]);

  useEffect(() => {
    if (currentUser){
      setIsAdminDev(currentUser.roles?.some((role: any) =>["admin","developer"].includes(role.name)) || false)
    }
  }, [currentUser]);

  // User Input
  const [firmware, setFirmware] = useState("");
  const [version, setVersion] = useState("");

  // Checked items
  const [checked, setChecked] = useState<any[]>([]);
  
  
  const getOnlineSelectedSpas = () => {
    return [...checked].filter((spa:any)=>spa?.status === 'connected');
  }

  return (
    <>
      {confirmUpdate && <ConfirmFirmwareModal 
        isOpen={confirmUpdate} 
        setOpen={setConfirmUpdate} 
        setOpenSingle={setSingleUpdateOpen}
        setOpenMultiple={setUpdateOpen}
        setFirmware={setFirmware}
        setVersion={setVersion}
        spas={getOnlineSelectedSpas()}
      />
      }

      {confirmLogs && <ConfirmLogsModal
        isOpen={confirmLogs} 
        setOpen={setConfirmLogs}
        setOpenRequest={setRequestOpen}
        spas={getOnlineSelectedSpas()}
      />
      }

      {updateOpen && <FirmwareUpdateModal 
        isOpen={updateOpen} 
        setOpen={setUpdateOpen} 
        spas={getOnlineSelectedSpas()} 
        firmwareType={firmware} 
        version={version}
      />}

      {singleUpdateOpen && <SingleUpdateModal 
        isOpen={singleUpdateOpen} 
        setOpen={setSingleUpdateOpen} 
        id={getOnlineSelectedSpas()[0]?.id} 
        firmwareType={firmware} 
        version={version} />}

      {requestOpen && <RequestLogsModal 
        isOpen={requestOpen} 
        setOpen={setRequestOpen} 
        spas={getOnlineSelectedSpas()}
      />}
      
      {deleteOpen && <DeleteSpaModal 
        open={deleteOpen} 
        setOpen={setDeleteOpen} 
        object={spaToModify} 
      />}

      {editOpen && <EditSpaModal 
        open={editOpen} 
        setOpen={setEditOpen} 
        object_id={spaToModify?.id}
      />}

      {addCommandsOpen && isAdminDev && <AddSpaCommandsModal 
        open={addCommandsOpen} 
        setOpen={setAddCommandsOpen} 
        selection={checked}  
      />}
      
      <Header/>
      <div className="list-container">
        <div className="list-header">

          <div className="list-summary">
            <div><b>Users</b> {[...spas].map(spa => spa.user_id).filter((user_id, i, self) => self.indexOf(user_id) === i).length}</div>
            <div><b>Online Spas</b>{[...spas].filter((spa)=>spa.status === 'connected').length}</div>
            <div><b>Offline Spas</b> {[...spas].filter((spa)=>spa.status !== 'connected').length}</div>
          </div>
        </div>

        <SpasContainer 
          checked={checked}
          setChecked={setChecked}
          setConfirmUpdate={setConfirmUpdate}
          setConfirmLogs={setConfirmLogs}
          setDeleteOpen={setDeleteOpen}
          setEditOpen={setEditOpen}
          setAddCommandsOpen={setAddCommandsOpen}
          setSpaToModify={setSpaToModify}
        />
      </div>   
    </>
  );
};

export default SpasPage;
