import styles from "../../../assets/scss/Modal.module.scss";
import React, { useEffect, useState } from "react";
import {CircularProgress, Modal} from '@mui/material';
import { AppDispatch } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { editSpa } from "../../../redux/actions/spa.actions";
import RootState from "../../../redux/states/root.state";
import { ModalProps } from "../../../components/common/Interfaces/Modals";


const EditSerialModal: React.FC<ModalProps> = ({open, setOpen}) => {
    const dispatch: AppDispatch = useDispatch();
    const {data:spa, loading, error} = useSelector((state: RootState) => state.spa);
    const [input, setInput] = useState(``);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        if (spa && open) {
          setInput(spa.arctic_serial_number??'');
        }
    }, [spa, open])

    const handleClose = () => {
      setInput(``);
      setSuccess(false);
      setOpen(false);
    };

    /* Handle text input */
    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        let input = e.target.value;
        setInput(input);
    };

    const handleEdit = async () => {
        await dispatch(editSpa({guid:spa?.id,arctic_serial_number:input}));
        if (!error) {
          setSuccess(true);
        }
    }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <div className={styles.modal}>
            <div className={styles.header}>
                {loading ?
                  <p><strong>Updating Spa...</strong></p>
                  :
                  error ?
                  <p><strong>An Error Occured</strong></p>
                  :
                  success ?
                  <p><strong>Spa Update Successful</strong></p>
                  :
                  <p><strong>Set Spa Serial Number</strong></p>
              }
            </div>
            <div className={styles.body}>
            {loading ?
                  <CircularProgress size={"24px"} />
                  :
                  error ?
                    <p>{error}</p>
                  :
                  success ?
                    <p>Spa's serial number was successfully updated.</p>
                  :
                    <form className={styles.forms}>
                      <label className={styles.formLabel}>Spa Serial Number:</label>
                      <input type="number" id="input" className={styles.textInput} value={input} onChange={handleInput}></input>
                    </form>
              }
                
            </div>
            {loading ?
                  <></>
                  :
                  error||success ?
                    <div className="modal-btn-group">
                      <div></div>
                      <button className="as-text" onClick={handleClose}> OK </button>
                    </div>
                  :
                    <div className="modal-btn-group">
                      <button className="as-text" onClick={handleClose}> Cancel </button>
                      <button className="as-button" onClick={handleEdit}> Confirm </button>
                    </div>
              }

        </div>
      </Modal>
    </div>
  );
};

export default EditSerialModal;
