import axios from 'axios';
import { BASE_URL } from './apiconfig';

export const getComments = async (token: string | null, params:string) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/comments?${params}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.log("Failed to pull comments list. ", error);
    throw error;
  }
}

export const getComment = async (token: string | null, id:string) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/comments/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return data;
  } catch (error) {
    console.log("Failed to fetch comment. ", error);
    throw error;
  }
}

export const postComment = async (token: string | null, updateInfo:{ title:string, category:string, body:string, spa_id:string, user_id:string}) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/comments`,{
      title: updateInfo.title,
      category: updateInfo.category,
      body: updateInfo.body,
      spa_id: updateInfo.spa_id,
      user_id: updateInfo.user_id,
    },{
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.log("Failed to post comment. ", error);
    throw error;
  }
}

export const putComment = async (token: string | null, updateInfo:{id:string, title:string, category:string, body:string, is_resolved:any}) => {
  try {
    const { data } = await axios.put(`${BASE_URL}/comments/${updateInfo.id}`,{
      title: updateInfo.title,
      category: updateInfo.category,
      body: updateInfo.body,
      is_resolved: updateInfo.is_resolved
    },{
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.log("Failed to update comment. ", error);
    throw error;
  }
}

export const resolveCommentApi = async (token: string | null, updateInfo:{id:string, is_resolved:any}) => {
  try {
    const { data } = await axios.put(`${BASE_URL}/comments/${updateInfo.id}`,{
      is_resolved: updateInfo.is_resolved
    },{
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.log("Failed to update comment. ", error);
    throw error;
  }
}

export const readComment = async (token: string | null, updateInfo:{id:string}) => {
  try {
    const { data } = await axios.put(`${BASE_URL}/comments/${updateInfo.id}/read`,{},{
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.log("Failed to mark as read. ", error);
    throw error;
  }
}

export const deleteCommentApi = async (token: string | null, id:string) => {
  try {
    const { data } = await axios.delete(`${BASE_URL}/comments/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.log("Failed to delete spa's comments. ", error);
    throw error;
  }
}

export const getSpaComments = async (token: string | null, request:{id:string,params:string}) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/spas/${request.id}/comments?${request.params}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.log("Failed to fetch spa's comments. ", error);
    throw error;
  }
}