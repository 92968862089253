import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import CommentsState from '../states/comments.state';
import { addComment, deleteComment, fetchComments, fetchSpaComments, markAsRead, resolveComment, updateComment } from '../actions/comments.actions';

const initialState: CommentsState = {
  loading: false,
  data: [],
  error: null,
  meta: null,
};

const CommentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchComments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchComments.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchComments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      })
      .addCase(fetchSpaComments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSpaComments.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchSpaComments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      })
      
      .addCase(addComment.pending, (state) => {
          state.loading = true;
          state.error = null;
      })
      .addCase(addComment.fulfilled, (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.data = [action.payload,...state.data];
      })
      .addCase(addComment.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload ?? null;
      })

      .addCase(deleteComment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteComment.fulfilled, (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.data = [...state.data].filter((comment:any)=>{return comment?.id!==action.payload});
      })
      .addCase(deleteComment.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload ?? null;
      })

      .addCase(updateComment.pending, (state) => {
          state.error = null;
      })
      .addCase(updateComment.fulfilled, (state, action: PayloadAction<any>) => {
          state.data = [...state.data].map((comment:any)=>comment?.id===action.payload?.id ? action.payload : comment);
      })
      .addCase(updateComment.rejected, (state, action) => {
          state.error = action.payload ?? null;
      })

      .addCase(resolveComment.pending, (state) => {
          state.error = null;
      })
      .addCase(resolveComment.fulfilled, (state, action: PayloadAction<any>) => {
          state.data = [...state.data].map((comment:any)=>comment?.id===action.payload?.id ? action.payload : comment);
      })
      .addCase(resolveComment.rejected, (state, action) => {
          state.error = action.payload ?? null;
      })
      
      .addCase(markAsRead.pending, (state) => {
          state.error = null;
      })
      .addCase(markAsRead.fulfilled, (state, action: PayloadAction<any>) => {
          state.data = [...state.data].map((comment:any)=>comment?.id===action.payload?.id ? action.payload : comment);
      })
      .addCase(markAsRead.rejected, (state, action) => {
          state.error = action.payload ?? null;
      });
  },
});

export default CommentsSlice.reducer;