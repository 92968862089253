import React, { useEffect } from "react";
import logo from "../../assets/logos/logo.png";
import loginStyles from "../../assets/scss/Login.module.scss"
import ResetPasswordForm from "./components/ResetPasswordForm";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { fetchUserData } from "../../redux/actions/user.actions";

const ResetPasswordPage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
  
    useEffect(() => {
        dispatch(fetchUserData());
    }, [dispatch]);

    return (
        <>
            <div className={loginStyles.homeScreen}>
                <img
                    src={logo}
                    alt="Logo"
                    style={{ height: "7rem", margin: "0 0 4rem 0" }}
                />

                <ResetPasswordForm />
            </div>
        </>
    );
};

export default ResetPasswordPage;
