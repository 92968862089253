import React, { useEffect, useState } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import DealershipsTable from "./DealershipsTable";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../../redux/states/root.state";
import { AppDispatch } from "../../../redux/store";
import { fetchRegions } from "../../../redux/actions/countries.actions";
import { useSearchParams } from "react-router-dom";
import useRoleCheck from "../../../hooks/useRoleCheck";

const DealershipsContainer: React.FC<{setDeleteOpen:any, setEditOpen:any,setAddOpen:any, setDealershipToModify:any}> = ({setDeleteOpen,setEditOpen, setAddOpen,setDealershipToModify}) => {
  const dispatch: AppDispatch = useDispatch();
  //Filters
  const {data:countries} = useSelector((state: RootState) => state.countries);
  const {data:regions} = useSelector((state: RootState) => state.regions);
  const [searchParams, setSearchParams] = useSearchParams();

  const isAdmin = useRoleCheck(['admin','developer']);

  const [name, setName] = useState('');
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');

  useEffect(() => {
    if (country!=='') {
      dispatch(fetchRegions(country));
    }
  }, [dispatch,country]);
 
  const handleSelect = (event: SelectChangeEvent, selectField: string) => {
    switch (selectField){
      case 'country':
        setCountry(event.target.value as string);
        setRegion('');
        break;
      case 'region':
        setRegion(event.target.value as string);
        break;
    }
  };

  const handleFilter = () => {
    name==="" ? searchParams.delete('name') : searchParams.set('name', name);
    country==="" ? searchParams.delete('country') : searchParams.set('country', country);
    region==="" ? searchParams.delete('region') : searchParams.set('region', region);
    setSearchParams(searchParams);
  }

  const handleClear = () => {
    setName("");
    searchParams.delete('name');
    setCountry("");
    searchParams.delete('country');
    setRegion("");
    searchParams.delete('region');
    setSearchParams(searchParams);
  }

  return (
    <>
    <div className="container">
        <div className="list">
          <div className="filters">
            <div className="search">
              <TextField
                id="name-search"
                label="Name"
                type="search"
                fullWidth={true}
                onChange={(e)=>setName(e.target.value)}
                onKeyDown={(e)=>(e.code==="Enter" ? handleFilter() : null)}
                size="small"
              />

              <FormControl fullWidth size="small">
                <InputLabel>Country</InputLabel>
                <Select
                  label="Country"
                  value={country}
                  onChange={e => handleSelect(e,'country')}
                >
                  <MenuItem value={''}>All</MenuItem>
                  { countries?.length>0 &&
                    countries.map((country:any)=>
                      <MenuItem key={country?.id} value={country?.id}>{country?.label}</MenuItem>
                    )
                  }
                </Select>
              </FormControl>

              <FormControl fullWidth size="small">
                <InputLabel>{country==='' || regions.length>0?`Region`:`No Regions Found.`}</InputLabel>
                <Select
                  value={region}
                  onChange={e => handleSelect(e,'region')}
                  disabled={regions.length===0}
                >
                  <MenuItem value={''}>All</MenuItem>
                  { regions?.length>0 && country!=='' &&
                    regions.map((region:any)=>
                      <MenuItem key={region?.id} value={region?.id}>{region?.label}</MenuItem>
                    )
                  }
                </Select>
              </FormControl>

              <div className="filter-btn-group">
                <button onClick={handleFilter}>Apply Filters</button>
                <button onClick={handleClear}>Clear</button>
              </div>
            </div></div>

            
          <div className="table-section">
            <DealershipsTable setDeleteOpen={setDeleteOpen} setEditOpen={setEditOpen} setDealershipToModify={setDealershipToModify}/>
            <div className="filter-btn-group spa-actions">
                {isAdmin && <button onClick={()=>{setAddOpen(true)}}>Add Dealership</button>}
            </div>
          </div>   
        </div>
      </div>   
    </>   
  );
};

export default DealershipsContainer;
