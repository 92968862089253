import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import CommandsState from '../states/commands.state';
import { addCommand, deleteCommand, editCommand, fetchCommands, fetchSpaCommands, fetchSpaCommandsHistory } from '../actions/commands.actions';

const initialState: CommandsState = {
  loading: false,
  data: [],
  error: null,
  meta: null,
};

const commandsSlice = createSlice({
  name: 'commands',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCommands.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCommands.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchCommands.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      })

      .addCase(fetchSpaCommands.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSpaCommands.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchSpaCommands.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      })

      .addCase(fetchSpaCommandsHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSpaCommandsHistory.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchSpaCommandsHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      })

      .addCase(addCommand.pending, (state) => {
          state.loading = true;
          state.error = null;
      })
      .addCase(addCommand.fulfilled, (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.data = [action.payload,...state.data];
      })
      .addCase(addCommand.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload ?? null;
      })

      .addCase(deleteCommand.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCommand.fulfilled, (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.data = [...state.data].filter((command:any)=>{return command?.id!==action.payload});
      })
      .addCase(deleteCommand.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload ?? null;
      })
      .addCase(editCommand.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editCommand.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = [...state.data].map((command:any)=>command?.id===action.payload?.id ? action.payload : command);
      })
      .addCase(editCommand.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      });
  },
});

export default commandsSlice.reducer;