import './ManageFirmwaresPage.css';
import React, {useState, useEffect} from "react";
import { useSearchParams } from "react-router-dom";
import Header from "../../components/common/Header/Header";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import FirmwareTable from "./components/FirmwareTable";
import UploadFirmwareModal from "./components/UploadFirmwareModal";
import { fetchAvailableFirmware } from "../../redux/actions/firmware.actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import UpdateFirmwareInfoModal from "./components/UpdateFirmwareInfoModal";
import DeleteFirmwareModal from './components/DeleteFirmwareModal';
import FirmwareDetailsModal from './components/FirmwareDetailsModal';

const ManageFirmwaresPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams, setSearchParams] = useSearchParams();

  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [firmwareToModify, setFirmwareToModify] = useState(null);

  const [type, setType] = useState('');

  useEffect(() => {
    dispatch(fetchAvailableFirmware(searchParams.toString()))
  }, [dispatch,searchParams]);


  const handleFilter = () => {
    searchParams.delete('page'); //reset page
    type==="" ? searchParams.delete('type') : searchParams.set('type', type);
    setSearchParams(searchParams);
  }
  
  const handleClear = () => {
    setType("");
    searchParams.delete('type');
    searchParams.delete('page'); //reset page
    setSearchParams(searchParams);
  }

  return (
    <>
    <UpdateFirmwareInfoModal 
      open={editOpen} 
      setOpen={setEditOpen} 
      object={firmwareToModify} 
    />

    <DeleteFirmwareModal 
      open={deleteOpen} 
      setOpen={setDeleteOpen} 
      object={firmwareToModify} 
    />

    <UploadFirmwareModal 
      open={uploadOpen} 
      setOpen={setUploadOpen} 
    />

    <FirmwareDetailsModal
      open={viewOpen} 
      setOpen={setViewOpen} 
      object={firmwareToModify} 
    />

    <Header/>
    <div className="container">
        <div className="list">
          <div className="filters">
            <div className="search">
              <FormControl fullWidth size="small">
                <InputLabel>Firmware</InputLabel>
                <Select
                  label="Firmware"
                  defaultValue=""
                  onChange={(e)=>setType(e.target.value)}
                >
                  <MenuItem value={'yocto'}>Yocto</MenuItem>
                  <MenuItem value={'lpc'}>LPC</MenuItem>
                  <MenuItem value={'spaboy'}>SpaBoy</MenuItem>
                </Select>
              </FormControl>

              <div className="filter-btn-group">
                <button onClick={handleFilter}>Apply Filters</button>
                <button onClick={handleClear}>Clear</button>
              </div>
            </div>
          

            <div className="firmware-updates">
              <div className="filter-btn-group">
                <button onClick={()=>setUploadOpen(true)}>Upload Firmware</button>
              </div>
            </div>

          </div> 
          <FirmwareTable 
            setEditOpen={setEditOpen}
            setDeleteOpen={setDeleteOpen}
            setViewOpen={setViewOpen}
            setFirmwareToModify={setFirmwareToModify}
          />
        </div>
      </div>      
    </>
  );
};

export default ManageFirmwaresPage;
