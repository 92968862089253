import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import "./UserSelect.css"
import React, {useEffect, useState} from "react";
import RootState from "../../../redux/states/root.state";
import { fetchUsers } from "../../../redux/actions/users.actions";
import { CircularProgress, Pagination } from "@mui/material";

interface UserSelectProps {
    checked:any[];
    setChecked: any;
}

const MultiSelect: React.FC<UserSelectProps> = ({
    checked,
    setChecked,
}) => {
const dispatch: AppDispatch = useDispatch();
const {data:users,meta,loading} = useSelector((state: RootState) => state.users);

const [pageNumber, setPageNumber] = useState(1);
const [email, setEmail] = useState(``);
const [emailQuery, setEmailQuery] = useState<any>(null);

const handleCheck = (user:any) => {
    let newArr = [...checked];
    let found = checked?.find((checked_user:any)=>user?.id===checked_user?.id)!==undefined;
    if(found){
      newArr = newArr.filter((checked_user:any)=>checked_user?.id!==user?.id);
    } else {
      newArr.push(user);
    }
    setChecked(newArr);
}

useEffect(() => {
    let query = `?page=${pageNumber}`;


    if (emailQuery){
        query = query + `&email=${emailQuery}`
    }

    dispatch(fetchUsers(query));

  }, [dispatch,pageNumber, emailQuery]);

  return (
    <>
    <div className="user-search-container">
        <input type="search" placeholder="Search email..." onChange={(e)=>setEmail(e.target.value)}/>
        <button type="button" onClick={()=>setEmailQuery(email)}>Search</button>
    </div>

    {loading ?
        <div className="no-users"><CircularProgress size={"24px"}/></div>
        :
    users.length>0 ? 
        <div className="select-container">
            <div className="user-select">
                {users.map((user:any)=>(
                    <div key={user.id}>
                        <label>
                            <input type="checkbox" name="user-select" onChange={()=>handleCheck(user)}/> {user?.name!==``? `${user?.name} (${user?.id})`:`${user?.id}`} 
                        </label>
                    </div>
                ))}
            </div>
            {meta?.last_page>1 && <Pagination 
                count={meta?.last_page} 
                size="small"
                onChange={(e,page)=>setPageNumber(page)}
                page={pageNumber}
                showLastButton 
                showFirstButton 
                disabled={meta?.last_page===1}
            />}
        </div>
        :
        <div>No Users Found.</div>
    }
    </>
  );
};

export default MultiSelect;
