import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createDealership, deleteDealership, fetchDealerships, updateDealership } from '../actions/dealership.actions';
import DealershipsState from '../states/dealerships.state';

const initialState: DealershipsState = {
  loading: false,
  data: [],
  error: null,
  meta: null,
};

const DealershipsSlice = createSlice({
  name: 'dealerships',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDealerships.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDealerships.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchDealerships.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      })

      .addCase(createDealership.pending, (state) => {
        state.error = null;
      })
      .addCase(createDealership.fulfilled, (state, action: PayloadAction<any>) => {
        state.data = [action.payload,...state.data];
      })
      .addCase(createDealership.rejected, (state, action) => {
        state.error = action.payload ?? null;
      })

      .addCase(deleteDealership.pending, (state) => {
          state.error = null;
      })
      .addCase(deleteDealership.fulfilled, (state, action: PayloadAction<any>) => {
          state.data = [...state.data].filter((dealership:any)=>{return dealership?.id!==action.payload});
      })
      .addCase(deleteDealership.rejected, (state, action) => {
          state.error = action.payload ?? null;
      })

      .addCase(updateDealership.pending, (state) => {
        state.error = null;
      })
      .addCase(updateDealership.fulfilled, (state, action: PayloadAction<any>) => {
        state.data = [...state.data].map((dealership:any)=>dealership?.id===action.payload?.id ? action.payload : dealership);
      })
      .addCase(updateDealership.rejected, (state, action) => {
        state.error = action.payload ?? null;
      });
  },
});

export default DealershipsSlice.reducer;