import "./SpaReport.css"
import React, {useEffect, useState} from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../redux/states/root.state";
import { AppDispatch } from "../../redux/store";
import { fetchSpa } from "../../redux/actions/spa.actions";
import Header from "../../components/common/Header/Header";
import ReportsGraph from "./components/ReportsGraph";
import ReportsTable from "./components/ReportsTable";
import NotFound from "../General/NotFound";
import {connectionOptions, errorOptions, firmwareOptions, liveOptions, RFIDOptions, settingsOptions, spaBoyOptions, spaSetOptions, statusOptions } from "./options";
import ReportUpgradeHistory from "./components/ReportUpgradeHistory";
import SpaCommandsTable from "./components/SpaCommandsTable";
import { Divider } from "@mui/material";

const SpaReport: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const {id} = useParams();
  var last_week = new Date();
  last_week.setDate(last_week.getDate() - 7)

  const {data: spa, error, isConnected} = useSelector((state: RootState) => state.spa);
  const {loading} = useSelector((state: RootState) => state.spaHistory);
  const [searchParams, setSearchParams] = useSearchParams();

  const [selection, setSelection] = useState<any[]>([]);
  const [category, setCategory] = useState("");
  const [startInput, setSInput] = useState(last_week.getTime());
  const [endInput, setEInput] = useState(new Date().getTime());

  const [startDate, setStart] = useState<any>(null);
  const [endDate, setEnd] = useState<any>(null);
  const [dataToShow, setShow] = useState("");
  const [selected, setSelected] = useState<any[]>([]);

  useEffect(() => {
    if (id){
      dispatch(fetchSpa(id));
    }
  }, [dispatch, id]);

  const handleGenerate = () => {
    searchParams.delete('page');
    setSearchParams(searchParams);
    setSelected(selection);
    setStart(startInput);
    setEnd(endInput);
    setShow(category);
  }

  const handleSpaboy = () => {
    setCategory('');
    setSelected([`sbI1`,`sbI2`,`sbVout`,`sbpH`,`sbORP`,`SBConnected`,`P1`]);
    setStart(startInput);
    setEnd(endInput);
    setShow('live');
  };

  const handleHeat = () => {
    setCategory('');
    setSelected([`STemp`,`TSP`,`HTemp`,`H1`]);
    setStart(startInput);
    setEnd(endInput);
    setShow('live');
  };

  const handleSelectAll = (e:any) => {
    if (e.target.checked) {
      switch (category) {
        case "live":
          setSelection(liveOptions.map((opt)=>opt.id))
          break;
        case "settings":
          setSelection(settingsOptions.map((opt)=>opt.id))
          break;
        case "spa_settings":
          setSelection(spaSetOptions.map((opt)=>opt.id))
          break;
        case "rfid":
          setSelection(RFIDOptions.map((opt)=>opt.id))
          break;
        case "spaboy":
          setSelection(spaBoyOptions.map((opt)=>opt.id))
          break;
        case "firmware":
          setSelection(firmwareOptions.map((opt)=>opt.id))
          break;
        case "connections":
          setSelection(connectionOptions.map((opt)=>opt.id))
          break;
        case "errors":
          setSelection(errorOptions.map((opt)=>opt.id))
          break;
        case "statuses":
          setSelection(statusOptions.map((opt)=>opt.id))
          break;
      }
    } else {
      setSelection([])
    }
  };

  const handleChange = (e:any) =>{
    setCategory(e.target.value);
    setSelection([]);
  }

  const handleCheck = (opt:any)=>{
    let newArray = [...selection];
    let found = newArray.find((s:any)=>s===opt.id)!==undefined;
    if (found) {
      newArray = selection.filter((s:any)=>
        s!==opt.id
      )
    } else {
      newArray.push(opt.id);
    }
    setSelection(newArray);
  }

  const handleDateChange = (e:any) => {
    let val = e.target.value;
    var date = new Date();
    switch(val){
      case "":
        date.setDate(date.getDate() - 7);
        setSInput(date.getTime());
        break;

      case "three":
        date.setTime(date.getTime() - 10800000)
        setSInput(date.getTime());
        break;

      case "six":
        date.setTime(date.getTime() - 21600000)
        setSInput(date.getTime());
        break;

      case "today":
        date.setUTCHours(0,0,0,0)
        setSInput(date.getTime());
        break;

      case "last_month":
        date.setMonth(date.getMonth()-1);
        setSInput(date.getTime());
        break;

      case "six_months":
        date.setMonth(date.getMonth()-6);
        setSInput(date.getTime());
        break;

      case "last_year":
        date.setFullYear(date.getFullYear() - 1);
        setSInput(date.getTime());
        break;
    }
  }

  useEffect(() => {
    if (category==='command_history'){
      setSelection(['commandHistory']);
    } else if (category==='upgrade_requests'){
      setSelection(['upgradeRequests']);
    }
  }, [category]);


  if (error){
    return <NotFound/>
  }

  return (
    <>
      <Header/>
      <div className="container">
        <div className="reports-header">
            <div>
              <div className="clickable-icon" onClick={()=>navigate(`/spa/${spa?.id}`)}><strong>{spa?.nick_name??`n/a`}</strong></div>
              {isConnected ? 
                (
                  <span className="status-indicator online"></span>
                ) : (
                  <span className="status-indicator offline"></span>
                )
              }
            </div>
            <div>{spa?.id}</div>
          </div>

        <div className="list reports">
          <div className="filters">
            <div className="search">
      
            <div className="date-input">
                <select className="select-style" name="report-category" id="report-select" onChange={(e)=>handleChange(e)} value={category} disabled={loading}>
                  <option value="">-- Select Category --</option>
                  <option value="live">Live Data</option>
                  <option value="settings">Settings Data</option>
                  <option value="spa_settings">Spa Settings</option>
                  <option value="rfid">RFID Tags</option>
                  <option value="spaboy">SpaBoy Settings</option>
                  <option value="firmware">Firmware</option>
                  <option value="connections">Connections</option>
                  <option value="errors">Errors</option>
                  <option value="statuses">Statuses</option>
                  <option value="upgrade_requests">Upgrade Requests</option>
                  <option value="command_history">Command History</option>
                </select>
              </div>


              {category==="live" &&
                <div className="checkbox-group"> 
                    <label><input type="checkbox" onChange={(e)=>handleSelectAll(e)} checked={selection.length===liveOptions.length}></input>Select All</label>
                    {liveOptions.map((opt)=>
                      <label key={opt.id}><input type="checkbox" onChange={()=>handleCheck(opt)} checked={selection.includes(opt.id)}></input>{opt.label}</label>
                    )}
                </div>
              }

              {category==="settings" &&
                <div className="checkbox-group"> 
                    <label><input type="checkbox" onChange={(e)=>handleSelectAll(e)} checked={selection.length===settingsOptions.length}></input>Select All</label>
                    {settingsOptions.map((opt)=>
                      <label key={opt.id}><input type="checkbox" onChange={()=>handleCheck(opt)} checked={selection.includes(opt.id)}></input>{opt.label}</label>
                    )}
                </div>
              }

              {category==="firmware" &&
                <div className="checkbox-group"> 
                    <label><input type="checkbox" onChange={(e)=>handleSelectAll(e)} checked={selection.length===firmwareOptions.length}></input>Select All</label>
                    {firmwareOptions.map((opt)=>
                      <label key={opt.id}><input type="checkbox" onChange={()=>handleCheck(opt)} checked={selection.includes(opt.id)}></input>{opt.label}</label>
                    )}
                </div>
              }

              {category==="rfid" &&
                <div className="checkbox-group">
                    <label><input type="checkbox" onChange={(e)=>handleSelectAll(e)} checked={selection.length===RFIDOptions.length}></input>Select All</label>
                    {RFIDOptions.map((opt)=>
                      <label key={opt.id}><input type="checkbox" onChange={()=>handleCheck(opt)} checked={selection.includes(opt.id)}></input>{opt.label}</label>
                    )}
                </div>
              }

              {category==="spa_settings" &&
                <div className="checkbox-group"> 
                    <label><input type="checkbox" onChange={(e)=>handleSelectAll(e)} checked={selection.length===spaSetOptions.length}></input>Select All</label>
                    {spaSetOptions.map((opt)=>
                      <label key={opt.id}><input type="checkbox" onChange={()=>handleCheck(opt)} checked={selection.includes(opt.id)}></input>{opt.label}</label>
                    )}
                </div>
              }

              {category==="spaboy" &&
                <div className="checkbox-group"> 
                    <label><input type="checkbox" onChange={(e)=>handleSelectAll(e)} checked={selection.length===spaBoyOptions.length}></input>Select All</label>
                    {spaBoyOptions.map((opt)=>
                      <label key={opt.id}><input type="checkbox" onChange={()=>handleCheck(opt)} checked={selection.includes(opt.id)}></input>{opt.label}</label>
                    )}
                </div>
              }

              {category==="connections" &&
                <div className="checkbox-group"> 
                    <label><input type="checkbox" onChange={(e)=>handleSelectAll(e)} checked={selection.length===connectionOptions.length}></input>Select All</label>
                    {connectionOptions.map((opt)=>
                      <label key={opt.id}><input type="checkbox" onChange={()=>handleCheck(opt)} checked={selection.includes(opt.id)}></input>{opt.label}</label>
                    )}
                </div>
              }

              {category==="errors" &&
                <div className="checkbox-group"> 
                    <label><input type="checkbox" onChange={(e)=>handleSelectAll(e)} checked={selection.length===errorOptions.length}></input>Select All</label>
                    {errorOptions.map((opt)=>
                      <label key={opt.id}><input type="checkbox" onChange={()=>handleCheck(opt)} checked={selection.includes(opt.id)}></input>{opt.label}</label>
                    )}
                </div>
              }

              {category==="statuses" &&
                <div className="checkbox-group"> 
                    <label><input type="checkbox" onChange={(e)=>handleSelectAll(e)} checked={selection.length===statusOptions.length}></input>Select All</label>
                    {statusOptions.map((opt)=>
                      <label key={opt.id}><input type="checkbox" onChange={()=>handleCheck(opt)} checked={selection.includes(opt.id)}></input>{opt.label}</label>
                    )}
                </div>
              }

              {category==="upgrade_requests" &&
                  <div className="checkbox-group"> 
                  <label>
                      <input 
                        type="checkbox" 
                        checked={true} 
                        disabled={true}
                        >
                      </input>
                    {'Upgrade Requests'}
                    </label>
                  </div>
              }

              {category==="command_history" &&
                <div className="checkbox-group"> 
                    <label>
                        <input 
                          type="checkbox" 
                          checked={true} 
                          disabled={true}
                          >
                        </input>
                      {'Command History'}
                      </label>
                </div>
              }

              <div className="reports-sticky">

                <div className="date-input">
                    <div className="since-date">
                      <p className="input-label">From </p>
                        <select className="date-range" name="date-range" id="range-select" onChange={(e)=>handleDateChange(e)}>
                          <option value="">Last Week</option>
                          <option value="three">Last 3 Hours</option>
                          <option value="six">Last 6 Hours</option>
                          <option value="today">Today</option>
                          <option value="last_month">Last Month</option>
                          <option value="six_months">Six Months</option>
                          <option value="last_year">Last Year</option>
                        </select>
                    </div>
                    <input type="date" value={new Date(startInput).toISOString().split("T")[0]??""} onChange={(e)=>setSInput(new Date(e.target.value).getTime())} max={new Date(endInput).toISOString().split("T")[0]}></input>
                  </div>

                  <div className="date-input">
                    <p className="input-label">Until </p>
                    <input 
                      type="date" 
                      value={new Date(endInput).toISOString().split("T")[0]??""} 
                      onChange={(e)=>setEInput(e.target.value===""? new Date().getTime() :new Date(e.target.value).getTime())} 
                      min={new Date(startInput).toISOString().split("T")[0]} 
                      max={new Date().toISOString().split("T")[0]}>
                    </input>
                  </div>

                <div className="filter-btn-group">
                  <button disabled={selection.length===0||category===""||loading} onClick={handleGenerate} >Generate Report</button>
                  <Divider/>
                  <button className="button" onClick={handleSpaboy} disabled={loading}>SpaBoy Report</button>
                  <button className="button" onClick={handleHeat} disabled={loading}>Heat Report</button>
                </div>
              </div>

            </div>
          </div>
          {
            dataToShow==="live" ?
            <ReportsGraph selected={selected} startDate={startDate} endDate={endDate} spa_guid={spa?.id}/>
            :
            dataToShow==="upgrade_requests" ?
              <ReportUpgradeHistory spa_guid={spa?.id} startDate={startDate} endDate={endDate}/>
            :
            dataToShow==="command_history" ?
              <SpaCommandsTable spa_guid={spa?.id} startDate={startDate} endDate={endDate}/>
            :
            dataToShow===""?
              <div className="empty-table">{`Select Data to generate report.`}</div>
            :
            <ReportsTable selected={selected} startDate={startDate} endDate={endDate} spa_guid={spa?.id} category={dataToShow}/>
          }
        </div>
      </div>
    </>
  );
};

export default SpaReport;
