import "../SpaDetailsPage.css"
import React from "react";
import { useSelector } from "react-redux";
import RootState from "../../../redux/states/root.state";
import {SpaLiveLabels} from "../../../mqttData/live/spaLiveLabels.enums";
import { SpaSettingsLabels } from "../../../mqttData/settings/spaSettingsLabels";
import useMQTT from "../../../hooks/useMQTT";
import Switch from '@mui/material/Switch';
import { SpaConstLabels } from "../../../mqttData/consts/spaConstsLabels.enums";

const DetailsGrid: React.FC<{editMode:any, newSettings:any, setNewSettings:any}> = ({editMode, newSettings, setNewSettings}) => {
  const spa = useSelector((state: RootState) => state.spa.data);

  // Spa data and settings:
  const liveData = useSelector((state: RootState) => state.live.data);
  const settingsData = useSelector((state: RootState) => state.settings.data);
  const constData = useSelector((state: RootState) => state.const.data);
  const statusData = useSelector((state: RootState) => state.status.data);

  // MQTT Functionality:
  useMQTT(spa.id ?? "");

  let valueMapper: { [p: number]: string };

  valueMapper = {
    0:'OFF',
    1:'P1low',
    2:'P1high',
    3:'Yess',   
    15 : 'LOW',
    100 : 'HIGH',
  }

  let orpLevelMapper: { [p: number]: [number, number] };
  let orpLevelMapperReverse: { [p: number]: number };

  orpLevelMapper = {
    0: [545, 555],
    50: [645, 655],
    100: [745, 755],
  };

  orpLevelMapperReverse = {
    555: 0,
    655: 50,
    755: 100,
  };



    const getConvertedValue = (value:any, convert = 100) => {
        return value ? (value as number) / convert : 0;
    };

    const getConfigDisplay = (value:any, type:string) => {
      switch(type){
        case `config`:
          return value ? <span className="check">✓</span> :<span className="ex">X</span>;

        case `isOn`:
          return value ? 'ON' :'OFF';;

        case `isConnected`:
          return value ? 'CONNECTED' :'DISCONNECTED';
      }
    }

  return (
    <>
      <div className="details-grid">
      {
        <>
          <span className="grid-header-row" style={{gridColumn:'1/span 1'}}>
            <span className="grid-header-row live-amps">
              <span><b>Pack Current: </b>{getConvertedValue(liveData[SpaLiveLabels.Current])} amps</span>
            </span>
          </span>
          <span className="grid-header-row" style={{gridColumn:'2/span 1'}}>Config</span>
          <span className="grid-header-row" style={{gridColumn:'3/span 5'}}>Live</span>
          <span className="grid-header-row" style={{gridColumn:'8/span 3'}}>Settings</span>
        </>
        }


          {/* Heat Row */}
          <>
            <div className="title"></div>
            <div className="title config">Heat</div>

            {/* Live */}
            <div className={liveData[SpaLiveLabels.H1]?"active":""}>{getConfigDisplay(liveData[SpaLiveLabels.H1], `isOn`)}</div>
            <div className="labelled-cell">
              <span>Spa: </span>
              <span className="active">{liveData[SpaLiveLabels.CurrentTemp] as number ?? 0}°F</span>
            </div>
            <div > </div>
            <div > </div>
            <div > </div>

            {/* Settings */}
            <div className="labelled-cell">
              <span>Set Point: </span>
              {editMode ?
                <span className="active">
                  <input id="setTSP" type="number" defaultValue={settingsData.TSP} min={constData[SpaConstLabels.setTSPmin]} max={constData[SpaConstLabels.setTSPmax]} 
                    onChange={(e)=>setNewSettings({...newSettings, setTSP: Number(e.target.value)})}/>
                </span>
                :
                <span className="active">{settingsData.TSP}°F</span>
              }
            </div>
            <div >{statusData.STAT61!==null ? statusData.STAT61 ? 'Euro': 'NA' : ''}</div>
            <div className="labelled-cell">
            <span>CTMode: </span>
            {editMode ? 
              <select name="CTMode" id="cfgCTMode" defaultValue={settingsData[SpaSettingsLabels.cfgCTMode] ?? 0} onChange={(e)=>setNewSettings({...newSettings, cfgCTMode: Number(e.target.value)})}>
                <option value={0}>0</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
              </select>
              :
              <span className="labelled-cell">{Number(settingsData[SpaSettingsLabels.cfgCTMode]) ?? 0}</span>            
            }
            </div>
          </>

          {/* Heat Row 2 */}
          <>
            <div className="title"></div>
            <div className="config"></div>

            {/* Live */}
            <div > </div>
            <div className="labelled-cell">
              <span>Heater: </span>
              <span className="active">{liveData[SpaLiveLabels.HTemp] as number ?? 0}°F</span>
            </div>
            <div > </div>
            <div > </div>
            <div > </div>

            {/* Settings */}
            <div > </div>
            <div > </div>
            <div > </div>
          </>

          {/* Filter Row */}
          <>
            <div className="title"></div>
            <div className="title config">Filter</div>

            {/* Live */}
            <div className={liveData[SpaLiveLabels.Filter] ? `active`:``}>{getConfigDisplay(liveData[SpaLiveLabels.Filter], `isOn`)}</div>
            <div > </div>
            <div > </div>
            <div > </div>
            <div > </div>

            {/* Settings */}
            <div className="labelled-cell">
              <span>FF: </span>
              {editMode ?
              <span className={settingsData.FF ? `active`:``}>
                <input type="number" defaultValue={settingsData.FF} min={constData[SpaConstLabels.setFFmin]} max={constData[SpaConstLabels.setFFmax]} 
                  onChange={(e)=>setNewSettings({...newSettings, setFF: Number(e.target.value)})}/>
              </span>
              :
              <span className={settingsData.FF ? `active`:``}>{settingsData.FF ?? 0}</span>
            }
              
            </div>

            <div className="labelled-cell">
              <span>FD: </span>
              {editMode ?
                <span className={settingsData.FD ? `active`:``}><input type="number" defaultValue={settingsData.FD} min={constData[SpaConstLabels.setFDmin]} max={constData[SpaConstLabels.setFDmax]}
                  onChange={(e)=>setNewSettings({...newSettings, setFD: Number(e.target.value)})}
                /></span>
                :
                <span className={settingsData.FD ? `active`:``}>{settingsData.FD ?? 0}</span>
              }
            </div>

            <div className="labelled-cell">
              <span>FS: </span>
                {editMode ?
                  <span><Switch size="small" defaultChecked={settingsData.FS} onChange={(e)=>setNewSettings({...newSettings, setFS: e.target.checked})}/></span>
                  :
                  <span className={settingsData.FS ? `active`:``}>{getConfigDisplay(settingsData.FS, `isOn`)}</span>
                }
            </div>
          </>

          {/* Pump 1 Row */}
          <>
            <div className="title">Pump1</div>
            <div className="config">{getConfigDisplay(settingsData[SpaSettingsLabels.cfgP1], `config`)}</div>
            
            {/* Live */}
            <div className={liveData[SpaLiveLabels.Pump1] ? `active`:``}>{valueMapper[liveData[SpaLiveLabels.Pump1]] ?? valueMapper[0]}</div>
            <div > </div>
            <div > </div>
            <div > </div>
            <div > </div>

            {/* Settings */}
            <div > </div>
            <div > </div>
            <div > </div>
          </>

          {/* Pump 2 Row */}
          <>
            <div className="title">Pump2</div>
            {editMode?
              <div className="config"><input type="checkbox" defaultChecked={settingsData[SpaSettingsLabels.cfgP2]} onChange={(e)=>setNewSettings({...newSettings, cfgP2: e.target.checked})}></input></div>
              :
              <div className="config">{getConfigDisplay(settingsData[SpaSettingsLabels.cfgP2], `config`)}</div>
            }

            {/* Live */}
            <div className={liveData[SpaLiveLabels.Pump2] ? `active`:``}>{valueMapper[liveData[SpaLiveLabels.Pump2]] ?? valueMapper[0]}</div>
            <div > </div>
            <div > </div>
            <div > </div>
            <div > </div>

            {/* Settings */}
            <div > </div>
            <div > </div>
            <div > </div>
          </>

          {/* Pump 3 Row */}
          <>
            <div className="title">Pump3</div>
            {editMode?
              <div className="config"><input type="checkbox" defaultChecked={settingsData[SpaSettingsLabels.cfgP3]} onChange={(e)=>setNewSettings({...newSettings, cfgP3: e.target.checked})}></input></div>
              :
              <div className="config">{getConfigDisplay(settingsData[SpaSettingsLabels.cfgP3], `config`)}</div>
            }

            {/* Live */}
            <div className={liveData[SpaLiveLabels.Pump3] ? `active`:``}>{valueMapper[liveData[SpaLiveLabels.Pump3]] ?? valueMapper[0]}</div>
            <div > </div>
            <div > </div>
            <div > </div>
            <div > </div>

            {/* Settings */}
            <div > </div>
            <div > </div>
            <div > </div>
          </>

          {/* SDS Row */}
          <>
            <div className="title">SDS</div>
            {editMode?
              <div className="config"><input type="checkbox" defaultChecked={settingsData[SpaSettingsLabels.cfgSDS]} onChange={(e)=>setNewSettings({...newSettings, cfgSDS: e.target.checked})}></input></div>
              :
              <div className="config">{getConfigDisplay(settingsData[SpaSettingsLabels.cfgSDS], `config`)}</div>
            }

            {/* Live */}
            <div className={liveData[SpaLiveLabels.SDS]!==0 ? `active`:``}>{valueMapper[liveData[SpaLiveLabels.SDS]] ?? valueMapper[0]}</div>
            <div > </div>
            <div > </div>
            <div > </div>
            <div > </div>

            {/* Settings */}
            <div > </div>
            <div > </div>
            <div > </div>
          </>

          {/* YESS Row */}
          <>
            <div className="title">YESS</div>
            {editMode?
              <div className="config"><input type="checkbox" defaultChecked={settingsData[SpaSettingsLabels.cfgYESS]} onChange={(e)=>setNewSettings({...newSettings, cfgYESS: e.target.checked})}></input></div>
              :
              <div className="config">{getConfigDisplay(settingsData[SpaSettingsLabels.cfgYESS], `config`)}</div>
            }

            {/* Live */}
            <div className={liveData[SpaLiveLabels.Yess] ? `active`:``}>{getConfigDisplay(liveData[SpaLiveLabels.Yess],`isOn`)}</div>
            <div > </div>
            <div > </div>
            <div > </div>
            <div > </div>

            {/* Settings */}
            <div > </div>
            <div > </div>
            <div > </div>
          </>

          {/* Fogger Row */}
          <>
            <div className="title">Fogger</div>
            {editMode?
              <div className="config"><input type="checkbox" defaultChecked={settingsData[SpaSettingsLabels.cfgFG]} onChange={(e)=>setNewSettings({...newSettings, cfgFG: e.target.checked})}></input></div>
              :
              <div className="config">{getConfigDisplay(settingsData[SpaSettingsLabels.cfgFG], `config`)}</div>
            }

            {/* Live */}
            <div className={liveData[SpaLiveLabels.Fogger] ? `active`:``}>{getConfigDisplay(liveData[SpaLiveLabels.Fogger],`isOn`)}</div>
            <div > </div>
            <div > </div>
            <div > </div>
            <div > </div>

            {/* Settings */}
            <div > </div>
            <div > </div>
            <div > </div>
          </>

          {/* Ozone P1 Row */}
          <>
            <div className="title">Ozone Peak1</div>
            {editMode?
              <div className="config"><input type="checkbox" defaultChecked={settingsData[SpaSettingsLabels.cfgOzP1]} onChange={(e)=>setNewSettings({...newSettings, cfgOzP1: e.target.checked})}></input></div>
              :
              <div className="config">{getConfigDisplay(settingsData[SpaSettingsLabels.cfgOzP1], `config`)}</div>
            }

            {/* Live */}
            <div className={liveData[SpaLiveLabels.Oz] ? `active`:``}>{getConfigDisplay(liveData[SpaLiveLabels.Oz],`isOn`)}</div>
            <div > </div>
            <div > </div>
            <div > </div>
            <div > </div>

            {/* Settings */}
            {editMode ?
              <div className={settingsData.OzCy!==0 ? `active`:``}>
                <select name="Ozone Cycle" id="OzCy" defaultValue={settingsData.OzCy} onChange={(e)=>setNewSettings({...newSettings, setOzCy: Number(e.target.value)})}>
                  <option value={0}>OFF</option>
                  <option value={1}>P1low</option>
                  <option value={2}>P1high</option>
                  <option value={3}>Yess</option>
                </select>
              </div>
              :
              <div className={settingsData.OzCy!==0 ? `active`:``}>{ valueMapper[settingsData.OzCy] ?? valueMapper[0]}</div>
            }
            <div > </div>
            <div > </div>
          </>

          {/* Ozone P2 Row */}
          <>
            <div className="title">Ozone Peak2</div>
            {editMode?
              <div className="config"><input type="checkbox" defaultChecked={settingsData[SpaSettingsLabels.cfgOzP2]} onChange={(e)=>setNewSettings({...newSettings, cfgOzP2: e.target.checked})}></input></div>
              :
              <div className="config">{getConfigDisplay(settingsData[SpaSettingsLabels.cfgOzP2], `config`)}</div>
            }

            {/* Live */}
            <div > </div>
            <div > </div>
            <div > </div>
            <div > </div>
            <div > </div>

            {/* Settings */}
            {editMode?
              <div className={settingsData.OzHr ? `active`:``}>
                <input 
                  style={{width:'5ch'}} 
                  type="number" 
                  defaultValue={settingsData.OzHr} 
                  min={constData[SpaConstLabels.setOzHrmin]}
                  max={constData[SpaConstLabels.setOzHrmax]}
                  onChange={(e)=>setNewSettings({...newSettings, setOzHr: Number(e.target.value)})}>
                </input> hrs/day
              </div>
              :
              <div className={settingsData.OzHr ? `active`:``}>{settingsData.OzHr ?? 0} hrs/day</div>
            }
            
            <div > </div>
            <div > </div>
          </>

          {/* SpaBoy */}
          <>
            <div className="title">SpaBoy</div>
            {editMode?
              <div className="config"><input type="checkbox" defaultChecked={settingsData[SpaSettingsLabels.cfgSB]} onChange={(e)=>setNewSettings({...newSettings, cfgSB: e.target.checked})}></input></div>
              :
              <div className="config">{getConfigDisplay(settingsData[SpaSettingsLabels.cfgSB], `config`)}</div>
            }

            {/* Live */}
            <div className={liveData.sbStat ? `active`:``}>{getConfigDisplay(liveData.sbStat,`isOn`)}</div>

            <div className="labelled-cell">
              <span>pH: </span>
              <span className={liveData.sbpH ? `active`:``}>{getConvertedValue(liveData.sbpH)}</span>
            </div>

            <div className="labelled-cell">
              <span>ORP: </span>
              <span className={liveData.sbORP ? `active`:``}>{liveData.sbORP??0} mV</span>
            </div>
            <div >{`E1 wear`}</div>
            <div >{`E2 wear`}</div>

            {/* Settings */}
            {editMode ?
              <div >
                <span>ORP: </span>
                <select name="CL Level" id="SBORPhi" defaultValue={orpLevelMapperReverse[settingsData.SBORPhi]} onChange={(e)=>setNewSettings({...newSettings, SBORPlo: orpLevelMapper[Number(e.target.value)][0], SBORPhi: orpLevelMapper[Number(e.target.value)][1]})}>
                  <option value={0}>Low</option>
                  <option value={50}>Med</option>
                  <option value={100}>High</option>
                </select>
              </div>
              :
              <div className="labelled-cell">
                <span>ORP Hi: </span>
                <span className={settingsData.SBORPhi ? `active`:``}>{settingsData.SBORPhi??0} mV</span>
              </div>
            } 
            <div className="labelled-cell">
              <span>pH Hi: </span>
              <span className={settingsData.SBpHhi ? `active`:``}>{getConvertedValue(settingsData.SBpHhi)}</span>
            </div>

            {editMode?
              <div className={settingsData.SBHr ? `active`:``}>
                <input style={{width:'5ch'}} 
                  type="number" 
                  defaultValue={settingsData.SBHr} 
                  min={0}
                  max={8}
                  onChange={(e)=>setNewSettings({...newSettings, setSBHrs: Number(e.target.value)})}>
                </input> hrs/day
              </div>
              :
              <div className={settingsData.SBHr ? `active`:``}>{settingsData.SBHr ?? 0} hrs/day</div>
            }
                       
          </>

          {/* SpaBoy Row 2 */}
          <>
            <div className="title">Smart pH</div>
            {editMode?
              <div className="config"><input type="checkbox" defaultChecked={settingsData[SpaSettingsLabels.cfgSP]} onChange={(e)=>setNewSettings({...newSettings, cfgSP: e.target.checked})}></input></div>
              :
              <div className="config">{getConfigDisplay(settingsData[SpaSettingsLabels.cfgSP], `config`)}</div>
            }

            {/* Live */}
            <div className={settingsData.SBConnected ? `active`:``}>{getConfigDisplay(settingsData.SBConnected,`isConnected`)}</div>
            <div className="labelled-cell">
                <span>Evolts: </span>
                <span className={liveData.sbVout ? `active`:``}>{liveData.sbVout} mV</span>
              </div>
            <div > </div>
            <div className={liveData.sbI1 ? `active`:``}>{liveData.sbI1} mA</div>
            <div className={liveData.sbI2 ? `active`:``}>{liveData.sbI2} mA</div>

            {/* Settings */}
            {editMode ?
              <div className="labelled-cell">
              </div>
              :
              <div className="labelled-cell">
                <span>ORP Lo: </span>
                <span className={settingsData.SBORPlo ? `active`:``}>{settingsData.SBORPlo??0} mV</span>
              </div>
            } 

            <div className="labelled-cell">
              <span>pH Lo: </span>
              <span className={settingsData.SBpHlo ? `active`:``}>{getConvertedValue(settingsData.SBpHlo)}</span>
            </div>

            <div > </div>
          </>

          {/* Onzen Row */}
          <>
            <div className="title">Onzen</div>
            {editMode?
              <div className="config"><input type="checkbox" defaultChecked={settingsData[SpaSettingsLabels.cfgOn]} onChange={(e)=>setNewSettings({...newSettings, cfgOn: e.target.checked})}></input></div>
              :
              <div className="config">{getConfigDisplay(settingsData[SpaSettingsLabels.cfgOn], `config`)}</div>
            }

            {/* Live */}
            <div  className={liveData.On ? `active`:``}>{getConfigDisplay(liveData.On,`isOn`)}</div>
            <div > </div>
            <div > </div>
            <div > </div>
            <div > </div>

            {/* Settings */}
            {editMode?
              <div className={settingsData.OnHr ? `active`:``}>
                <input 
                  style={{width:'5ch'}} 
                  type="number" 
                  defaultValue={settingsData.OnHr} 
                  min={constData[SpaConstLabels.setOnHrmin]}
                  max={constData[SpaConstLabels.setOnHrmax]}
                  onChange={(e)=>setNewSettings({...newSettings, setOnHr: Number(e.target.value)})}>
                </input> hrs/day
              </div>
              :
              <div className={settingsData.OnHr ? `active`:``}>{settingsData.OnHr ?? 0} hrs/day</div>
            }
            
            <div > </div>
            <div > </div>
          </>

          {/* RFID Row */}
          <>
            <div className="title">RFID</div>
            {editMode?
              <div className="config"><input type="checkbox" defaultChecked={settingsData[SpaSettingsLabels.cfgRFID]} onChange={(e)=>setNewSettings({...newSettings, cfgRFID: e.target.checked})}></input></div>
              :
              <div className="config">{getConfigDisplay(settingsData[SpaSettingsLabels.cfgRFID], `config`)}</div>
            }
            <div ></div>
            <div > </div>
            <div > </div>
            <div > </div>
            <div > </div>
            <div > </div>
            <div > </div>
            <div > </div>
          </>

          {/* Breaker Count Row */}
          <>
            <div className="title">Breaker Count</div>
            {editMode ? 
              <div className="config">
                <select name="Breaker Count" id="cfgPL" defaultValue={1} onChange={(e)=>setNewSettings({...newSettings, cfgPL: Number(e.target.value)})}>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                </select>
              </div>
              :
              <div className="config">{settingsData[SpaSettingsLabels.cfgPL] ?? 0}</div>            
            }
            
            <div > </div>
            <div > </div>
            <div > </div>
            <div > </div>
            <div > </div>
            <div > </div>
            <div > </div>
            <div > </div>
          </>

          {/* Breaker Size Row */}
          <>
            <div className="title">Breaker Size</div>
            {editMode ? 
              <div className="config">
                <select name="Breaker Size" id="cfgBS" defaultValue={settingsData[SpaSettingsLabels.cfgBS] ?? 60} onChange={(e)=>setNewSettings({...newSettings, cfgBS: Number(e.target.value)})}>
                  <option value={16}>16</option>
                  <option value={20}>20</option>
                  <option value={25}>25</option>
                  <option value={32}>32</option>
                  <option value={40}>40</option>
                  <option value={50}>50</option>
                  <option value={60}>60</option>
                </select>
              </div>
              :
              <div className="config">{settingsData[SpaSettingsLabels.cfgBS] ?? 0}</div>            
            }
            <div > </div>
            <div > </div>
            <div > </div>
            <div > </div>
            <div > </div>
            <div > </div>
            <div > </div>
            <div > </div>
          </>

          {/* Econ Mode Row */}
          <>
            <div className="title">Econ Mode</div>
            <div className="config"> </div>

            {/* Live */}
            <div className={liveData.Econ ? `active`:``}>{getConfigDisplay(liveData.Econ,`isOn`)}</div>
            <div > </div>
            <div > </div>
            <div > </div>
            <div > </div>
            
            {/* Settings */}
            <div >{`Click for settings`}</div>
            <div > </div>
            <div > </div>
          </>
      </div>
    </>
  );
};

export default DetailsGrid;
