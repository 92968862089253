import './RoleDetailsPage.css'
import React, {  useEffect, useState } from "react";
import Header from '../../../components/common/Header/Header';
import { AppDispatch } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { fetchRole, fetchRoleUsers } from '../../../redux/actions/roles.actions';
import RootState from '../../../redux/states/root.state';
import { CircularProgress, Tooltip } from '@mui/material';
import SimpleUsersTable from '../../../components/common/Tables/SimpleUsersTable';
import { Permission } from '../../../redux/models/permission.model';
import { InfoOutlined } from '@mui/icons-material';
import RemoveUsersModal from '../components/RemoveUsersModal';
import AddUsersModal from '../components/AddUsersModal';

const RoleDetailsPage = () => {
  const dispatch: AppDispatch = useDispatch();
  const {id} = useParams();
  const searchParams = useSearchParams()[0];
  const {data:role,loading:isLoading} = useSelector((state: RootState) => state.role);

  // Checked items
  const [checked, setChecked] = useState<any[]>([]);
  const [isCheckAll, setCheckAll] = useState(false);

  const [removeOpen, setRemoveOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  
  // Dispatch UseEffects
  useEffect(() => {
    if (id) {
      dispatch(fetchRole(id));
      dispatch(fetchRoleUsers({id:id, page:searchParams.toString()}));
    }
  }, [dispatch, id,searchParams]);

  return (
      <>
        <RemoveUsersModal selection={checked} open={removeOpen} setOpen={setRemoveOpen} setSelection={setChecked} />
        <AddUsersModal open={addOpen} setOpen={setAddOpen} />
        <Header/>
        <div className="container">
        {isLoading ? 
          <CircularProgress size={"24px"} sx={{position:'absolute', top:'50%', right:'50%'}}/>
        :
          <div className="list">
              <div className="filters">
                <div className="card-container details">
                  
                  <div className="card-title">
                    <strong style={{textTransform: 'capitalize'}}>{role?.name}</strong>
                  </div>
                  <hr></hr>
                  <div>{role?.description}</div>
                  <br></br>      

                  <div className="card-title">
                    <strong>Permissions</strong>
                  </div>
                  <hr></hr>

                  <div className="permissions">
                  {role?.permissions!==undefined && role?.permissions.length>0 ?
                      role.permissions.map((p:Permission, i:number)=>
                      <span key={i} className="permission-info">
                        <Tooltip title={p.description}><InfoOutlined/></Tooltip>
                        <div>{p.name}</div>
                      </span>
                      )
                      :
                      <div>None</div>
                    } 
                </div>
                
                </div>

              </div>
              <div className="table-section">
                  <SimpleUsersTable
                    checked={checked}
                    setChecked={setChecked}
                    isCheckAll={isCheckAll}
                    setCheckAll={setCheckAll}
                  />
                <div className="filter-btn-group spa-actions">
                  <button 
                    onClick={()=>setAddOpen(true)}>
                      Add Users
                  </button>

                  <button 
                    onClick={()=>setRemoveOpen(true)}
                    disabled={checked.length===0}
                    >
                      {`Remove Users ${checked.length>0? `(${checked.length})`:``}`}
                  </button>
                  
                </div>
            </div>             
            </div>
          }
      </div>   
    </>
  );
};

export default RoleDetailsPage;
