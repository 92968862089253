import axios from "axios";
import { BASE_URL } from "./apiconfig";

export const getAvailableFirmware = async (token: string | null, params:string) => {
    try {
      const { data } = await axios.get(`${BASE_URL}/firmware/available?${params}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (error) {
      console.log("Failed to pull firmware available. ", error);
      throw error;
    }
}

export const upgradeFirmwareApi = async (token: string | null, firmwareInfo : {spa_id: string,platform: string,version: string}) => {
  try {
      const response = await axios.post(`${BASE_URL}/firmware/upgrade`, {
          spa_id: firmwareInfo.spa_id,
          platform: firmwareInfo.platform,
          version: firmwareInfo.version,
          source: "web_portal"
      },{
          headers: { Authorization: `Bearer ${token}` }
      });

      return response.data;
  } catch (error) {
        console.log("Failed to upgrade spa. ", error);
      throw error;
  }
};

export const uploadFirmwareApi = async (token: string | null, formData: FormData) => {
    try {
        const {data} = await axios.post(`${BASE_URL}/firmware/upload`,formData,{
            headers: { 
                Authorization: `Bearer ${token}` ,
            },
        });

        return data
    } catch (error) {
        console.log("Failed upload firmware.", error);
        throw error;
    }
}


export const updateFirmwareApi = async (token: string | null, firmwareInfo: {firmware_id: string, is_latest: boolean, description: string}) => {
    try {
        const {data} = await axios.put(`${BASE_URL}/firmware/${firmwareInfo.firmware_id}`,{
            is_latest: firmwareInfo.is_latest,
            description: firmwareInfo.description
        },{
            headers: { 
                Authorization: `Bearer ${token}` ,
            },
        });

        return data
    } catch (error) {
        console.log("Failed to update firmware information.", error);
        throw error;
    }
}

export const deleteFirmwareApi = async (token: string | null, firmware_id: string) => {
    try {
        const {data} = await axios.delete(`${BASE_URL}/firmware/${firmware_id}`,{
            headers: { 
                Authorization: `Bearer ${token}` ,
            },
        });

        return data
    } catch (error) {
        console.log("Failed to delete firmware.", error);
        throw error;
    }
}


