import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAUserSpas, getFlaggedSpas, getUserSpas } from '../../services/api/spas.api';
import RootState from "../states/root.state";

export const fetchSpas = createAsyncThunk<any, string, { rejectValue: string; state: RootState}>(
  'spa/list',
  async (params, {rejectWithValue, getState}) => {
    try {

      const {user} = getState();
      const token = user.accessToken;

      const response = await getUserSpas(token,params);
      return response.data;
    } catch (error: any) {
        if (error.response) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue('An error occurred while fetching spas.');
        }
    }
  }
);

export const fetchUserSpas = createAsyncThunk<any, {page:string,uuid:string}, { rejectValue: string; state: RootState}>(
  'user/spa/list',
  async (params, {rejectWithValue, getState}) => {
    try {

      const {user} = getState();
      const token = user.accessToken;

      const response = await getAUserSpas(token,params);
      return response.data;
    } catch (error: any) {
        if (error.response) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue('An error occurred while fetching spas.');
        }
    }
  }
);

export const fetchFlaggedSpas = createAsyncThunk<any, string, { rejectValue: string; state: RootState}>(
  'user/spa/flagged',
  async (params, {rejectWithValue, getState}) => {
    try {

      const {user} = getState();
      const token = user.accessToken;

      const response = await getFlaggedSpas(token,params);
      return response.data;
    } catch (error: any) {
        if (error.response) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue('An error occurred while fetching spas.');
        }
    }
  }
);