import "./Tables.css"
import React, {useEffect, useState} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../../redux/states/root.state";
import {
  CircularProgress, 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableRow,
  TableSortLabel,
  Box,
  Pagination,
  Popover,
  Badge,
  Tooltip
} from "@mui/material";
import { visuallyHidden } from '@mui/utils';
import { ControlIcon, CommentIcon, EcoModeOn, EcoModeOff, ErrorsIcon, DeleteIcon, EditIcon, SettingIcon, FlagIconFilled, FlagIconOutlined} from "../../../assets/svg/svg";
import { IndicatorColours } from "../Indicators/IndicatorColours";
import useRoleCheck from "../../../hooks/useRoleCheck";
import { flagSpa } from "../../../redux/actions/spa.actions";
import { AppDispatch } from "../../../redux/store";

type Order = 'asc' | 'desc';
const headers = [
  {id:'checked', label:'', align:'center'},
  {id:'status', label:'Status', align:'center'}, // combine last online into here
  {id:'id', label:'GUID', align:'left'}, 
  {id:'dealership', label:'Dealership', align:'left'}, 
  {id:'name', label:'User', align:'left'}, 
  {id:'arctic_serial_number', label:'Serial', align:'left'}, 
  {id:'ip_address', label:'IP', align:'left'},
  {id:'nick_name', label:'Nickname', align:'left'},
  {id:'errors', label:'Errors', align:'center'},
  {id:'yocto_version', label:'Yocto', align:'left'},
  {id:'lpc_version', label:'LPC', align:'left'},
  {id:'spaboy_firmware_version', label:'Spaboy', align:'left'},
  {id:'econ', label:'EcoMode', align:'center'},
  {id:'sbHr', label:'Manual Mode', align:'center'},
  {id:'current', label:'Amps', align:'center'},
  {id:'sbpH', label:'pH', align:'center'},
  {id:'sbORP', label:'ORP', align:'center'},
  {id:'user_registered_at', label:'Registered',align:'left'}, 
  {id:'created_at', label:'Added',align:'left'}, 
  {id:'updated_at', label:'Updated',align:'left'}, 
  {id:'control', label:'Control', align:'center'}, 
  {id:'comments', label:'Comments', align:'center'},
  {id:'is_flagged', label:'Flag', align:'center'},
  {id:'edit', label:'Edit', align:'center'},
  {id:'delete', label:'Delete', align:'center'},
];

const defaultHeaders = [
  'checked',
  'status',
  'name',
  'arctic_serial_number',
  'ip_address',
  'nick_name',
  'errors',
  'econ',
  'current',
  'sbpH',
  'sbORP',
  'created_at',
  'updated_at',
  'control',
  'comments',
  'is_flagged',
];

interface SpasTableProps {
  pageName?:string,
  setChecked?: any,
  checked?: any[],
  setDeleteOpen: any;
  setEditOpen: any;
  setSpaToModify: any;
}

const SpasTable: React.FC<SpasTableProps> = (
  {
    pageName='spas', 
    setChecked=null, 
    checked=null,
    setDeleteOpen,
    setEditOpen,
    setSpaToModify,
  }
) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const {data:spas,loading:isLoading, meta} = useSelector((state: RootState) => state.spas);
  const [searchParams, setSearchParams] = useSearchParams();

  const [headersToShow, setHeadersToShow] = useState<any[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  // Handle Spa Delete
  const canDelete = useRoleCheck(['admin','developer']);

  // Table Sort
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('');
  const [sortedRows, setSortedRows] = useState<any[]>([]);
  const [allChecked, setAllChecked] = useState(false);
  
  useEffect(() => {
    setSortedRows(spas);
  }, [spas]);

  useEffect(() => {
    let local = localStorage.getItem('spa_headers');
    let newArray=['checked','status']; //permanent headers
    if (local){
      newArray = local.split(','); //split local string of headers
    } else {
      newArray = [...defaultHeaders]; //use default header settings
    }

    if (canDelete){
      newArray.push('edit');
      newArray.push('delete');
    }

    setHeadersToShow(newArray);
  }, [pageName,canDelete,setHeadersToShow]);

  const handleChange = (event:any, value:any) => {
    searchParams.set("page",value);
    setSearchParams(searchParams);
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    header_id: string,
  ) => {
    const isAsc = orderBy === header_id && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(header_id);

    handleSort();
  };

  const handleSetting = (e:any) => {
    let isToggledOn = e.target.checked;
    let val = e.target.value;
    let newArray = [...headersToShow]
    if (isToggledOn) {
      newArray.push(val);
    } else {
      newArray = newArray.filter((header:string)=>header!==val);
    }
    localStorage.setItem('spa_headers',newArray.toString());
    setHeadersToShow(newArray);
  }

  const handleSort = () => {
    let newArray = [...spas].sort((a:any, b:any)=>{
      let isAsc = order ==='asc';
      switch (orderBy){
        case 'status':
          return a.status===b.status ? 0 : isAsc? a.status === 'connected' ? -1 : 1 : b.status === 'connected' ? -1 : 1
      
        case 'name':
          return a?.user?.name===b?.user?.name ? 0 : isAsc ? a?.user?.name>b?.user?.name ? -1 : 1 : a?.user?.name<b?.user?.name ? -1 : 1;

        case 'dealership':
          return a?.dealership?.name===b?.dealership?.name ? 0 : isAsc ? a?.dealership?.name>b?.dealership?.name ? -1 : 1 : a?.dealership?.name<b?.dealership?.name ? -1 : 1;
          
        default:
          return a[orderBy]===b[orderBy] ? 0 : isAsc ? a[orderBy]>b[orderBy] ? -1 : 1 : a[orderBy]<b[orderBy] ? -1 : 1;
       
      }
    })
    setSortedRows(newArray);
  }

  const handleCheck = (spa:any) => {
    setAllChecked(false);
    let newArr = [...checked??[]];
    let found = checked?.find((checked_spa:any)=>spa?.id===checked_spa?.id)!==undefined;
    if(found){
      newArr = newArr.filter((checked_spa:any)=>checked_spa?.id!==spa?.id);
    } else {
      newArr.push(spa);
    }
    setChecked(newArr);
  }

  // Handle Selecting Users
  const checkAll = (e:any) => {
    setAllChecked(e.target.checked);
    setChecked(e.target.checked ? [...spas]:[])
  }

  const isChecked=(spa:any)=>{
    return checked?.find((checked_spa:any)=>spa?.id===checked_spa?.id)!==undefined
  }

  const handleEditDelete = (spa:any, action: 'delete'|'edit') => {
    setSpaToModify(spa);
    action==="delete"? setDeleteOpen(true) : setEditOpen(true);
  }

  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const showSetting = (header:any) => {
    if(header.id!=='checked'){
      if (header.id==='edit'||header.id==='delete'){
        return canDelete;
      }
      return true;
    }
    return false;
  };

  const relativeTimestamp = (last_fetched:any) => {
    const date = new Date();
    const timestamp = date.getTime();
    const seconds = Math.floor(timestamp / 1000);

    const oldTimestamp = new Date(last_fetched).getTime();
    const oldSeconds = Math.floor(oldTimestamp / 1000);

    const difference = seconds - oldSeconds;

    let output = ``;
    if (difference < 60) {
        // Less than a minute has passed:
        output = `${difference} seconds ago`;
    } else if (difference < 3600) {
        // Less than an hour has passed:
        output = `${Math.floor(difference / 60)} minutes ago`;
    } else if (difference < 86400) {
        // Less than a day has passed:
        output = `${Math.floor(difference / 3600)} hours ago`;
    } else if (difference < 2620800) {
        // Less than a month has passed:
        output = `${Math.floor(difference / 86400)} days ago`;
    } else if (difference < 31449600) {
        // Less than a year has passed:
        output = `${Math.floor(difference / 2620800)} months ago`;
    } else {
        // More than a year has passed:
        output = `${Math.floor(difference / 31449600)} years ago`;
    }

    return output;
  }

  const handleFlag = async (guid:string) => {
    await dispatch(flagSpa(guid));
  }

  return (
    <>
      <div className="table">

            {isLoading ? ( 
              <CircularProgress size={"24px"} sx={{position:'absolute', top:'50%', right:'50%'}}/>
            ): 
            spas.length>0 ? (
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                    {headers.map((header)=>(
                      headersToShow.includes(header.id) &&
                      ((header.id==='checked' && checked)||(header.id!=='checked')) &&
                      <TableCell key={header.id}align={header.align==='center'?'center':'left'}>
                        {header.id!=='control' && header.id!=='comments' && header.id!=='checked' && header.id!=='delete' && header.id!=='edit'? 
                        <TableSortLabel
                          active={orderBy === header.id}
                          direction={orderBy === header.id ? order : 'asc'}
                          onClick={(e)=>handleRequestSort(e,header.id)}
                        >
                          {header.label}
                          {orderBy === header.id ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                        :
                        header.id ==='delete'||header.id ==='edit' ? 
                          (canDelete?header.label:null)
                        :
                        (header.id!=='checked' ? header.label : <input id="all-checked" className="table-check" type="checkbox" checked={allChecked} onChange={(e)=>checkAll(e)}></input>)

                      }
                      </TableCell>
                      )
                    )}
                </TableRow>
              </TableHead>

              <TableBody>
                {sortedRows.map((spa:any)=>(
                  <TableRow key={spa.id}>
                    {checked && <TableCell align='center'  >
                      <input id={`${spa?.id}-checked`} className="table-check" name="spa-check" type="checkbox" checked={isChecked(spa)} onChange={()=>handleCheck(spa)}></input>
                    </TableCell>}

                    {headersToShow.includes('status') &&
                    <Tooltip title={`Last fetched ${relativeTimestamp(spa?.last_fetched)}`} followCursor={true}>
                      <TableCell align="center"  >
                        {spa.status === 'connected' ?
                          (
                            <span className="status-indicator online"></span>
                          ) : (
                            <div className="last-online">
                              <span className="status-indicator offline"></span>
                              <small>{spa.last_online} days ago</small>
                            </div>
                          )
                        }
                      </TableCell>
                    </Tooltip>
                    }

                    {headersToShow.includes('id') &&
                      <TableCell  >
                        <div className="clickable-icon" onClick={()=>navigate(`/spa/${spa.id}`)}>
                          {spa?.id}
                        </div>
                      </TableCell>
                    }

                    {headersToShow.includes('dealership') &&
                      <TableCell  >
                        <div className="clickable-icon" onClick={()=>navigate(`/dealership/${spa.dealership.id}`)}>
                          {spa.dealership?.name ?? 'N/A'}
                        </div>
                      </TableCell>
                    }

                    {headersToShow.includes('name') &&
                      <TableCell  >
                        <div className="clickable-icon" onClick={()=>navigate(`/user/${spa.user_id}`)}>
                          {spa.user?.name??"N/A"}
                        </div>
                      </TableCell>
                    }

                    {headersToShow.includes('arctic_serial_number') &&
                      <TableCell  >
                        <div className="clickable-icon" onClick={()=>navigate(`/spa/${spa.id}`)}>
                          {spa.arctic_serial_number??"N/A"}
                        </div>
                      </TableCell>
                    }

                    {headersToShow.includes('ip_address') &&
                      <TableCell  >
                        {spa.ip_address}
                      </TableCell>
                    }

                    {headersToShow.includes('nick_name') &&
                      <TableCell  >
                        <div className="clickable-icon" onClick={()=>navigate(`/spa/${spa.id}`)}>
                          {spa?.nick_name}
                        </div>
                      </TableCell>
                    }

                    {headersToShow.includes('errors') &&
                    <Tooltip title={`Last fetched ${relativeTimestamp(spa?.last_fetched)}`} followCursor={true}>
                      <TableCell align='center'  >
                        <div className="clickable-icon" onClick={()=>navigate(`/spa/${spa.id}/errors`)}>
                          <ErrorsIcon hasErrors={spa.hasErrors}/>
                        </div>
                      </TableCell>
                    </Tooltip>
                    }

                    {headersToShow.includes('yocto_version') &&
                      <TableCell  >
                        {spa.yocto_version}
                      </TableCell>
                    }

                    {headersToShow.includes('lpc_version') &&
                      <TableCell  >
                        {spa.lpc_version}
                      </TableCell>
                    }
                    
                    {headersToShow.includes('spaboy_firmware_version') &&
                      <TableCell  >
                        {spa.spaboy_firmware_version}
                      </TableCell>
                    }
                    
                    {headersToShow.includes('econ') &&
                    <Tooltip title={`Last fetched ${relativeTimestamp(spa?.last_fetched)}`} followCursor={true}>
                      <TableCell align='center'  >
                        {Number(spa.econ??0)?
                          <EcoModeOn/>
                          :
                          <EcoModeOff/>  
                        } 
                      </TableCell>
                    </Tooltip>
                    }

                    {headersToShow.includes('sbHr') &&
                    <Tooltip title={`Last fetched ${relativeTimestamp(spa?.last_fetched)}`} followCursor={true}>
                      <TableCell align='center'  >
                        {spa.sbHr>0?'ON':'OFF'}
                      </TableCell>
                    </Tooltip>
                    }

                    {headersToShow.includes('current') &&
                    <Tooltip title={`Last fetched ${relativeTimestamp(spa?.last_fetched)}`} followCursor={true}>
                      <TableCell  align="center">
                        {spa.current ? Number(spa.current) / 100 : 0}
                      </TableCell>
                    </Tooltip>
                    }

                    {headersToShow.includes('sbpH') &&
                    <Tooltip title={`Last fetched ${relativeTimestamp(spa?.last_fetched)}`} followCursor={true}>
                      <TableCell  align="center">
                        {spa.sbpH && <span 
                          className="status-indicator online" 
                          style={{
                            backgroundColor:Object.values(IndicatorColours)[spa.sbpHind??0], 
                            boxShadow: `0 0 0.1rem ${Object.values(IndicatorColours)[spa.sbpHind??0]}`
                          }}></span>}
                        {spa.sbpH ? Number(spa.sbpH) / 100 : `N/A`}
                      </TableCell>
                    </Tooltip>
                    }

                    {headersToShow.includes('sbORP') &&
                    <Tooltip title={`Last fetched ${relativeTimestamp(spa?.last_fetched)}`} followCursor={true}>
                      <TableCell  align="center" sx={{color:Object.values(IndicatorColours)[2]}}>
                        {spa.sbORP && <span 
                          className="status-indicator online" 
                          style={{
                            backgroundColor:Object.values(IndicatorColours)[spa.sbORPind??0], 
                            boxShadow: `0 0 0.1rem ${Object.values(IndicatorColours)[spa.sbORPind??0]}`
                          }}></span>}
                        {spa.sbORP??`N/A`}
                      </TableCell>
                    </Tooltip>
                    }

                    {headersToShow.includes('user_registered_at') &&
                      <TableCell  >
                        {spa.user_registered_at && new Date(spa.user_registered_at).toUTCString()}
                      </TableCell>
                    }

                    {headersToShow.includes('created_at') &&
                      <TableCell  >
                        {spa.created_at && new Date(spa.created_at).toUTCString()}
                      </TableCell>
                    }

                    {headersToShow.includes('updated_at') &&
                      <TableCell  >
                        {spa.updated_at && new Date(spa.updated_at).toUTCString()}
                      </TableCell>
                    }
                    
                    {headersToShow.includes('control') &&
                    <TableCell align='center'  >
                      <div className="clickable-icon" onClick={()=>navigate(`/spa-control/${spa.id}`)}>
                        <ControlIcon/>
                      </div>
                    </TableCell>
                    }

                    {headersToShow.includes('comments') &&
                    <TableCell  align='center'>
                      <div className="clickable-icon" onClick={()=>navigate(`/spa/${spa.id}/comments`)}>
                        <Badge badgeContent={spa?.unread_count ?? 0} color="primary">
                          <CommentIcon/>
                        </Badge>
                      </div>
                    </TableCell>
                    }

                  {headersToShow.includes('is_flagged') &&
                    <TableCell  align='center'>
                      <div className="clickable-icon" onClick={()=>handleFlag(spa?.id)}>
                        {spa?.is_flagged ? <FlagIconFilled/>:<FlagIconOutlined/>}
                      </div>
                    </TableCell>
                    }

                    {headersToShow.includes('edit') && canDelete &&
                      <TableCell align='center'  >
                        <div className="clickable-icon" onClick={()=>handleEditDelete(spa, 'edit')}>
                          <EditIcon/>
                        </div>
                      </TableCell>
                    }
                    
                    {headersToShow.includes('delete') && canDelete &&
                      <TableCell align='center'  >
                        <div className="clickable-icon" onClick={()=>handleEditDelete(spa, 'delete')}>
                          <DeleteIcon/>
                        </div>
                      </TableCell>
                    }

                  </TableRow>
                ))}
              </TableBody>

            </Table>
            ):(
            <div className="empty-table">No Spas Found.</div>
            )
          }
          </div>

          <div className="table-footer">
         
            <div className="pagination">
              <Pagination 
                count={meta?.total_pages} 
                variant="outlined" 
                onChange={handleChange}
                page={searchParams.get("page")?Number(searchParams.get("page")):1}
                showLastButton 
                showFirstButton 
                disabled={meta?.total_pages===1}
                />
              <div className="total-count">Showing {spas?.length??0}/{meta?.total??0} Spas</div>
            </div>


            <div className="list-settings">
              <div aria-describedby={open ? 'settings-icon' : undefined} className="clickable-icon" onClick={handleClick}><SettingIcon/></div>
              <Popover
                id={open ? 'settings-icon' : undefined}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                closeAfterTransition={false}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <div className="popover-checkboxes">
                  {headers.map((header:any)=>(
                    showSetting(header) &&
                    <label  key={header.id}><input type="checkbox" value={header.id} defaultChecked={headersToShow.includes(header.id)} onChange={(e)=>handleSetting(e)}/>{header.label}</label>
                  ))}
                </div>
              </Popover>
            </div>


          </div>  
    </>
  );
};

export default SpasTable;
