import axios from "axios";
import { BASE_URL } from "./apiconfig";

export const getUpgradeRequests = async (token: string | null, params:string) => {
    try {
      const { data } = await axios.get(`${BASE_URL}/upgrade-requests?${params}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (error) {
      console.log("Failed to pull upgrade requests. ", error);
      throw error;
    }
}

export const getSpaUpgradeRequests = async (token: string | null, request: {spa_id:string,params:string}) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/upgrade-requests/${request.spa_id}?${request.params}`,{
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.log("Failed to pull upgrade requests. ", error);
    throw error;
  }
}