import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { assignRole, fetchRoleUsers, removeRole } from '../actions/roles.actions';
import { fetchUserGroupUsers } from '../actions/userGroups.actions';
import { fetchDealershipUsers } from '../actions/dealership.actions';
import RoleUsersState from '../states/roleUsers.state';

const initialState: RoleUsersState = {
  loading: false,
  data: [],
  error: null,
  meta: null,
};

const RolesUsersSlice = createSlice({
  name: 'role_user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoleUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRoleUsers.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchRoleUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      })
      .addCase(fetchUserGroupUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserGroupUsers.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchUserGroupUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      })
      .addCase(fetchDealershipUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDealershipUsers.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchDealershipUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      })

      .addCase(assignRole.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(assignRole.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = [...Array.from(new Set([...state.data,...action.payload.roleUsers]))];
      })
      .addCase(assignRole.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      })

      .addCase(removeRole.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removeRole.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = [...state.data].filter((user:any)=> ![...action.payload.roleUsers].find(({id})=>user?.id===id));
      })
      .addCase(removeRole.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      })
      ;
  },
});

export default RolesUsersSlice.reducer;