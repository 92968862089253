export enum CategoryLabels {
    'error_code'='Error Code',
    'filtration'='Filtration',
    'network_wifi'='Network Connect (WiFi)',
    'network_wired'='Network Connect (Wired)',
    'other'='Other',
    'spaboy_communications'='Spa Boy Communications',
    'spaboy_operation'='Spa Boy Operation',
    'tub_operation'='Tub Operation',
}
