import React, {useEffect, useState} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import RootState from "../../../redux/states/root.state";
import {
  CircularProgress, 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableRow,
  TableSortLabel,
  Box,
  Pagination,
} from "@mui/material";
import { visuallyHidden } from '@mui/utils';
import { Permission } from "../../../redux/models/permission.model";
import { UserGroup } from "../../../redux/models/userGroup.model";
import { DeleteIcon, EditIcon } from "../../../assets/svg/svg";

type Order = 'asc' | 'desc';
const headers = [
  {id:'id', label:'ID', align:'center'},
  {id:'name', label:'Name', align:null},
  {id:'description', label:'Description', align:null},
  {id:'permissions', label:'Permissions', align:null},
  {id:'users_count', label:'Users', align:'center'},
  {id:'edit', label:'Edit', align:'center'},
  {id:'delete', label:'Delete', align:'center'},
];

interface TableProps {
  setEditOpen: any;
  setDeleteOpen: any;
  setUserGroupToModify: any;
}

const UserGroupsTable: React.FC<TableProps> = (
  {
    setEditOpen,
    setDeleteOpen,
    setUserGroupToModify,
  }
) => {
  const navigate = useNavigate();
  const {data:userGroups,loading:isLoading, meta} = useSelector((state: RootState) => state.userGroups);
  const [searchParams, setSearchParams] = useSearchParams();
  
  // Table Sort
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('');
  const [sortedRows, setSortedRows] = useState<any[]>([]);
  
  useEffect(() => {
    setSortedRows(userGroups);
  }, [userGroups]);

  const handleChange = (event:any, value:any) => {
    searchParams.set("page",value);
    setSearchParams(searchParams);
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    header_id: string,
  ) => {
    const isAsc = orderBy === header_id && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(header_id);

    handleSort();
  };


  const handleSort = () => {
    let newArray = [...userGroups].sort((a:any, b:any)=>{
      let isAsc = order ==='asc';
      switch (orderBy){
        default:
          return a[orderBy]===b[orderBy] ? 0 : isAsc ? a[orderBy]>b[orderBy] ? -1 : 1 : a[orderBy]<b[orderBy] ? -1 : 1;
       
      }
    })
    setSortedRows(newArray);
  }

  const handleModal = (userGroup:any, modal:'edit'|'delete') => {
    setUserGroupToModify(userGroup);

    switch(modal){
      case 'edit':
        setEditOpen(true);
        break;
      case 'delete':
        setDeleteOpen(true);
        break;
    }

  }

  return (
    <>
      <div className="table" style={{padding:'1rem'}}>
            {isLoading ? ( 
              <CircularProgress size={"24px"} sx={{position:'absolute', top:'50%', right:'50%'}}/>
            ): 
            userGroups.length>0 ? (
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {headers.map((header)=>(
                    <TableCell key={header.id}   align={header.align==='center'?'center':'left'}>
                      {header.id!=='delete' && header.id!=='edit'? 
                      <TableSortLabel
                        active={orderBy === header.id}
                        direction={orderBy === header.id ? order : 'asc'}
                        onClick={(e)=>handleRequestSort(e,header.id)}
                      >
                        {header.label}
                        {orderBy === header.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                      :
                      header.label
                    }
                    </TableCell>
                    )
                  )}
              </TableRow>
              </TableHead>

              <TableBody>
                {sortedRows.map((userGroup:UserGroup, i:number)=>(
                  <TableRow key={i}>
                    <TableCell align='center'>
                      <div className="clickable-icon" onClick={()=>navigate(`/user-groups/${userGroup.id}`)}>
                        {userGroup?.id}
                      </div>
                    </TableCell>

                    <TableCell>
                      {userGroup?.name}
                    </TableCell>

                    <TableCell>
                      {userGroup?.description}
                    </TableCell>

                    <TableCell>
                      {userGroup?.permissions.map((permission:Permission)=>permission.name).toString()}
                    </TableCell>

                    <TableCell align='center'>
                      {userGroup?.users_count}
                    </TableCell>
                    
                    <TableCell align='center'  >
                      <div className="clickable-icon" onClick={()=>handleModal(userGroup, 'edit')}>
                        <EditIcon/>
                      </div>
                    </TableCell>

                    <TableCell align='center'  >
                      <div className="clickable-icon" onClick={()=>handleModal(userGroup, 'delete')}>
                        <DeleteIcon/>
                      </div>
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>

            </Table>
            ):(
            <div className="empty-table">No User Groups Found.</div>
            )
          }
          </div>

          <div className="table-footer">
         
            <div className="pagination">
              <Pagination 
                count={meta?.last_page} 
                variant="outlined" 
                onChange={handleChange}
                page={searchParams.get("page")?Number(searchParams.get("page")):1}
                showLastButton 
                showFirstButton 
                disabled={meta?.last_page===1}
                />
              <div className="total-count">Showing {userGroups?.length??0}/{meta?.total??0} User Groups</div>
            </div>
          </div>  
    </>
  );
};

export default UserGroupsTable;
