import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchRegions } from '../actions/countries.actions';
import RegionsState from '../states/regions.state';

const initialState: RegionsState = {
  loading: false,
  data: [],
  error: null,
};

const regionsSlice = createSlice({
  name: 'regions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRegions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRegions.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(fetchRegions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      });
  },
});

export default regionsSlice.reducer;