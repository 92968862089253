import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import RootState from "../../../redux/states/root.state";
import {
  CircularProgress, 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableRow,
  TableSortLabel,
  Box,
  Pagination
} from "@mui/material";
import { visuallyHidden } from '@mui/utils';
import { DeleteIcon } from "../../../assets/svg/svg";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CategoryLabels } from "../categoryLabels.enum";
import useRoleCheck from "../../../hooks/useRoleCheck";

type Order = 'asc' | 'desc';
const headers = [
  {id:'id', label:'ID', align:"center"}, 
  {id:'title', label:'Title',align:null}, 
  {id:'body', label:'Body',align:null}, 
  {id:'user', label:'Author',align:null}, 
  {id:'category', label:'Category',align:null}, 
  {id:'is_resolved', label:'Resolved',align:'center'}, 
  {id:'spa_id', label:'Spa', align:null},
  {id:'updated_at', label:'Last Updated',align:null}, 
  {id:'created_at', label:'Created',align:null},
  {id:'delete', label:'Delete', align:"center"},
];

interface TableProps {
  setViewOpen: any;
  setDeleteOpen: any;
  setComment: any;
}

const unread_sx = {fontWeight:'bolder'}
const read_sx = {opacity:'80%'}

const CommentsTable: React.FC<TableProps> = (
  {
    setViewOpen,
    setDeleteOpen,
    setComment,
  }
) => {

  const {data:comments, loading, meta} = useSelector((state: RootState) => state.comments);
  const {data:user} = useSelector((state: RootState) => state.user);
  // Table Sort
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [sortedRows, setSortedRows] = useState<any[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const isAdmin = useRoleCheck(['admin','developer']);

  const {id} = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setSortedRows(comments);
  }, [comments]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    header_id: string,
  ) => {
    const isAsc = orderBy === header_id && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(header_id);

    handleSort();
  };

  const handleSort = () => {
    let newArray = [...comments].sort((a:any, b:any)=>{
      return a[orderBy] === b[orderBy] ? 0 : order==='asc'? a[orderBy] ? -1:1 : b[orderBy] ? 1:-1;
    })
    setSortedRows(newArray);
  }

  const handleChange = (event:any, value:any) => {
    setSearchParams({page: value})  
  }

  return (
    <>
          <div className="table">
            {loading ? ( 
              <CircularProgress size={"24px"} sx={{position:'absolute', top:'50%', right:'50%'}}/>
            ): 
            
            comments.length>0 ? (
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {headers.map((header)=>(
                    ((id && header.id!=='spa_id') || (!id)) &&
                    <TableCell key={header.id} align={header.align?"center":"left"}>
                      {header.id!=='edit' && header.id!=='delete' ? 
                      <TableSortLabel
                        active={orderBy === header.id}
                        direction={orderBy === header.id ? order : 'asc'}
                        onClick={(e)=>handleRequestSort(e,header.id)}
                      >
                        {header.label}
                        {orderBy === header.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                      :
                      (header.label)
                    }
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
              {sortedRows.map((comment,i)=>(
                <TableRow key={i} onClick={()=>{setViewOpen(true);setComment(comment)}} className="clickable-row">
                  <TableCell align='center' sx={comment?.is_unread ? unread_sx : read_sx}>
                    {comment?.id}
                  </TableCell>

                  <TableCell sx={comment?.is_unread ? unread_sx : read_sx}>
                    {comment?.title}
                  </TableCell>

                  <TableCell sx={comment?.is_unread ? unread_sx : read_sx}>
                   {comment?.body}
                  </TableCell>

                  <TableCell sx={comment?.is_unread ? unread_sx : read_sx}>
                   {comment?.user?.username}
                  </TableCell>

                  <TableCell sx={comment?.is_unread ? unread_sx : read_sx}>
                   {CategoryLabels[comment?.category as keyof typeof CategoryLabels]}
                  </TableCell>

                  <TableCell align="center" sx={comment?.is_unread ? unread_sx : read_sx}>
                   {comment?.is_resolved?<span className="check">✓</span>:''}
                  </TableCell>

                  {
                  (!id) &&
                  <TableCell>
                    <div className="clickable-icon" onClick={()=>navigate(`/spa/${comment?.spa_id}`)}>
                      {comment?.spa_id}
                    </div>
                  </TableCell>
                  }

                  <TableCell sx={comment?.is_unread ? unread_sx : read_sx}>
                    {new Date(comment?.updated_at).toUTCString()}
                  </TableCell>

                  <TableCell sx={comment?.is_unread ? unread_sx : read_sx}>
                    {new Date(comment?.created_at).toUTCString()}
                  </TableCell>

                  {
                  ( isAdmin || user?.id===comment?.user?.id ) &&
                  <TableCell align='center'>
                    <div className="clickable-icon" onClick={(e)=>{setComment(comment); setDeleteOpen(true);e.stopPropagation()}}>
                      <DeleteIcon/>
                    </div>
                  </TableCell>
                  }
                    
                </TableRow>
                ))}
              </TableBody>

            </Table>
            ):(
            <div className="empty-table">No Comments Found.</div>
            )
          }
          </div>

          <div className="table-footer">
            <div className="pagination">
              <Pagination 
                count={meta?.last_page} 
                variant="outlined" 
                onChange={handleChange}
                page={searchParams.get("page")?Number(searchParams.get("page")):1}
                showLastButton 
                showFirstButton 
                disabled={meta?.total_pages===1}
                />
              <div className="total-count">Showing {comments?.length}/{meta?.total??0} Comments</div>
            </div>
          </div>  
    </>
  );
};

export default CommentsTable;
