import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import SpasState from '../states/spas.state';
import { fetchFlaggedSpas, fetchSpas, fetchUserSpas } from '../actions/spas.actions';
import { deleteSpa, deregisterSpaUser, editSpa, flagSpa, registerSpaUser } from '../actions/spa.actions';

const initialState: SpasState = {
  loading: false,
  data: [],
  error: null,
  meta: null,
};

const hotTubSlice = createSlice({
  name: 'hotTub',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    // Fetch all spas
      .addCase(fetchSpas.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSpas.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchSpas.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      })

      //Delete spa
      .addCase(deleteSpa.pending, (state) => {
          state.error = null;
      })
      .addCase(deleteSpa.fulfilled, (state, action: PayloadAction<any>) => {
          state.data = [...state.data].filter((spa:any)=>{return spa?.id!==action.payload});
      })
      .addCase(deleteSpa.rejected, (state, action) => {
          state.error = action.payload ?? null;
      })

      // Fetch a user's spas
      .addCase(fetchUserSpas.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserSpas.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchUserSpas.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      })

      // Edit spa
      .addCase(editSpa.pending, (state) => {
          state.error = null;
      })
      .addCase(editSpa.fulfilled, (state, action: PayloadAction<any>) => {
          state.data = [...state.data].map((spa:any)=>
            spa?.id===action.payload?.id 
          ? {...spa,nick_name: action.payload.nick_name, dealership_id: action.payload.dealership_id, arctic_serial_number: action.payload.arctic_serial_number} 
          : spa
        );
      })
      .addCase(editSpa.rejected, (state, action) => {
          state.error = action.payload ?? null;
      })

      // Register user to spa
      .addCase(registerSpaUser.pending, (state) => {
          state.error = null;
      })
      .addCase(registerSpaUser.fulfilled, (state, action: PayloadAction<any>) => {
          state.data = [...state.data].map((spa:any)=>
            spa?.id===action.payload?.id ? 
            {...spa,user_id: action.payload.user_id, user:action.payload.user} 
              : spa
            );
      })
      .addCase(registerSpaUser.rejected, (state, action) => {
          state.error = action.payload ?? null;
      })

      //Deregister user from spa
      .addCase(deregisterSpaUser.pending, (state) => {
          state.error = null;
      })
      .addCase(deregisterSpaUser.fulfilled, (state, action: PayloadAction<any>) => {
          state.data = [...state.data].map((spa:any)=>
            spa?.id===action.payload?.id ? 
          {...spa,user_id: action.payload.user_id, user:action.payload.user} 
          : spa);
      })
      .addCase(deregisterSpaUser.rejected, (state, action) => {
          state.error = action.payload ?? null;
      })

      // fetch flagged spas
      .addCase(fetchFlaggedSpas.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFlaggedSpas.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchFlaggedSpas.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      })

      // flag spa from list
      .addCase(flagSpa.pending, (state) => {
        state.error = null;
      })
      .addCase(flagSpa.fulfilled, (state, action: PayloadAction<any>) => {
        state.data = [...state.data].map((spa:any)=>
          spa?.id===action.payload?.id ? 
        {...spa,is_flagged: action.payload.is_flagged} 
        : spa);
      })
      .addCase(flagSpa.rejected, (state, action) => {
        state.error = action.payload ?? null;
      });
  },
});

export default hotTubSlice.reducer;