import { createAsyncThunk } from '@reduxjs/toolkit';
import RootState from '../states/root.state';
import { getHistory } from '../../services/api/spaHistory.api';

export const fetchSpaHistory = createAsyncThunk<
    any,
    { guid: string; date_from: number;date_to: number,keys: any[], type:string},
    { rejectValue: string; state: RootState }
>(
    'spa-history',
    async (spaInfo, { getState, rejectWithValue }) => {
        try {
            const { user } = getState();
            const token = user.accessToken;

            const response = await getHistory(token, spaInfo);

            return response.data;

        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(`An error occurred while fetching ${spaInfo.type} history.`);
            }
        }
    }
);