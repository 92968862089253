import axios from "axios";
import { BASE_URL } from "./apiconfig";

export const getLogs = async (token: string | null, params:string) => {
    try {
      const { data } = await axios.get(`${BASE_URL}/log-requests?${params}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (error) {
      console.log("Failed to pull log history. ", error);
      throw error;
    }
}

export const downloadLogFile = async (token: string | null, log_request_id: number) => {
    try {
        const {data} = await axios.post(`${BASE_URL}/log-requests/download/${log_request_id}`,{},{
            headers: { Authorization: `Bearer ${token}` },
        });

        return data
    } catch (error) {
        console.log("Failed to download log file. ", error);
        throw error;
    }
}

