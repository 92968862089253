import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import "./UserSelect.css"
import React, {useEffect, useState} from "react";
import RootState from "../../../redux/states/root.state";
import { CircularProgress, Pagination } from "@mui/material";
import { fetchDealershipUsers } from "../../../redux/actions/dealership.actions";
import { fetchRoleUsers } from "../../../redux/actions/roles.actions";

interface UserSelectProps {
    checked:any[];
    setChecked: any;
    role: any;
    dealership_id?:string|null;
}

const MultiSelectUserRole: React.FC<UserSelectProps> = ({
    checked,
    setChecked,
    role,
    dealership_id=null,
}) => {
const dispatch: AppDispatch = useDispatch();
const {data:users,meta,loading} = useSelector((state: RootState) => state.roleUsers);

const [pageNumber, setPageNumber] = useState(1);

const handleCheck = (user:any) => {
    let newArr = [...checked];
    let found = checked?.find((checked_user:any)=>user?.id===checked_user?.id)!==undefined;
    if(found){
      newArr = newArr.filter((checked_user:any)=>checked_user?.id!==user?.id);
    } else {
      newArr.push(user);
    }
    setChecked(newArr);
}

useEffect(() => {
    let page = `?page=${pageNumber}`;

    dealership_id ? dispatch(fetchDealershipUsers({id:dealership_id,params:page+`&role=${role}`})) : dispatch(fetchRoleUsers({id:role==='technician'?'7':'8',page:page}));

  }, [dispatch,pageNumber, role,dealership_id]);

  return (
    <>
    { loading ?
    <div className="no-users"><CircularProgress size={"24px"}/></div>
    :
    users.length>0 ? 
        <div className="select-container">
            <div className="user-select">
                {users.map((user:any)=>(
                    <div key={user.id}>
                        <label>
                            <input type="checkbox" name="user-select" onChange={()=>handleCheck(user)}/> {user?.name!==``? `${user?.name} (${user?.id})`:`${user?.id}`} 
                        </label>
                    </div>
                ))}
            </div>
            {meta?.last_page>1 && <Pagination 
                count={meta?.last_page} 
                size="small"
                onChange={(e,page)=>setPageNumber(page)}
                page={pageNumber}
                showLastButton 
                showFirstButton 
                disabled={meta?.last_page===1}
            />}
        </div>
        :
        <div className="no-users">No Users Found.</div>
        }
    </>
  );
};

export default MultiSelectUserRole;
