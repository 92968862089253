import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useSearchParams} from 'react-router-dom';
import RootState from '../../redux/states/root.state';
import Spinner from "../common/Spinner";
import { AppDispatch } from '../../redux/store';
import { loginUserFromToken } from '../../redux/actions/user.actions';
import ErrorPage from '../../pages/General/ErrorPage';

interface ProtectedRouteProps {
    children: React.ReactNode;
}

const SpasRoute: React.FC<ProtectedRouteProps> = ({children}) => {
    const dispatch: AppDispatch= useDispatch();
    const {accessToken, loading, error} = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const searchParams = useSearchParams()[0];
    const jwt = searchParams.get('session_id');
    useEffect(() => {
        if (!accessToken) {
            jwt ? dispatch(loginUserFromToken(jwt)):navigate('/login');
        } 
    }, [accessToken, navigate,jwt,dispatch]);


    return (accessToken) ? <>{children}</> : loading ? <Spinner /> : error ? <ErrorPage error={error}/> : <Spinner />

};

export default SpasRoute;