import "./CommentsPage.css"
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { AppDispatch } from "../../redux/store";
import Header from "../../components/common/Header/Header";
import { fetchComments, fetchSpaComments } from "../../redux/actions/comments.actions";
import DeleteCommentModal from "./components/DeleteCommentModal";
import CommentsContainer from "./components/CommentsContainer";
import AddCommentModal from "./components/AddCommentModal";
import ViewCommentModal from "./components/ViewCommentModal";

const CommentsPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const searchParams = useSearchParams()[0];
  const {id} = useParams();

  const [viewOpen, setViewOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const [comment, setComment] = useState<any>(null);

  useEffect(() => {
    if (id){
      dispatch(fetchSpaComments({id:id, params:searchParams.toString()}));
    } else {
      dispatch(fetchComments(searchParams.toString()));
    }
  }, [dispatch,searchParams,id]);

  return (
    <>
    <ViewCommentModal open={viewOpen} setOpen={setViewOpen} object_id={comment?.id}/>
    <AddCommentModal open={addOpen} setOpen={setAddOpen}/>
    <DeleteCommentModal open={deleteOpen} setOpen={setDeleteOpen} object={comment}/>
    <Header/>
    <CommentsContainer
      setAddOpen={setAddOpen}
      setViewOpen={setViewOpen}
      setDeleteOpen={setDeleteOpen}
      setComment={setComment}
    />
    </>
  );
};

export default CommentsPage;
