import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import RolesState from '../states/roles.state';
import { fetchRoles } from '../actions/roles.actions';

const initialState: RolesState = {
  loading: false,
  data: [],
  error: null,
  meta: null,
};

const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoles.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRoles.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      });
  },
});

export default rolesSlice.reducer;