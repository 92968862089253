import React, {useEffect, useState} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import RootState from "../../../redux/states/root.state";
import {
  CircularProgress, 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableRow,
  TableSortLabel,
  Box,
  Pagination,
} from "@mui/material";
import { visuallyHidden } from '@mui/utils';
import { Role } from "../../../redux/models/role.model";
import { Permission } from "../../../redux/models/permission.model";

type Order = 'asc' | 'desc';
const headers = [
  {id:'id', label:'ID', align:'center'},
  {id:'name', label:'Name', align:null},
  {id:'description', label:'Description', align:null},
  {id:'permissions', label:'Permissions', align:null},
  {id:'users_count', label:'Users', align:'center'},
];

const RolesTable: React.FC = () => {
  const navigate = useNavigate();
  const {data:roles,loading:isLoading, meta} = useSelector((state: RootState) => state.roles);
  const [searchParams, setSearchParams] = useSearchParams();

  // Table Sort
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('');
  const [sortedRows, setSortedRows] = useState<any[]>([]);
  
  useEffect(() => {
    setSortedRows(roles);
  }, [roles]);

  const handleChange = (event:any, value:any) => {
    searchParams.set("page",value);
    setSearchParams(searchParams);
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    header_id: string,
  ) => {
    const isAsc = orderBy === header_id && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(header_id);

    handleSort();
  };


  const handleSort = () => {
    let newArray = [...roles].sort((a:any, b:any)=>{
      let isAsc = order ==='asc';
      switch (orderBy){
        default:
          return a[orderBy]===b[orderBy] ? 0 : isAsc ? a[orderBy]>b[orderBy] ? -1 : 1 : a[orderBy]<b[orderBy] ? -1 : 1;
       
      }
    })
    setSortedRows(newArray);
  }

  return (
    <>
      <div className="table" style={{padding:'1rem'}}>
            {isLoading ? ( 
              <CircularProgress size={"24px"} sx={{position:'absolute', top:'50%', right:'50%'}}/>
            ): 
            roles.length>0 ? (
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                    {headers.map((header)=>(
                      <TableCell key={header.id}   align={header.align==='center'?'center':'left'}>
                        <TableSortLabel
                          active={orderBy === header.id}
                          direction={orderBy === header.id ? order : 'asc'}
                          onClick={(e)=>handleRequestSort(e,header.id)}
                        >
                          {header.label}
                          {orderBy === header.id ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                      )
                    )}
                </TableRow>
              </TableHead>

              <TableBody>
                {sortedRows.map((role:Role, i:number)=>(
                  <TableRow key={i}>
                    <TableCell align='center'>
                      <div className="clickable-icon" onClick={()=>navigate(`/roles/${role.id}`)}>
                        {role.id}
                      </div>
                    </TableCell>

                    <TableCell>
                      {role.name}
                    </TableCell>

                    <TableCell>
                      {role.description}
                    </TableCell>

                    <TableCell>
                      {role.permissions.map((permission:Permission)=>permission.name).toString()}
                    </TableCell>

                    <TableCell align='center'>
                      {role.users_count}
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>

            </Table>
            ):(
            <div className="empty-table">No Roles Found.</div>
            )
          }
          </div>

          <div className="table-footer">
         
            <div className="pagination">
              <Pagination 
                count={meta?.last_page} 
                variant="outlined" 
                onChange={handleChange}
                page={searchParams.get("page")?Number(searchParams.get("page")):1}
                showLastButton 
                showFirstButton 
                disabled={meta?.last_page===1}
                />
              <div className="total-count">Showing {roles?.length??0}/{meta?.total??0} Roles</div>
            </div>
          </div>  
    </>
  );
};

export default RolesTable;
